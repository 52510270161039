#appeal-address-input-container {
	grid-column: 1 / -1;
	display: flex;
	flex-wrap: wrap;
	margin-top: spacing(4);
	background-color: $denim-lightest;
	padding: spacing(2);
	border-radius: spacing(2);
	position: relative;

	@include if-size("tablet") {
		padding: spacing(4);
		padding-top: spacing(2);
		//box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

.appeal-address-address1,
.appeal-address-address2,
.appeal-propertyID-address {
	width: 100%;
}

.appeal-address-address2 {
	margin-top: spacing(1);
}

.appeal-propertyID-address {
	margin-top: spacing(2);
}

.appeal-address-city-state-zip {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;

	.appeal-address-city,
	.appeal-address-state {
		margin-right: 20px;

		@include respond-to("medium") {
			margin-right: 0px;
		}
	}

	.appeal-address-city {
		margin: spacing(2) 0;
		width: 100%;
	}

	.appeal-address-state {
		width: calc(50% - spacing(1));
		margin-right: spacing(1);

		select {
			height: 100%;
		} /* Remove when input padding is fixed */
	}

	.appeal-address-zip {
		width: calc(50% - spacing(1));
		margin-left: spacing(1);
	}

	.appeal-move-out-date {
		width: 100%;
		margin-top: spacing(2);
	}
}

#appeal-address-title,
#appeal-address-subtitle {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-address-other-signup-methods {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: spacing(2);
	margin-bottom: 150px;

	grid-column: 1 / -1;
	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	max-width: 100%;

	.appeal-address-signup-method {
		flex: 1;
		min-width: 220px;
		//box-shadow: 1px 3px 7px 2px #0532490d;
		background-color: $denim-lightest;
		padding: spacing(2);
		border-radius: spacing(2);

		&:hover {
			cursor: pointer;
		}
	}
}

.appeal-address-show-line-2 {
	margin-top: 26px;
	width: 80%;
}

#appeal-address-pricing-section {
	width: 100%;
	margin-top: spacing(8);
	background-color: $denim-lightest;
	padding: spacing(8) 0;
}

.appeal-address-cta-container {
	grid-column: 1 / -1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	p.sm {
		margin: spacing(3) 0 spacing(1);
	}
}

.appeal-get-savings-btn {
	grid-column: 1 / -1;
	margin: 38px auto 0;
	&.savings-page-extra-margin-bottom {
		margin-bottom: spacing(4);
	}
}

.appeal-address-autocomplete-results-outer-contianer {
	margin-top: spacing(2);
	padding-left: spacing(1);
}

.appeal-address-autocomplete-results-label-container {
	display: flex;
	height: 16px;
}

.appeal-address-autocomplete-results-loader {
	width: 16px;
	margin-left: 5px;
	position: relative;
	top: -1px;
	opacity: 0.7;
}

.appeal-address-autocomplete-results-inner-contianer {
	margin-top: spacing(1);
	border-top: 2px solid $white;
	padding-top: spacing(2);
}

.appeal-address-autocomplete-results-item {
	cursor: pointer;
	margin-bottom: spacing(2);

	&:hover {
		* {
			text-decoration: underline;
		}
	}

	p,
	.label {
		color: $azure;
		cursor: pointer;
	}
}

.appeal-address-autocomplete-container {
	background: $cloud;
	grid-column: 3 / -3;
	padding: spacing(2) spacing(4);
	margin-top: spacing(4);
	border-radius: 16px;
}

.appeal-address-autocomplete-input-container {
	margin-bottom: 16px;
	position: relative;
}

#appeal-address-autocomplete-container {
	width: 100%;
	margin-top: spacing(1);
	position: relative;
}

#appeal-address-manual-input-container {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}
