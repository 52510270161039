#properties-dashboard {
	[data-skeleton="true"] {
		border-radius: 4px;
	}
	&-grid {
		margin: spacing(2) spacing(2) spacing(4);

		@include if-size("desktop") {
			row-gap: spacing(4);
		}

		// @include if-size("desktop-xl") {
		// 	margin: spacing(4) auto;
		// }

		grid-auto-rows: min-content;
		width: auto;
	}

	&-widgets {
		&-col-1 {
			display: flex;
			flex-direction: column;
			gap: spacing(3);

			@include if-size("desktop") {
				gap: spacing(4);
			}
		}
	}

	&-top-bar {
		z-index: 1000;
		background-color: $white;
		width: 100%;

		@include if-size("desktop") {
			padding: 0 spacing(2);
		}
	}

	.filter-tools {
		max-width: 100%;
		grid-column: span 1;
		padding: spacing(2) spacing(2) 0;
		display: flex;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;
		row-gap: spacing(2);
		column-gap: spacing(2);
		height: min-content;

		@include if-size("desktop-xl") {
			grid-column: span 2;
			padding: spacing(2) 0 0;
		}

		@include if-size("desktop-xl") {
			column-gap: spacing(4);
			flex-wrap: nowrap;
		}

		.result-count {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			order: 2;
			@include if-size("desktop-xl") {
				order: 1;
			}

			label {
				color: $denim_5;
				white-space: nowrap;
				letter-spacing: 0.08em;

				font-size: 10px;
				line-height: 12px;
				@include if-size("desktop-xl") {
					font-size: 12px;
					line-height: 14px;
				}
			}

			p {
				font-weight: 600;

				font-size: 16px;
				line-height: 20px;
				@include if-size("desktop-xl") {
					font-size: 20px;
					line-height: 28px;
				}
			}
		}

		#view-hide-filters {
			flex: 1;
			display: flex;
			align-items: center;
			gap: spacing(1);

			color: $azure;
			white-space: nowrap;

			&:hover {
				color: $denim;
				cursor: pointer;

				path {
					fill: $denim;
				}
			}

			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.05em;

			order: 1;
			@include if-size("desktop-xl") {
				display: none;
			}
		}

		.filters {
			&.hide {
				display: none;
			}

			&.show {
				display: flex;
				flex-flow: row wrap;
				align-items: flex-start;
			}

			width: 100%;
			order: 3;
			gap: 16px;
			@include if-size("desktop-xl") {
				display: flex;
				align-items: flex-start;
				order: 2;

				&.hide,
				&.show {
					display: flex;
					flex-flow: row wrap;
				}
			}

			.expand-button {
				align-self: flex-end;

				p {
					font-size: 14px;
				}
			}
		}

		.tax-year-filter {
			width: 90px;
			z-index: 12;
		}

		#state-filter {
			width: 100px;
			z-index: 11;
		}

		#legal-entity-filter {
			width: 200px;
			z-index: 10;
		}
	}

	&-highlights {
		background-color: $denim;
		border-radius: 8px;

		height: min-content;
		padding: spacing(2);
		@include if-size("desktop-xl") {
			padding: spacing(2) spacing(3);
		}

		display: flex;
		gap: 16px;
		align-items: flex-end;
		flex-wrap: wrap;

		.highlight {
			&.flex-2 {
				flex: 2 0 100%;
				@include if-size("desktop-xl") {
					flex: 2;
				}
			}

			&.flex-0 {
				flex: 0;
			}

			label {
				white-space: nowrap;
				color: $cloud;

				font-size: 10px;
				line-height: 12px;
				letter-spacing: 0.08em;
				@include if-size("desktop-xl") {
					font-size: 12px;
					line-height: 14px;
					letter-spacing: 0.08em;
				}

				margin-bottom: spacing(0.5);
			}

			.lg {
				color: $cloud;
				font-weight: 600;

				font-size: 30px;
				line-height: 33px;
				@include if-size("desktop-xl") {
					font-size: 38px;
					line-height: 42px;
				}

				&.skeleton {
					color: transparent;
					@include shimmer-denim();
					border-radius: 4px;
				}
			}

			.sm {
				color: $cloud;

				font-size: 20px;
				line-height: 28px;
				@include if-size("desktop-xl") {
					font-size: 24px;
					line-height: 28px;
				}

				&.skeleton {
					color: transparent;
					@include shimmer-denim();
					border-radius: 4px;
				}
			}
		}
	}

	.properties-dashboard-needs-attention-reasons {
		width: 100%;
		height: min-content;
		background-color: $cloud;
		border-radius: 16px;

		padding: spacing(2);

		display: flex;
		flex-direction: column;
		gap: 16px;

		header {
			.body-tiny {
				color: $denim_5;
			}
		}

		.no-required-actions {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 8px;

			* {
				color: $denim_5;
			}
		}

		.required-actions {
			background-color: $white;
			padding: spacing(2);
			border-radius: 8px;

			box-shadow: 1px 3px 7px 2px #0532490d;

			&.needs-attention {
				border: solid 2px $rust;
			}

			&.pending {
				border: solid 2px $mint;
			}

			dt {
				&:first-child {
					margin-top: 0;
				}
				margin-top: spacing(1);
				margin-bottom: 6px;

				@include if-size("desktop-xl") {
					margin-top: spacing(2);
					margin-bottom: 6px;
				}

				label {
					color: $denim_5;

					display: flex;
					align-items: center;
					gap: 4px;

					font-size: 10px;
					line-height: 12px;
					letter-spacing: 0.08em;
					@include if-size("desktop-xl") {
						font-size: 12px;
						line-height: 14px;
						letter-spacing: 0.08em;
					}

					svg {
						width: 12px;
						height: 12px;
						path {
							fill: $denim_5;
						}
					}
				}
			}

			dd {
				margin-bottom: spacing(1);

				&:last-child {
					margin-bottom: 0;
				}

				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			&-count {
				color: $denim;
				border-radius: 4px;

				font-size: 14px;
				line-height: 18px;

				@include if-size("desktop-xl") {
					font-size: 18px;
					line-height: 24px;
				}
			}

			&-button {
				padding: 2px 8px;
				background-color: transparent;
				border: none;
				font-family: $ff-secondary;
				border-radius: 4px;

				font-size: 14px;
				line-height: 18px;

				@include if-size("desktop-xl") {
					font-size: 16px;
					line-height: 24px;
				}

				color: $denim;
				&:hover {
					cursor: pointer;
					background-color: rgba($azure, 0.1);
				}

				&.active {
					color: $white;
					background-color: $azure;
				}

				&.continue {
					color: $azure;
				}

				&.disabled {
					&:hover {
						cursor: not-allowed;
					}
				}

				&[data-skeleton="true"] {
					background-color: $sand;
				}
			}
		}
	}

	.properties-dashboard-actions-required {
		width: 100%;
		height: min-content;
		border-radius: 16px;
		background-color: $denim-lightest;
		border: 1px solid $denim;
		padding: spacing(2);

		@include if-size("desktop") {
			padding: spacing(3);
		}

		&.mobile-only {
			display: block;
			@include if-size("desktop-xl") {
				display: none;
			}
		}

		&.has-required-actions {
			background-color: $rust-lightest;
			border-color: $rust-dark;
		}

		label.label {
			font-size: 10px;
			list-style: 11.5px;
			letter-spacing: 1px;
			color: $denim-medium;

			@include if-size("desktop") {
				font-size: 12px;
				line-height: 13.8px;
			}
		}

		.urgent-bubble {
			padding: 0 spacing(2);
			border-radius: 40px;
			background-color: $rust;
			color: $white;
			width: min-content;

			padding-bottom: 3px;
			@include if-size("desktop") {
				line-height: 26px;
				padding-bottom: 6px;
			}
		}
	}

	&-appeal-statuses {
		box-shadow: 1px 3px 7px 2px #0532490d;
		border-radius: 16px;
		border: 1px solid $slate;
		padding: spacing(2);

		display: flex;
		flex-direction: column;
		gap: spacing(2);

		header {
			.body-tiny {
				color: $denim_5;
			}

			p.bold {
				font-weight: 1000 !important;
			}
		}

		.appeal-status-percentages {
			height: 16px;
			width: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
			gap: 4px;

			.appeal-status-percentage {
				padding-top: 4px;
				height: 16px;
				position: relative;
				display: flex;

				@mixin top-bar-styles($postfix, $color) {
					@extend .appeal-status-percentage;

					.appeal-status-percentage-bar {
						flex: 1;
						border-radius: 4px;
						background-color: $color;
					}

					&-expanded {
						@extend .appeal-status-percentage-#{$postfix};

						&::after {
							content: "";
							position: absolute;
							top: 0;
							height: 2px;
							width: 100%;
							background-color: $color;
						}
					}
				}

				&-reviewing {
					@include top-bar-styles("reviewing", $sunflower-light);
				}

				&-appealing {
					@include top-bar-styles("appealing", $sunflower);
				}

				&-results {
					@include top-bar-styles("results", $kelly);
				}

				&-not-appealed {
					@include top-bar-styles("not-appealed", $rust-medium);
				}

				&-skeleton {
					@extend .appeal-status-percentage;
					@include shimmer();
				}
			}
		}

		.appeal-groups-header {
			display: flex;
			gap: 24px;
			padding: 0 spacing(1);

			.appeal-group-name {
				flex: 1;

				@include if-size("desktop") {
					flex: 7;
				}
			}

			.appeal-group-pct {
				text-align: right;
				flex: 1;
			}

			.appeal-group-count {
				text-align: right;
				flex: 1;
			}
		}

		.appeal-groups {
			display: flex;
			flex-direction: column;
			gap: 2px;
			width: 100%;
			margin-top: spacing(0.5);

			.appeal-group-row-container {
				padding: spacing(1);
				border-radius: 4px;
				background: $white;
				border: 1px solid $white;

				&:nth-of-type(odd) {
					background-color: $denim-lightest;
					border-color: $denim-lightest;
				}

				&:hover {
					border-color: $slate;
				}

				&-expanded {
					@extend .appeal-group-row-container;

					&:hover {
						border-color: transparent;
					}
				}

				.appeal-group-subgroups {
					display: flex;
					flex-direction: column;
					gap: spacing(1.5);
					margin-top: spacing(1.5);

					.appeal-group-subgroup-row {
						padding-left: spacing(2);
						display: flex;
						justify-content: space-between;
					}
				}
			}

			.appeal-group-row {
				width: 100%;
				display: flex;
				align-items: center;
				gap: spacing(3);
				cursor: pointer;

				.appeal-group-color-circle {
					height: 16px;
					width: 16px;
					border-radius: 50%;
					margin-top: spacing(0.5);

					&-reviewing {
						@extend .appeal-group-color-circle;
						background-color: $sunflower-light;
					}

					&-appealing {
						@extend .appeal-group-color-circle;
						background-color: $sunflower;
					}

					&-results {
						@extend .appeal-group-color-circle;
						background-color: $kelly;
					}

					&-not-appealed {
						@extend .appeal-group-color-circle;
						background-color: $rust-medium;
					}
				}

				.appeal-group-name {
					display: flex;
					align-items: center;
					gap: spacing(1);
					flex: 1;

					@include if-size("desktop") {
						flex: 7;
					}
				}

				.appeal-group-pct {
					text-align: right;
					flex: 1;
				}

				.appeal-group-count {
					text-align: right;
					flex: 1;
				}

				.appeal-group-expand-container {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					.appeal-group-expand-btn {
						cursor: pointer;
						width: 12px;
						height: auto;

						&-expanded {
							@extend .appeal-group-expand-btn;
							transform: rotate(180deg);
						}
					}
				}
			}

			#appeal-group-separator {
				height: 2px;
				background: $sand;
				margin: spacing(1) 0;
				border-left: none;
			}
		}

		.appeal-status-filter-button {
			color: $denim_5;
			background-color: transparent;
			border: none;
			font-family: $ff-secondary;
			border-radius: 4px;
			white-space: nowrap;

			padding: 2px 8px;
			font-size: 10px;
			line-height: 14px;
			@include if-size("desktop-xl") {
				padding: 4px 8px;
				font-size: 12px;
				line-height: 16px;
			}

			display: flex;
			align-items: center;
			gap: 4px;

			svg {
				width: 12px;
				height: 12px;

				path {
					fill: $denim_5;
				}
			}

			/* This rule applies when the mouse hovers over the element, but not when the mouse hovers over any of its child elements. */
			&:hover:not(:has(*:hover)) {
				cursor: pointer;
			}

			&.active {
				color: $white;
				background-color: $azure;

				svg path {
					fill: $white;
				}

				&.rust {
					color: $white;
				}
			}

			&.rust {
				color: $rust;

				svg,
				path {
					fill: $rust;
				}
			}
		}
	}

	&-search-bar-row {
		display: flex;
		flex-direction: column;
		gap: 4px;

		@include if-size("monitor") {
			gap: 16px;
			flex-direction: row;
			align-items: center;
			grid-column: span 2;
			margin-top: spacing(4);
		}

		.search-bar {
			flex: 3;
			label {
				margin-left: spacing(1);
				margin-bottom: 4px;
			}

			input {
				padding: 8px 12px;
				border-width: 2px;
				border-color: $denim_5;

				&::placeholder {
					color: $denim_5;
					opacity: 1; /* Firefox */
				}
			}
		}

		#properties-download-csv {
			flex: 2;

			margin-top: 14px;

			&.disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}

			svg {
				width: 16px;
				height: 16px;
			}

			p {
				font-weight: 600;

				font-size: 14px;
				line-height: 16px;

				@include if-size("desktop-xl") {
					font-size: 16px;
					line-height: 24px;
				}
			}
		}
	}

	&-table {
		@include if-size("monitor") {
			grid-column: span 2;
		}

		a {
			text-decoration: underline;
		}

		#properties-page-tabular-view {
			margin-top: 0;
			margin-bottom: spacing(3);
		}

		#properties-page-pagination-container {
			width: 100%;

			#properties-page-pagination-page-select-container {
				margin: 0 auto;
			}
		}

		.view-edit-property {
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 16px;

			@include if-size("desktop") {
				gap: 8px;
			}

			svg {
				&:hover {
					cursor: pointer;
				}
			}

			.property-settings-property-card-popover {
				left: 100%;
			}
		}
	}

	&-download-csv {
		@include if-size("desktop-xl") {
			grid-column: span 1;
			grid-row-start: 5;
		}
	}

	.mobile-only {
		display: flex;

		@include if-size("monitor") {
			display: none;
		}
	}

	.desktop-only {
		display: none;

		@include if-size("desktop-xl") {
			display: flex;
		}
	}
}

#enterprise-layout {
	display: flex;
	flex-direction: column;
	min-height: calc(100svh - 50px);

	@include if-size("desktop") {
		flex-direction: row;
		height: calc(100vh - 44px);
		position: relative;
		top: unset;
	}

	&-content {
		flex: 1 1;
		background-color: $white;
		width: 100%;
		height: 100%;
		position: relative;

		@include if-size("desktop") {
			overflow-y: auto;
		}

		@include if-size("desktop-xl") {
			background-color: $white;
		}

		@include styled-scrollbar();

		#account-page-container {
			#account-upload-evidence,
			#invoice-page,
			#account-information-page,
			#account-add-property-page,
			#account-referral-page {
				@include if-size("desktop") {
					padding-left: 16px;
					padding-right: 16px;

					.grid,
					&.grid {
						min-width: 100%;
						grid-template-columns: repeat(12, minmax(32px, 80px));
					}

					#property-back-btn {
						display: none;
					}
				}
			}
		}
	}
}

#enterprise-nav {
	background: $denim-lightest;
	color: $denim;
	overflow-x: hidden;
	border-right: 1px solid $slate;
	flex-direction: column;
	gap: 16px;
	padding: spacing(3) spacing(2) spacing(4);
	display: flex;
	position: absolute;
	min-width: none;
	width: 85%;
	max-width: 288px;
	z-index: 10000;
	height: 100dvh;
	top: 0;
	display: none;

	@include if-size("desktop") {
		display: flex;
		position: relative;
		top: 0;
		min-width: 224px;
		max-width: 224px;
		overflow-y: auto;
		height: 100%;
	}

	.enterprise-mobile-logo {
		width: 126px;
		height: 32px;
		fill: $denim;
		display: block;
		@include if-size("desktop") {
			display: none;
		}
	}

	&.mobile-show {
		display: flex;
		position: fixed;
		top: 0px;
		background-color: $denim-lightest;
		overflow: initial;
		z-index: 1000001;
		max-width: 320px;
	}

	.enterprise-nav-link {
		width: 100%;
	}

	.enterprise-nav-links {
		width: 100%;
		display: flex;
		gap: 4px;
		align-items: center;
		padding: 0;
		flex-direction: column;
		align-items: flex-start;

		&::-webkit-scrollbar {
			display: none;
		}

		.enterprise-nav-link {
			padding: 8px 16px;
			width: 100%;
			cursor: pointer;

			border: solid 1px transparent;

			p {
				color: $denim-medium;
			}

			&-active {
				background-color: $white;
				border-radius: 4px;

				p {
					color: $royal;
					font-weight: 700;
				}
			}

			&:hover {
				border: solid 1px $denim-extra-light;
				border-radius: 4px;
			}
		}

		.enterprise-nav-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	.enterprise-nav-general-nav {
		margin-top: auto;
		gap: 6px;
	}

	#enterprise-nav-top-nav {
		padding-bottom: 16px;
		margin-bottom: 8px;
		border-bottom: 1px solid $slate;
	}

	.enterprise-nav-item-rust-badge {
		border-radius: 6px;
		background-color: $rust;
		color: $white;
		padding: 4px 6px 6px;
		font-size: 10px;
		font-weight: 700;
	}

	.enterprise-service-links {
		label.body-tiny {
			@extend .body-tiny;
			margin-bottom: 6px;
		}
	}

	.enterprise-nav-mobile-overlay-close-icon {
		position: absolute;
		right: -45px;
		top: 16px;
		z-index: 1000000;
		background-color: $white;
		padding: 7px 8px;
		border-radius: 100px;

		@include if-size("tablet") {
			right: -60px;
		}
	}
}

#properties-dashboard-overview-stats {
	display: flex;
	gap: spacing(2.5);
	flex-wrap: wrap;

	.overview-stat-card {
		flex: 1;
		padding: spacing(2);
		border: 1px solid $slate;
		box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
		border-radius: 8px;
		min-width: calc(50% - 16px);

		p.bold {
			font-weight: 1000 !important;
		}

		@include if-size("desktop-xl") {
			min-width: calc(25% - 16px);
			padding: spacing(3);
		}
	}
}

#properties-dashboard-reduction-highlights {
	border: 1px solid $slate;
	border-radius: 8px;
	box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
	padding: spacing(2);

	@include if-size("desktop") {
		padding: spacing(3);
	}

	header {
		p.bold {
			font-weight: 1000 !important;
		}
	}

	#properties-dashboard-reduction-highlights-table-container {
		max-height: 400px;
		overflow: auto;
	}

	table {
		min-width: 100%;
		width: max-content;

		thead {
			th {
				text-align: left;
				padding: spacing(2) spacing(1);
				background: $denim-lightest;
				border-bottom: 1px solid $slate;

				&:not(:last-of-type) {
					border-right: 1px solid $slate;
				}
			}
		}

		tbody {
			padding-top: 52px;

			tr {
				&:not(:last-of-type) {
					border-bottom: 1px solid $slate;
				}

				td {
					padding: spacing(2) spacing(1);
					width: fit-content;

					&:first-of-type {
						max-width: 200px;

						@include if-size("desktop") {
							max-width: unset;
						}
					}

					&:not(:last-of-type) {
						border-right: 1px solid $slate;
					}
				}
			}
		}
	}

	.properties-dashboard-reduction-highlights-reduction-badge {
		padding: 6px;
		background: $kelly-lightest;
		border-radius: 6px;

		p {
			color: $kelly-dark;
		}
	}
}
