#appeal-flow {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $white;
	z-index: 4000;

	#appeal-flow-inner {
		display: flex;
		height: 100%;
		width: 100%;
		flex-direction: column;
		align-items: center;
		position: relative;
		// overflow-x: hidden;
		// overflow-y: overlay;
	}

	#appeal-flow-body {
		width: 100%;
	}

	#appeal-flow-header {
		background-color: $denim-lightest;
		position: relative;
		width: 100%;
		padding: spacing(2) 0;
		z-index: 1001;
		max-height: 90px;
	}

	#appeal-flow-header-grid {
		place-items: center;
	}

	.cobranded-header-hack {
		margin-left: -18px;

		@include if-size("desktop") {
			margin-left: 0;
		}
	}

	#appeal-flow-logo {
		grid-column: 2 / -2;
		width: 100%;
		height: auto;
		z-index: 1002;
		min-height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;

		#ownwell-logo {
			min-width: 100px;
			height: auto;
			max-width: 175px;
			max-height: 45px;
			flex: 1;
		}

		#valon-logo {
			width: auto;
			max-height: 20px;

			@include if-size("desktop") {
				max-height: 30px;
			}
		}

		.plus {
			display: none;

			@include if-size("desktop") {
				display: block;
			}
		}

		@include if-size("tablet") {
			grid-column: 3 / -3;
		}

		@include if-size("desktop") {
			grid-column: 5 / -5;
		}
	}

	#appeal-flow-back {
		grid-column: 1 / 1;
		cursor: pointer;
		display: flex;
		align-items: center;

		&:hover {
			svg,
			p {
				fill: $azure;
			}
		}

		svg {
			margin-right: 4px;
			margin-top: 1px;
			fill: $denim;

			&:hover {
				fill: $royal;
			}
		}

		p:hover {
			color: $royal;
		}
	}

	#appeal-flow-close-icon {
		grid-column: -2 / -1;
		color: #676767;
		cursor: pointer;
		border: 2px solid $denim;
		padding: 3px;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		display: grid;
		place-items: center;

		svg {
			width: 8px;
			height: 8px;
			fill: $denim;
		}
	}
}

#appeal-flow-ratings-footer {
	margin: spacing(8) 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	max-width: 80%;

	.rating-no-hover {
		cursor: default;
	}
}

.appeal-flow-signing-up-is-easy {
	grid-column: 1 / -1;
	margin-top: spacing(8);

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

.appeal-flow-step-counter-title {
	grid-column: 1 / -1;
	margin: spacing(3) 0 spacing(2);

	@include if-size("desktop") {
		grid-row: 2;
		&:nth-of-type(1) {
			grid-column: 2 / 6;
		}

		&:nth-of-type(2) {
			grid-column: 7 / -2;
		}
	}
}

#appeal-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($denim, 0.75);
	z-index: 10000;
}

#appeal-exit-are-you-sure {
	width: 80%;
	max-width: 500px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-75%);
	border-radius: 8px;
	background-color: $white;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: flex;
	flex-direction: column;

	#appeal-exit-are-you-sure-warning {
		display: flex;
		align-items: center;

		.label {
			color: $terracotta;
			margin-left: 1ch;
		}

		svg {
			width: 12px;
			height: 12px;
			fill: $terracotta;
		}
	}
}

#appeal-exit-are-you-sure-text {
	padding: spacing(3) spacing(2);
	font-size: 18px;
	line-height: 20px;
}

#appeal-exit-are-you-sure-btn-container {
	display: flex;
	justify-content: space-between;
	border-top: 4px solid $sky-light;
}

.appeal-exit-are-you-sure-btn {
	width: 50%;
	padding: 21px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:nth-of-type(even) {
		border-radius: 0 0 8px 0;
	}

	&:nth-of-type(odd) {
		border-radius: 0 0 0 8px;
	}

	&:hover {
		background-color: $sky-light;
	}

	p {
		color: $azure;
	}

	svg {
		fill: $azure;
		margin-right: 3px;
		margin-top: 4px;
		width: 12px;
		@include if-size("tablet") {
			width: 16px;
		}
	}

	#appeal-close-icon {
		height: 16px;
		width: 16px;
		border: 2px solid $azure;
		border-radius: 50%;
		padding: 3px;
		margin-right: 5px;
	}
}

.appeal-exit-are-you-sure-btn:nth-of-type(odd) {
	border-right: 4px solid $sky-light;
	border-radius: 0 0 0 8px;
}

.appeal-exit-are-you-sure-btn:nth-of-type(even) {
	border-radius: 0 0 8px 0;
}

#appeal-exit-intent {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	border-radius: 8px;
	width: 80%;
	max-width: 560px;
	background-color: $white;

	@include if-size("desktop") {
		width: 50%;
	}

	#appeal-exit-intent-content {
		padding: spacing(2);
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	#appeal-exit-intent-cta {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;
		gap: spacing(2);
		border-top: 1px solid $slate;

		@include if-size("desktop") {
			flex-direction: row;
		}
	}
}

.appeal-flow-savings-banner {
	width: 100%;
	padding: spacing(1) 0;
	background-color: $azure;
	text-align: center;
	p {
		color: $white;
	}
}

.appeal-step-tooltip {
	width: 100%;
}

#appeal-flow-step-counter-container {
	margin-top: spacing(1);
	margin-bottom: spacing(4);
	grid-column: 1 / -1;
	@include if-size("desktop") {
		margin: spacing(3) 0;
		grid-column: 3 / -3;
	}

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4px;

	.step-counter-address {
		@include if-size("tablet") {
			display: none;
		}
	}
}

#appeal-exit-deadline-approaching {
	width: 80%;
	max-width: 500px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-75%);
	border-radius: 8px;
	background-color: $white;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: spacing(4);

	#appeal-close-icon {
		height: 16px;
		width: 16px;
		padding: 3px;
		@include if-size("desktop") {
			height: 24px;
			width: 24px;
			padding: 5px;
		}

		border: 2px solid $denim;
		border-radius: 50%;
		margin: 8px;
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
	}

	&-time-remaining {
		border-radius: 8px;
		border: 2px solid $terracotta;
		padding: spacing(1);
		width: min-content;
		white-space: nowrap;
		color: $terracotta;
		font-weight: 600;
		margin-bottom: spacing(2);

		font-size: 16px;
		line-height: 19px;
		@include if-size("desktop") {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&-heading {
		&-residents {
			width: 100%;
			text-align: left;
			color: $azure;
			font-family: $ff-secondary;
			font-weight: 600;

			font-size: 14px;
			line-height: 17px;
			@include if-size("desktop") {
				font-size: 16px;
				line-height: 24px;
			}
		}

		&-text {
			width: 100%;
			text-align: left;
			color: $denim;
			font-family: $ff-primary;
			margin-bottom: spacing(1);

			font-size: 24px;
			line-height: 28px;
			@include if-size("desktop") {
				font-size: 32px;
				line-height: 37px;
			}
		}
	}

	&-continue {
		margin-top: spacing(2);
		margin-bottom: 20px;

		font-size: 16px;
		line-height: 19px;
		@include if-size("desktop") {
			font-size: 20px;
			line-height: 23px;
		}
	}

	&-exit {
		margin-bottom: 12px;
		color: $azure;
		&:hover {
			color: $denim;
			cursor: pointer;
		}

		font-size: 16px;
		line-height: 19px;
		@include if-size("desktop") {
			font-size: 20px;
			line-height: 23px;
		}
	}

	&-savings-or-free {
		white-space: nowrap;
		color: $denim;

		font-size: 14px;
		line-height: 17px;
		@include if-size("desktop") {
			font-size: 16px;
			line-height: 24px;
		}
	}

	&-guarantee {
		color: $denim;
		font-style: italic;
		font-size: 16px;
		line-height: 24px;
		font-weight: 600;
		white-space: pre-wrap;
		text-align: center;

		font-size: 14px;
		line-height: 17px;
		@include if-size("desktop") {
			font-size: 16px;
			line-height: 24px;
		}
	}
}

.rust-warning-box {
	background-color: $rust;
	grid-column: 1/-1;
	padding: 6px 8px 8px;
	border-radius: 8px;
	text-align: center;

	@include if-size("desktop") {
		grid-column: 3/-3;
	}

	p {
		color: $denim-lightest;
	}
}

.savings-page-past-deadline-additional-value-prop {
	grid-column: 1/-1;
	display: flex;
	place-items: center;
	flex-direction: column;
	gap: 48px;
	padding: spacing(4) 0;

	@include if-size("desktop") {
		padding: spacing(8) 0 spacing(4);
	}

	.savings-page-past-deadline-additional-value-prop-info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 32px;

		@include if-size("desktop") {
			flex-wrap: nowrap;
		}
	}

	.savings-page-past-deadline-additional-value-prop-info-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
		text-align: center;

		flex: 1 1;
		min-width: 200px;

		svg {
			height: 48px;
			width: 48px;
		}
	}
}

#continue-with-exemption-only {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	border-radius: 16px;
	width: 80%;
	max-width: 560px;
	background-color: $white;

	button {
		margin: spacing(2) auto;
	}

	#continue-with-exemption-only-close {
		position: absolute;
		top: 12px;
		right: 12px;
		cursor: pointer;
		height: 24px;
		width: 24px;
	}
}

#continue-with-exemption-only-text {
	display: flex;
	flex-direction: column;

	padding: spacing(4);

	a {
		font-size: 16px;
		line-height: 20px;

		@include if-size("desktop") {
			font-size: 20px;
			line-height: 24px;
		}
	}
}

#appeal-flow.portfolio-flow {
	#appeal-flow-header {
		background-color: $denim-lightest;
	}

	#appeal-address-input-container {
		background-color: $denim-lightest;
	}

	#portfolio-signup-animation-container {
		height: 300px;
	}

	#appeal-flow-body {
		padding-bottom: spacing(8);
	}

	.appeal-contact-question-card {
		background-color: $denim-lightest;
	}

	#appeal-flow-ratings-footer {
		padding-bottom: spacing(4);
		flex-direction: column;
		gap: spacing(1);

		#talk-to-sales {
			order: 1;
		}
		.google-rating {
			order: 2;
		}
		.bbb-rating {
			order: 3;
		}

		@include if-size("desktop") {
			flex-direction: row;
			gap: spacing(1);

			.google-rating {
				order: 1;
			}
			.bbb-rating {
				order: 2;
			}
			#talk-to-sales {
				order: 3;
			}
		}

		#talk-to-sales {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: spacing(2);
			border-radius: 16px;
			padding: spacing(2);
			background-color: $sky-light;
		}
	}

	#appeal-address-input-container {
		.search-options {
			width: 100%;
			display: flex;
			flex-direction: row;
			gap: spacing(2);
			padding-left: spacing(2);
			padding-right: spacing(2);
			border-bottom: 1px solid $denim-extra-light;
			margin-bottom: spacing(1);

			.search-option {
				flex: 1;
				padding-top: spacing(2);
				padding-bottom: spacing(2);

				p {
					text-align: center;
					margin: 0 auto;

					font-size: 12px;
					line-height: 18px;
					font-weight: 433;
					letter-spacing: -0.02em;

					@include if-size("desktop") {
						font-size: 16px;
						line-height: 22px;
						letter-spacing: -0.01em;
					}
				}

				&.active {
					border-bottom: 2px solid $denim;
					p {
						font-weight: 622;
					}
				}

				&:hover {
					cursor: pointer;
				}
			}
		}

		.appeal-parcel-autocomplete-container {
			position: relative;
		}

		.appeal-parcel-autocomplete-results-container {
			position: absolute;
			width: 100%;
		}
	}

	.account-leftovers-property-card-address {
		flex-direction: row;
	}

	button.remove-portfolio-signup-property {
		background-color: transparent;
		border: none;
		padding: 0px;

		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;

		font-family: "sofia-pro";
		font-size: 14px;
		line-height: 16p.8x;
		letter-spacing: 0.05em;
		color: $denim_5;

		@include if-size("desktop") {
			margin-top: 0;
		}

		svg {
			margin-top: 2px;
			width: 10px;
			height: 10px;

			path {
				fill: $denim_5;
			}
		}

		&:hover {
			cursor: pointer;
			color: $rust;

			svg {
				path {
					fill: $rust;
				}
			}
		}
	}

	.appeal-contact-radio-list-container {
		margin-top: spacing(2);
	}

	.appeal-contact-question-alert {
		background-color: $rust;
		padding: spacing(2) spacing(4);

		p.sm {
			margin-top: 0;
		}
	}

	#appeal-contact-question-container {
		margin-top: spacing(4);
		@include if-size("desktop") {
			margin-top: spacing(8);
		}
	}
}

#ownership-info-confirmation-animation {
	width: 240px;
	height: 240px;
}

.property-verification-resolution-tile-card {
	margin-top: spacing(4);
	border-radius: 16px;
	padding: spacing(4);
	background-color: $denim-lightest;

	.tile-options {
		flex-direction: column;
		@include if-size("desktop") {
			flex-direction: row;
		}
	}
}

.property-verification-parcel-number-entry-card {
	margin-top: spacing(4);
	border-radius: 16px;
	padding: spacing(4);
	background-color: $denim-lightest;

	.quick-link-link {
		&.semibold {
			font-weight: 700;
		}
	}

	.quick-link-small {
		.quick-link-arrow {
			height: 9.14px !important;
			width: 10.67px !important;
			margin-left: 3px;
			margin-top: 4px;
		}
	}
}

.property-verification-ownership-boxes {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: spacing(2);

	.property-verification-ownership-box {
		flex: 1;
		background-color: $white;
		padding: spacing(2);
		box-shadow: 1px 3px 7px 2px #0532490d;
		border-radius: 16px;
	}
}

.owner-of-record-box {
	background-color: $white;
	padding: spacing(2);
	box-shadow: 1px 3px 7px 2px #0532490d;
	border-radius: 16px;
}

.edit-owner-details {
	.edit-owner-details-pencil-icon {
		margin-right: 2px;
		width: 12px;
		height: 12px;
	}
}

#appeal-flow.portfolio-flow {
	.potential-savings-box {
		border-radius: 16px;
		background-color: $sky-light;
		padding: spacing(2) spacing(4);

		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		.potential-savings-amount {
			font-size: 64px;
			line-height: 74px;
			letter-spacing: -0.03em;
			font-weight: 800;
			color: $azure;
		}
	}

	#verify-property-owner-information {
		.information-needed-badge {
			color: $gold;
			background-color: $sunflower-light;
			border-radius: 8px;
			padding: 6px 8px;
			width: min-content;
			white-space: nowrap;
		}

		.account-leftovers-property-card {
			display: block;

			.account-leftovers-property-card-address {
				flex-direction: column;
				align-items: flex-end;
				gap: spacing(1);
				@include if-size("mobile") {
					flex-direction: row;
					align-items: flex-start;
					flex-wrap: wrap;
				}

				label {
					font-size: 12px;
					line-height: 13.8px;
				}

				div {
					flex: 1;
				}

				div:first-of-type {
					@include if-size("mobile") {
						flex-basis: 140px;
					}
				}

				.account-leftovers-property-card-children {
					width: 100%;
					flex: 1;
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					gap: spacing(2);
				}
			}

			.edit-owner-details-form {
				margin-top: spacing(2);
				padding: spacing(2);
				box-shadow: 1px 3px 7px 2px #0532490d;

				border-radius: 8px;
				background-color: $denim-lightest;
			}
		}
	}
}

#reduction-approach-breakdown {
	border-radius: 8px;
	background-color: $denim-lightest;

	.approach-breakdowns {
		display: flex;
		flex-direction: column;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
		}
	}

	.approach-breakdown {
		padding: 12px;
		display: flex;
		gap: spacing(1);
		align-items: center;
		border-radius: 8px;
		background-color: $white;

		svg {
			height: auto;
			width: 40px;
		}
	}
}
