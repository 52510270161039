#bill-reduction-modal-container {
	@include if-size("desktop") {
		> .property-grid {
			display: flex; // This is a hack to center the smaller than usual modal (desktop fixed width 944px)
		}
	}
}

#bill-reduction-modal {
	padding: 32px;

	@include if-size("desktop") {
		width: 944px;
	}
}

#bill-reduction-form {
	#bill-reduction-heading {
		font-size: 20px;
		line-height: 28px;
	}
}

.bill-reduction-form-content {
	background: $denim_lightest;
	border-radius: 16px;
	padding: spacing(2);
	margin-top: spacing(2);

	.bill-reduction-split-row {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
			gap: spacing(3);
		}

		> * {
			flex: 1;
			width: 100%;
		}
	}

	.bill-reduction-split-row-2 {
		@extend .bill-reduction-split-row;
		gap: spacing(1);

		@include if-size("desktop") {
			gap: spacing(2);
		}
	}

	.bill-reduction-form-right-comment {
		align-self: end;
		padding-bottom: 6px;
	}

	select.with-label {
		margin-top: 0;
	}
}

.bill-reduction-other-info {
	background-color: $sunflower-lightest;
	padding: 12px 16px;
	margin: -8px 0 16px;
	border-radius: 8px;

	p {
		color: $rust;
	}
}

.bill-reduction-highly-recommended {
	position: absolute;
	top: calc(50% - 3px);
	right: 8px;
	padding: 6px;
	border-radius: 6px;
	background-color: $sky-light;

	p {
		color: $royal;
	}
}

#bill-reduction-success-animation-container {
	margin: 0 auto;
	width: fit-content;
}

#bill-reduction-service-content {
	background: $white;
	border-radius: 16px;

	@include if-size("desktop") {
		padding: spacing(2);
	}

	.denied-icon {
		width: 20px;
		height: 20px;
		fill: $denim_5;
		margin-left: 5px;

		g path {
			fill: $denim_5;
		}
	}
}

.electric-disclaimer {
	background-color: $rust;
	border-radius: 8px;
	padding: 8px;
}

#electric-bill-reduction-savings-found {
	.card-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		gap: spacing(1);
		@include if-size("tablet") {
			flex-direction: row;
		}

		.fancy-tag {
			padding: 5px 16px;
			background: linear-gradient(90deg, #57c975 -3.89%, #1c7ce5 115.56%);
			border-radius: 2px;
		}

		.savings-active-tag {
			background: $kelly_lightest;
			padding: spacing(1) spacing(2);
			border-radius: 40px;
		}
	}

	.card-subheader {
		// Styles for card subheader
	}

	.card-body {
		display: flex;
		justify-content: space-between;
		gap: spacing(2);
		flex-direction: column-reverse;

		@include if-size("tablet") {
			flex-direction: row;
		}

		.img-container {
			display: flex;
			align-items: center;
			justify-content: center;

			.bulb-img {
				width: 256px;
			}
		}

		.card-body-container {
			flex: 1;
		}

		.project-savings-container,
		.upload-bill-container {
			flex: 1;
			display: flex;
			padding: 18px 16px;
			flex-direction: column;
			gap: 12px;
			flex: 1 0 0;
			align-self: stretch;
			border-radius: 8px;

			border: 1px solid $denim_5;
			background: $denim_lightest;

			&.projected {
				border: 1px solid #57c975;
				background: rgba(230, 247, 242, 0.4);
			}

			.no-margin {
				margin: 0;
			}

			.fancy {
				margin: 0;
				background: linear-gradient(270deg, #1c7ce5 89.42%, #57c975 101.15%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			.no-top-margin {
				margin-top: -12px;
				margin-bottom: 12px;
			}

			.bill-items-container {
				display: flex;
				flex-direction: column;
				gap: spacing(1);

				.bill-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: spacing(1);
				}
			}

			.line-separator {
				width: 100%;
				height: 1px;
				background-color: $slate;
			}

			.result-line-item {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: spacing(1);
			}

			.simple-link {
				color: $azure;
				cursor: pointer;
				text-align: center;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.light-suggestion-box {
	padding: 10px 24px;
	border-radius: 8px;
	border: 1px solid $slate;
	display: flex;
	flex-direction: column;
	gap: 8px;

	@include if-size("tablet") {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.light-suggestion-box-content {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
}

.kelly-box {
	padding: 0 6px;
	border-radius: 8px;
	background-color: #e6f7f2;
	border: 1px solid $kelly;

	span {
		padding: 0;
		margin: 0;
	}
}

#onboarding-light-results {
	&.light-box-container {
		border-radius: 8px;
		background-color: $denim_lightest;
		padding: 16px;
		width: 100%;
		.light-box-container-content {
			background: $white;
			padding: 16px;
			width: 100%;
			border-radius: 8px;
		}
	}
}
