#minimal-signup-landing-page {
	position: relative;
	height: 100dvh !important;
	width: 100dvw !important;

	display: flex;
	flex-direction: column;
	align-items: center;

	#minimal-signup-landing-page-gradient-backdrop {
		z-index: -1;
		position: absolute;
		background: linear-gradient(
			270deg,
			rgba(28, 124, 229, 0.4) 1.3%,
			rgba(87, 201, 117, 0.4) 64.53%
		);
		filter: blur(58px);
		width: 100dvw;

		border-radius: 576.291px;
		top: -115px;
		left: 0;
		height: 200px;

		@include if-size("desktop") {
			border-radius: 1542.76px;
			top: -760px;
			left: 0;
			height: 890px;
		}
	}

	#minimal-signup-landing-page-logo {
		margin-top: 24px;

		@include if-size("desktop") {
			margin-top: 40px;
		}
	}

	#minimal-signup-landing-page-jumbo-section {
		padding: 0 spacing(2);
		margin-top: spacing(6);

		@include if-size("desktop") {
			margin-top: 120px;
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: spacing(4);

		#minimal-signup-landing-page-jumbo-title {
			background: linear-gradient(90deg, #1c7ce5 0%, #57c975 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			text-align: center;
			font-family: "sofia-pro";
			font-size: 40px;
			font-weight: 800;
			line-height: 60px;
			letter-spacing: -0.4px;

			@include if-size("desktop") {
				font-size: 56px;
				line-height: 76px;
				letter-spacing: -1.12px;
			}
		}

		#minimal-signup-landing-page-jumbo-subtitle {
			color: $denim;
			text-align: center;

			font-family: "sofia-pro";
			font-size: 18px;
			font-weight: 400;
			line-height: 26px;
			letter-spacing: -0.36px;

			@include if-size("desktop") {
				font-size: 20px;
				line-height: 28px;
				letter-spacing: -0.2px;
			}
		}

		#landing-hero-address-input {
			padding: 0;

			#landing-hero-address-search {
				overflow: hidden;
				box-shadow:
					-44px 319px 90px 0px rgba(171, 171, 171, 0),
					-28px 204px 82px 0px rgba(171, 171, 171, 0.01),
					-16px 115px 70px 0px rgba(171, 171, 171, 0.05),
					-7px 51px 51px 0px rgba(171, 171, 171, 0.09),
					-2px 13px 28px 0px rgba(171, 171, 171, 0.1);

				svg.search-icon-left path {
					fill: $denim;
				}
			}
		}

		#minimal-signup-landing-page-under-address-input-copy {
			z-index: 2;
			display: flex;
			padding: 8px 16px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			width: fit-content;

			border-radius: 80px;
			background-color: $white;

			color: $azure;
			text-align: center;

			font-family: "sofia-pro";
			font-size: 18px;
			font-weight: 400;
			line-height: 26px;
			letter-spacing: -0.36px;

			@include if-size("desktop") {
				font-size: 20px;
				line-height: 28px;
				letter-spacing: -0.2px;
			}
		}
	}

	#landing-marquee-section {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100dvw;
		.marquee-featured-in {
			color: $denim-extra-light;
		}
		.marquee-featured-logos-img {
			box-shadow: none;
			border: none;
			border-radius: 0;
			opacity: 0.5;
			margin-bottom: spacing(2);
		}
	}

	.gradient-border {
		border-radius: 8px;
		padding: 1px;
		background: linear-gradient(270deg, #57c975 0%, #1c7ce5 100%);
	}

	.savings-toast {
		border-radius: 7px;
		background-color: $white;

		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;

		padding: 12px 16px;
		@include if-size("desktop") {
			padding: 16px;
		}

		.coin-stack-svg {
			width: 24px;
			height: 24px;
		}
	}

	.toast-container {
		bottom: 128px !important;
		@include if-size("desktop") {
			bottom: unset !important;
		}
	}
}
