#landing-page.become-a-partner-page {
	#landing-title-section {
		background: $sky-light;

		padding-top: spacing(3);
		padding-bottom: spacing(5);

		@include if-size("desktop") {
			padding: spacing(10) 0;
		}

		#landing-title {
			font-family: "sofia-pro";
			letter-spacing: -2px;
			font-weight: 800;
		}

		#landing-title-hero-img {
			grid-column: 1 / -1;
			height: 100%;
			width: 100%;

			order: 1;
			margin-bottom: spacing(2);

			svg {
				max-width: 360px;
			}

			@include if-size("desktop") {
				order: 2;
				margin-bottom: 0;
				grid-column: 8 / 13;
				justify-items: end;
			}
		}

		#landing-title-info-section {
			grid-column: 1 / -1;

			order: 2;
			@include if-size("desktop") {
				order: 1;
				max-width: none;
				grid-column: 1 / 8;
			}

			#landing-title-value-props {
				list-style-type: none;
				display: flex;
				flex-direction: column;
				gap: spacing(1);

				@include if-size("desktop") {
					gap: spacing(2);
				}

				margin-bottom: spacing(3);

				li {
					display: flex;
					gap: spacing(1);
					align-items: center;

					svg {
						width: 24px;
						height: 24px;

						@include if-size("desktop") {
							width: 32px;
							height: 32px;
						}
					}
				}
			}

			button {
				margin: auto;
				@include if-size("desktop") {
					margin: 0;
				}
			}
		}
	}

	.value-prop-1-featured-label {
		color: $white;
		text-align: center;
		margin-bottom: spacing(1);
	}

	#value-prop-1-container {
		background-color: $denim;
		padding-top: spacing(3);
	}

	#value-prop-1 {
		align-items: center;

		padding-top: spacing(8);
		padding-bottom: spacing(3);

		@include if-size("desktop") {
			padding-top: spacing(10);
			padding-bottom: spacing(7);
		}

		#value-prop-1-img {
			position: relative;
			grid-column: 1 / -1;
			order: 1;
			width: 100%;
			height: auto;
			margin-bottom: spacing(3);

			@include if-size("desktop") {
				order: 2;
				max-width: none;
				grid-column: 8 / -1;
				margin-bottom: 0;
			}
		}

		#value-prop-1-content {
			grid-column: 1 / -1;
			order: 2;
			margin-bottom: spacing(4);

			@include if-size("desktop") {
				order: 1;
				margin-bottom: 0;
				max-width: none;
				grid-column: 1 / 7;
			}

			* {
				color: $white;
				fill: $white;
			}

			ul {
				list-style: none;
				z-index: 100;

				li:not(:last-child) {
					margin-bottom: spacing(2);
				}

				.value-prop-1-content-item {
					display: flex;

					svg {
						fill: $white;
						width: 24px;
						height: 24px;
						margin-right: spacing(1);
					}
				}
			}

			.value-prop-1-content-item {
				display: flex;
				align-items: center;
			}

			#value-prop-1-content-title {
				margin-bottom: spacing(1);

				@include if-size("desktop") {
					margin-bottom: spacing(2);
				}
			}
		}
	}

	#value-prop-2 {
		background-color: $denim;
		overflow: hidden;
		position: relative;

		&::before {
			width: 50%;
			left: 50%;
			bottom: -50%;
			transform: translateX(-50%);
			padding-top: 50%;
			content: "";
			position: absolute;
			border-radius: 1000000px;
			filter: blur(160px);
			background: rgba(0, 73, 141, 0.6);
			z-index: 1;
		}

		#value-prop-2-seperator {
			grid-column: 1 / -1;
			height: 2px;
			width: 100%;
			background-color: $sand;
			margin-bottom: spacing(3);

			@include if-size("desktop") {
				margin-bottom: spacing(6);
			}
		}

		#value-prop-2-content {
			z-index: 100;
			padding-bottom: spacing(4);

			@include if-size("desktop") {
				padding-bottom: spacing(10);
			}

			#value-prop-2-title {
				grid-column: 1 / -1;
				width: fit-content;

				padding: spacing(1) spacing(2);
				margin-bottom: spacing(3);
				@include if-size("desktop") {
					padding: spacing(2) spacing(3);
					margin-bottom: spacing(6);
				}

				transition: background-position 0.5s ease-in-out;
				background: linear-gradient(to right, #f9db79 50%, transparent 50%);
				background-size: 200% 100%;
				background-position: 100% 0;

				&.highlight {
					background-position: 0% 0;
				}
			}

			#value-prop-2-list {
				grid-column: 1 / -1;
				list-style: none;
				display: flex;
				gap: spacing(3) spacing(4);
				flex-wrap: wrap;

				li {
					z-index: 100;
					width: 100%;

					@include if-size("desktop") {
						width: calc(50% - spacing(2));
					}
				}

				li:not(:last-child) {
					padding-bottom: spacing(2);
					@include if-size("desktop") {
						padding-bottom: spacing(4);
					}
				}

				.value-prop-heading {
					display: flex;
					align-items: center;
					gap: spacing(1);
					margin-bottom: spacing(1);
					color: $white;

					@include if-size("desktop") {
						font-size: 28px;
						line-height: 34px;
					}

					svg {
						fill: $white;
						width: 24px;
						height: 24px;
						margin-top: 6px;

						path {
							fill: $white;
						}

						@include if-size("desktop") {
							width: 32px;
							height: 32px;
						}
					}
				}

				.value-prop-description {
					color: $white;
				}
			}
		}
	}

	#value-prop-3 {
		padding-top: spacing(5);
		padding-bottom: spacing(6);
		row-gap: spacing(2);
		@include if-size("desktop") {
			padding: spacing(10) 0;
			row-gap: spacing(5);
		}

		align-items: center;

		#value-prop-3-content {
			grid-column: 1 / -1;

			@include if-size("desktop") {
				grid-column: 1 / 6;
			}

			#value-prop-3-content-title {
				margin-bottom: spacing(1);
				@include if-size("desktop") {
					margin-bottom: spacing(2);
				}
			}
		}

		#value-prop-3-partner-types {
			grid-column: 1 / -1;

			@include if-size("desktop") {
				grid-column: 6 / 13;
			}

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			row-gap: spacing(2);
			@include if-size("desktop") {
				row-gap: spacing(4);
			}

			.partner-type {
				flex: 1 0 50%;

				display: flex;
				flex-direction: column;
				align-items: center;

				.composite-img {
					position: relative;

					img {
						width: auto;
						max-height: 80px;

						@include if-size("desktop") {
							max-height: 130px;
						}
					}

					svg {
						z-index: -1;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						path {
							fill: $denim_lightest;
						}
					}

					.real-estate-agent {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								top: 50%;
								left: 50%;
							}
						}

						&-mobile-background {
							display: block;
							top: 50%;
							left: 50%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}

					.real-estate-investor {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								top: 55%;
							}
						}

						&-mobile-background {
							display: block;
							top: 50%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}

					.property-manager {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								left: 40%;
							}
						}

						&-mobile-background {
							display: block;
							top: 50%;
							left: 40%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}

					.real-estate-group {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								top: 55%;
							}
						}

						&-mobile-background {
							display: block;
							top: 50%;
							left: 55%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}

					.tech-company {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								left: 40%;
								top: 60%;
							}
						}

						&-mobile-background {
							display: block;
							top: 50%;
							left: 55%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}

					.content-creator {
						&-background {
							display: none;
							@include if-size("desktop") {
								display: block;
								top: 55%;
								left: 40%;
							}
						}

						&-mobile-background {
							display: block;
							top: 55%;
							left: 40%;
							@include if-size("desktop") {
								display: none;
							}
						}
					}
				}
			}

			p {
				text-align: center;
				margin: 0 auto;
			}
		}

		button {
			grid-column: 1/ -1;
			place-self: center;
		}
	}

	#landing-reviews-section {
		background-color: $sky-light;

		padding-top: spacing(5);
		padding-bottom: spacing(4);

		@include if-size("desktop") {
			padding-top: spacing(12);
			padding-bottom: spacing(6);
		}
	}

	#contact-section {
		background: $denim_lightest;

		#contact-section-inner {
			max-width: 1200px;
			display: flex;
			flex-direction: column;
			margin: 0 auto;
			gap: spacing(4);
			padding: spacing(4) spacing(2);

			@include if-size("desktop-xl") {
				gap: spacing(10);
				justify-content: space-between;
				align-items: center;
				flex-direction: row;
				padding-top: spacing(10);
				padding-bottom: spacing(5);
			}
		}

		.contact-section-info {
			padding-bottom: spacing(3);
			text-align: center;

			@include if-size("desktop-xl") {
				text-align: left;
				padding-bottom: spacing(4);
			}
		}

		.contact-section-header {
			grid-column: 1 / -1;

			h1 {
				white-space: nowrap;
				text-align: center;

				@include if-size("desktop-xl") {
					text-align: left;
				}
			}

			@include if-size("desktop") {
				grid-column: 1 / 6;
			}
		}

		#contact-step {
			grid-column: 7 / -1;
		}

		#partner-contact-form {
			grid-column: 1/-1;
			padding: spacing(3);
			-webkit-box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
			box-shadow: 1px 3px 7px 2px rgb(0 0 0 / 10%);
			border-radius: 16px;
			background-color: $sky-light;

			#partner-contact-btn {
				width: 100%;
				button {
					width: 100%;

					@include if-size("desktop") {
						width: auto;
						margin: 0 auto;
					}
				}
			}
		}
	}

	#tools-and-integrations-underlay {
		background-color: $denim;
		position: relative;
		overflow: hidden;

		&::before {
			width: 50%;
			left: 50%;
			bottom: -50%;
			transform: translateX(-50%);
			padding-top: 50%;
			content: "";
			position: absolute;
			border-radius: 1000000px;
			filter: blur(160px);
			background: rgba(0, 73, 141, 0.6);
			z-index: 1;
		}

		* {
			z-index: 2;
		}
	}

	#tools-and-integrations {
		padding: spacing(8) spacing(2);

		@include if-size("desktop") {
			padding: spacing(10);
		}

		#tools-and-integrations-tab-container {
			display: flex;
			gap: spacing(1.5);
			z-index: 100;

			#tools-and-integrations-tabs {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: spacing(2.5);

				@include if-size("tablet") {
					width: 380px;
				}
			}

			.tools-and-integrations-tab {
				padding: spacing(2);
				background: rgba(247, 249, 252, 0.3);
				border-radius: 8px;
				cursor: pointer;
				position: relative;

				.tools-and-intergrations-tab-header {
					position: relative;

					&::after {
						content: "+";
						position: absolute;
						right: 0px;
						top: 45%;
						transform: translateY(-50%);
						font-size: 32px;
						color: $white;
						line-height: 32px;
					}

					@include if-size("desktop") {
						&::after {
							all: unset;
						}
					}
				}

				@include if-size("desktop") {
					max-width: 400px;
				}

				p {
					color: $white;
				}

				#tools-and-integrations-active-tab-content-mobile {
					padding: spacing(2);

					@include if-size("desktop") {
						display: none;
					}

					#tool-api,
					#tool-widgets,
					#tool-cobranded_portal,
					#tool-partner_page {
						@keyframes fadein {
							from {
								opacity: 0;
							}
							to {
								opacity: 1;
							}
						}

						img {
							max-height: 100%;
							max-width: 100%;
							height: min-content;
							width: fit-content;
							animation: fadein 2s;
						}
					}

					#tool-cobranded_portal,
					#tool-widgets {
						display: flex;
						justify-content: center;

						img {
							max-width: 300px;

							@include if-size("desktop") {
								max-width: 540px;
							}
						}
					}

					#tool-api {
						flex: unset;
						height: fit-content !important;
						border-radius: 16px;
						overflow: hidden;
						box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
					}
				}

				&-active {
					@extend .tools-and-integrations-tab;
					background: $white;
					position: relative;

					.tools-and-intergrations-tab-header {
						position: relative;

						&::after {
							content: " ";
							position: absolute;
							right: 0px;
							top: 45%;
							transform: translateY(-50%);
							font-size: 32px;
							color: $azure;
							line-height: 32px;
						}

						@include if-size("desktop") {
							&::after {
								all: unset;
							}
						}
					}

					p {
						color: $azure;
					}

					@include if-size("desktop") {
						border-radius: 8px 0 0 8px;

						&:before {
							content: "";
							position: absolute;
							right: 0;
							top: 0;
							width: 36px;
							height: 100%;
							background: $white;
							transform: translateX(100%);
						}
					}
				}
			}
		}

		#tools-and-integrations-active-tab-content-desktop {
			display: none;

			@include if-size("desktop") {
				display: block;
				flex: 1;
			}
		}

		.tools-and-integrations-active-tab-content {
			display: none;
			width: 100%;
			height: 100%;

			@include if-size ("desktop") {
				background: $white;
				flex: 1;
				border-radius: 16px;
				padding: spacing(4);
				display: flex;
				flex-direction: column;
			}

			#tools-and-integrations-active-tab-content-container {
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			#tool-api,
			#tool-widgets,
			#tool-cobranded_portal,
			#tool-partner_page {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				img {
					max-height: 100%;
					max-width: 100%;
					height: min-content;
					width: fit-content;
					animation: fadein 2s;
					max-width: 540px;
				}
			}

			#tool-api {
				flex: unset;
				height: fit-content !important;
				border-radius: 16px;
				overflow: hidden;
				box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
			}
		}
	}

	#faqs {
		padding-top: spacing(3);
		padding-bottom: spacing(9);

		@include if-size("desktop") {
			padding-top: spacing(5);
			padding-bottom: spacing(10);
		}

		h1 {
			margin-bottom: spacing(2);
		}

		.faq-card-container {
			grid-column: 1 / -1;
			cursor: pointer;

			&:not(:first-of-type) {
				margin-top: spacing(2);
			}

			@include if-size("desktop") {
				grid-column: 1 / -1;
			}
		}

		.faq-card {
			background: $sky-light;
			padding: spacing(2);
			border-radius: 16px;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

			.expand-button {
				margin-left: auto;
			}

			.body-tiny {
				color: $denim_5;
			}
		}
	}
}
