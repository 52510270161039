#appeal-savings-description {
	grid-column: 1 / -1;
	font-weight: 600;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-savings-box {
	grid-column: 1 / -1;

	button {
		margin: spacing(4) auto 0;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#estimated-savings-title {
	margin-bottom: spacing(0);
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.savings-bubble-animation {
	height: 200px !important;
	margin-top: spacing(1);
	transform: unset !important;

	@include if-size("tablet") {
		height: 275px !important;
	}
}

#appeal-savings-video-container {
	margin-top: spacing(8);
	margin-bottom: spacing(4);
	grid-column: 1 / -1;

	video {
		width: 100%;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-pricing-section {
	width: 100%;
	background-color: $denim-lightest;
	padding: spacing(8) 0;
	margin-top: spacing(4);
}

#appeal-never-overpay-value-prop-section {
	grid-column: 1/-1;
	text-align: center;

	.appeal-never-overpay-value-prop-section-cards {
		padding-top: spacing(6);
		display: flex;
		gap: spacing(3);
		flex-wrap: wrap;
		justify-content: center;
		align-items: baseline;

		@include if-size("desktop") {
			gap: spacing(1);
		}
	}

	.appeal-never-overpay-value-prop-section-card {
		display: flex;
		flex-direction: column;
		text-align: center;
		flex: 1 1 0px;
		min-width: 200px;

		svg {
			margin: auto;
			width: 48px;
			height: 48px;
		}
	}
}

#savings-info-title {
	margin: spacing(4) 0 spacing(4);
	@include if-size("tablet") {
		margin: spacing(4) 0 0;
	}
}

.savings-info-subheader {
	padding-top: 4px;
}

#savings-info-title,
.savings-info-subheader {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-animation-container {
	position: fixed;
	height: 100%;
	width: 100%;
	background-color: $white;
	z-index: 1000;
	top: 0;
	opacity: 1;
	transition:
		opacity 400ms linear,
		visibility 400ms linear;
	visibility: visible;
	padding-top: spacing(20);

	@include if-size("tablet") {
		padding-top: spacing(36);
	}

	#appeal-savinsg-animation-grid {
		height: 100%;
		overflow: hidden;
	}

	svg {
		transition:
			opacity 400ms linear,
			visibility 1s linear;
	}

	&-hide {
		@extend #appeal-savings-animation-container;
		svg {
			opacity: 0;
			visibility: none;
		}
	}

	&-hide-full {
		@extend #appeal-savings-animation-container-hide;
		opacity: 0;
		visibility: none;
	}
}

#appeal-savings-not-served-title {
	margin-top: spacing(4);
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-not-served-description {
	margin-top: spacing(1);
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-not-served-parcel-text {
	margin-top: spacing(1);
	grid-column: 1 / -1;
	margin: spacing(2) 0 0;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-try-another {
	grid-column: 1 / -1;
	margin: spacing(3) 0 spacing(6);

	.button-v2 {
		margin: 0 auto;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-get-notified-title {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#appeal-savings-get-notified-form {
	margin-top: spacing(2);
	grid-column: 1 / -1;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@include if-size("tablet") {
		padding: spacing(4);
		background-color: $cloud;
		border-radius: 16px;
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	.appeal-savings-get-notified-fname,
	.appeal-savings-get-notified-lname {
		width: calc(50% - 8px);
	}

	.appeal-savings-get-notified-email,
	.appeal-savings-get-notified-phone {
		width: 100%;
		margin-top: spacing(2);
	}

	.button-v2 {
		margin: spacing(2) auto 0;
	}
}

#appeal-savings-exemption-savings-boxes {
	display: flex;
	flex-direction: column;
	margin-top: spacing(3);
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.appeal-savings-exemption-savings-box {
	position: relative;
	flex: 1;
	padding: spacing(2);
	border-radius: 8px;
	border: 1px solid cloud;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	opacity: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	cursor: pointer;
}

.appeal-savings-exemption-savings-box-free-filing {
	padding: spacing(0.5) spacing(2);
	background: #7f939d; // $denim 50% opacity
	position: absolute;
	bottom: 0;
	right: 16px;
	transform: translateY(50%);
	border-radius: 4px;

	> .body-tiny {
		color: $white;
	}
}

#exemptions-savings-explainer-section {
	margin-top: spacing(8);
	width: 100%;
	background: $cloud;
}
#exemptions-savings-explainer-section-content {
	grid-column: 1 / -1;
	padding: spacing(8) 0;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

#exemptions-savings-explainer-section-box-container {
	display: flex;
	flex-direction: column;
	margin: spacing(6) 0 0;
}

.exemptions-savings-box-explainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: spacing(4);
	border-radius: 16px;
	background-color: $white;

	&:not(:last-of-type) {
		margin-bottom: spacing(2);
	}

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
	}
}

.exemptions-savings-box-explainer-copy {
	padding-bottom: spacing(2);
	flex: 2;

	@include if-size("desktop") {
		padding-bottom: unset;
		padding-right: spacing(4);
	}
}

.exemptions-savings-box-explainer-divider {
	width: 100%;
	background-color: $cloud;
	height: 2px;

	@include if-size("desktop") {
		height: unset;
		width: 2px;
		align-self: stretch;
	}
}

.exemptions-savings-box-explainer-savings {
	flex: 1;
	padding-top: spacing(2);

	@include if-size("desktop") {
		padding-top: unset;
		padding-left: spacing(4);
	}
}

.exemption-savings-learn-more {
	display: block;
	margin: spacing(2) auto 0;
	cursor: pointer;
}

#appeal-savings-under-card-container {
	display: flex;
	flex-direction: column-reverse;

	@include if-size("desktop") {
		flex-direction: column;
	}

	.appeal-savings-callout-box {
		padding: spacing(1) spacing(2);
		border-radius: 8px;
		background-color: $denim-lightest;

		margin-top: spacing(1);
		@include if-size("desktop") {
			margin-top: spacing(2);
		}

		label {
			display: flex;
			align-items: center;
			gap: 4px;
			margin-bottom: 4px;
		}

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.mobile-flex-reverse {
		display: flex;
		flex-direction: column-reverse;
		@include if-size("desktop") {
			flex-direction: column;
		}
	}
}

#end-to-end-exemptions-flow {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

#appeal-savings-village-filing {
	margin-top: spacing(4);
	border: 1px solid $kelly-dark;
	border-radius: spacing(2);
	padding: spacing(2) spacing(3);

	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	gap: spacing(2);

	.bonus-village-filing-badge {
		border: 1px solid $kelly-dark;
		border-radius: 6px;
		padding: spacing(1) spacing(2);
		background-color: $kelly-light;
		width: fit-content;
		white-space: nowrap;
	}

	.village-filing-message {
		flex: 1 1 230px;
		color: $denim-medium;

		a {
			text-decoration: underline;
		}
	}
}

#village-grievance-filing-modal {
	top: 0px;
	height: 100%;

	.account-property-modal-card {
		padding-top: spacing(8);
	}

	#village-grievance-filing-modal-card-heading {
		p:first-of-type {
			margin-bottom: spacing(1);
		}
	}

	#village-grievance-filing-modal-card-heading-subtitle {
		color: $denim-medium;
	}

	#village-grievance-filing-modal-card-villages {
		margin-top: spacing(3);
		border-top: 1px solid $sand;
		padding-top: spacing(3);

		display: flex;
		justify-content: center;
		flex-direction: row;
		flex-wrap: wrap;
		gap: spacing(2);

		.village-list {
			margin-bottom: spacing(2);

			ul {
				margin-top: spacing(1);
				margin-left: spacing(2);
			}
		}
	}
}

#appeal-flow-ratings-savings-page {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: spacing(4) 0;
	flex-direction: column;
	@include if-size("desktop") {
		flex-direction: row;
	}
}

.ptc-bio {
	&-section {
		padding-top: spacing(4);
		grid-column: 1 / -1;

		@include if-size("desktop") {
			padding-top: spacing(4);
			grid-column: 2 / -2;
		}
	}

	&-card {
		border-radius: 16px;
		box-shadow: 0px 4px 24px 0px #7f939d2e;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: spacing(2);

		padding: spacing(2);
		@include if-size("desktop") {
			gap: spacing(3);
			flex-direction: row;
			align-items: flex-start;
			padding: spacing(2) spacing(3);
		}

		&-image {
			flex: 1;
			min-width: 200px;
			position: relative;

			box-shadow: 0px 4px 24px 0px #7f939d2e;
			border-radius: 8px;
			overflow: hidden;
			width: 200px;
			height: 200px;

			&-badge {
				position: absolute;
				top: 12px;
				left: 16px;

				font-size: 12px;
				line-height: 18px;
				letter-spacing: -0.02em;
				color: $denim_dark;

				padding: 2px 6px;
				background-color: $sky-light;
				border-radius: 4px;
			}
		}

		&-details {
			&-highlight {
				display: flex;
				align-items: center;
				gap: 4px;

				span {
					font-weight: normal;
				}

				&-icon {
					flex: 0;
					min-width: 16px;
					min-height: 16px;
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
