.block {
	display: block;
}

.relative {
	position: relative;
}

.list-inside {
	list-style-position: inside;
}

.list-outside {
	list-style-position: outside;
}
