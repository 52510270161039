.property-modal {
	position: fixed;
	top: 50px;
	left: 0;
	width: 100%;
	height: calc(100% - 50px);
	z-index: 100001;
	background-color: $white;
	overflow-x: hidden;

	@supports (overflow-y: overlay) {
		overflow-y: overlay;
	}

	@supports not (overflow-y: overlay) {
		overflow-y: auto;
	}

	@include if-size("desktop") {
		background-color: rgba($denim, 0.75);
		top: 0;
		height: 100%;
		width: 100%;
		padding: spacing(4);
	}

	header {
		padding: spacing(2) spacing(3);
		@include if-size("desktop") {
			padding: spacing(3);
		}

		border-bottom: solid 1px $slate;

		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: spacing(3);

		.profile-modal-close {
			position: relative;
			top: unset;
			right: unset;

			svg {
				top: unset;
				right: unset;
			}
		}
	}

	&-secondary {
		@extend .property-modal;

		.account-property-modal-card {
			width: 90%;
			max-width: 560px;
			min-height: 250px;
			box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);
			padding: 0 !important;
			margin: spacing(8) auto !important;

			@include if-size("tablet") {
				margin: spacing(16) auto !important;
			}
		}

		.profile-modal-close {
			top: 21px !important;
			right: 29px !important;
			z-index: 100;

			@include if-size("desktop") {
				top: 29px !important;
				right: 29px !important;
			}

			// svg > path {
			// 	fill: $denim;
			// }
		}
	}

	&-fullscreen {
		@extend .property-modal;
		width: 100%;
		height: 100%;

		.account-property-modal-card {
			width: 100%;
			height: 100%;
			max-width: none;
			max-height: none;
			box-shadow: unset;
			border-radius: none;
		}
	}

	.profile-modal-back {
		position: absolute;
		top: 16px;
		left: 16px;
		cursor: pointer;
		color: $denim;

		svg > path {
			fill: $denim;
		}
		span {
			margin-left: 4px;
		}

		@include if-size("desktop") {
			left: 32px;
			top: 32px;
		}
	}

	.profile-modal-close {
		position: absolute;
		top: 21px;
		right: 29px;
		cursor: pointer;
		z-index: 10000002;

		svg {
			height: 14px;
			width: 14px;

			@include if-size("desktop") {
				right: 29px;
				top: 29px;
			}
		}
	}

	.account-property-modal-card {
		z-index: 100000;
		grid-column: span 1;
		background-color: $white;
		border-radius: 16px;
		position: relative;

		padding: spacing(6) spacing(2);

		@include if-size("desktop") {
			padding: spacing(4) spacing(12);
			margin-bottom: spacing(8);
			overflow-x: hidden;
			grid-column: span 2;
			box-shadow: $primary-box-shadow;
		}
	}
}
.account-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10001;
	height: 100vh;
	width: 100vw;
	overflow-y: auto;
}

.account-modal-inner {
	min-height: 100vh;
	width: 100%;
	position: relative;
	overflow-y: auto;
	padding-bottom: spacing(4);
}

.account-modal-underlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($cloud, 0.75);
	z-index: 991;
}

.account-modal-card {
	z-index: 992;
	margin-top: spacing(4);
	background-color: $white;
	padding: spacing(4);
	border-radius: 16px;
	position: relative;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 2 / -2;
	}
}

#account-modal-close {
	top: 12px;
	right: 12px;
	cursor: pointer;
	position: absolute;
	height: 24px;
	width: 24px;
	padding: 5px;
	border-radius: 50%;
	border: 2px solid #000;
	display: grid;
	place-items: center;

	@include if-size("desktop") {
		right: 32px;
	}
}

#account-updated-terms-modal {
	ul {
		list-style-position: inside;
		padding-left: spacing(2);
		margin: spacing(2) 0 spacing(4);
	}
}

.detailed-appeal-status-view-cta {
	margin: spacing(4) auto 0;
}

#preparing-tax-data-modal {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 16px;

	.value-prop-card {
		border-radius: spacing(1);
		padding: spacing(2);
		display: flex;
		align-items: center;
		gap: spacing(2);
		background: $white;
		box-shadow: 1px 3px 7px 2px #0532490d;
		border: solid 1px $cloud;

		svg {
			min-width: 24px;
			min-height: 24px;
		}
	}
}

#property-modal-reset-ui {
	padding: 0;
}

#help-text-modal-container {
	background-color: rgba($denim, 0.75);
	z-index: 10000000;
}
#help-text-modal {
	padding: 0;
	width: 90%;
	.help-text-content {
		max-width: 500px;
		.help-text-title {
			padding: 12px;
			border-bottom: 1px solid $slate;
			@include if-size("tablet") {
				padding: 24px;
			}
		}
		.help-text {
			padding: 16px;
			@include if-size("tablet") {
				padding: 24px 40px;
			}
		}
	}
}

#energy-plan-info-modal-container {
	background-color: rgba($denim, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	.property-grid {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.bulb-img-container {
		display: flex;
		align-items: center;
		justify-content: center;
		.bulb-img {
			width: 200px;
		}
	}
	#energy-plan-info-modal {
		max-width: 90%;
		width: 800px;
		padding: spacing(4) spacing(2);
		margin: auto;
	}
}

#light-enrollment-modal-container {
	z-index: 10000000;
	background-color: rgba($denim, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	.property-grid {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#light-enrollment-modal {
		width: 400px;
		padding: spacing(4) spacing(2);
		padding: 0;
		margin: auto;
	}
}

#light-savings-breakdown-modal-container {
	z-index: 10000000;
	background-color: rgba($denim, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	.property-grid {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#light-savings-breakdown-modal {
		width: 840px;
		max-width: 90%;
		padding: spacing(4) spacing(2);
		margin: auto;
		.savings-breakdown-chart-container {
			@include if-size("tablet") {
				border: 1px solid $slate;
				border-radius: 16px;
				padding: spacing(2);
			}
			.savings-breakdown-chart-header {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				margin-bottom: spacing(3);
				.right-column {
					display: flex;
					gap: spacing(1);
					flex-direction: column;
					width: 100%;
					margin-top: spacing(2);
					@include if-size("tablet") {
						flex-direction: row;
						width: unset;
						margin-top: 0;
					}
					.chart-header-box {
						padding: spacing(1) 12px;
						border: 1px solid $slate;
						border-radius: 8px;
						background-color: $denim_lightest;
						width: 100%;
						flex: 1;
						display: flex;
						justify-content: space-between;
						align-items: center;
						@include if-size("tablet") {
							width: unset;
							flex-direction: column;
							flex: unset;
							text-align: right;
							align-items: unset;
						}
						&.fancy-box {
							position: relative;
							background-color: $kelly_lightest;
							border-radius: 8px;
							border: none;

							&::before {
								content: "";
								position: absolute;
								top: -1px;
								left: -1px;
								right: -1px;
								bottom: -1px;
								border-radius: 8px;
								background: linear-gradient(180deg, #57c975 0%, #1c7ce5 100%);
								z-index: -1;
							}
						}
						.fancy {
							background: linear-gradient(90deg, #1c7ce5 0%, #57c975 112.11%);
							-webkit-background-clip: text;
							-webkit-text-fill-color: transparent;
							background-clip: text;
							text-fill-color: transparent;
						}
					}
				}
			}

			.savings-breakdown-chart-body {
				margin: spacing(4) 0;

				.chart {
					width: 100%;
					min-height: 300px;

					.custom-tooltip {
						background-color: $sky;
						padding: 12px;
						border-radius: 8px;
						opacity: 0.9;
						display: flex;
						flex-direction: column;
						gap: 4px;
					}
				}
			}
		}
	}
}

#light-enrollment-rate-modal-container {
	z-index: 10000000;
	background-color: rgba($denim, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	.property-grid {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#light-enrollment-rate-modal {
		width: 400px;
		padding: spacing(4) spacing(2);
		padding: 0;
		margin: auto;
	}
}

#light-enrollment-billing-modal-container {
	z-index: 10000000;
	background-color: rgba($denim, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
	.property-grid {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	#light-enrollment-billing-modal {
		width: 400px;
		padding: spacing(4) spacing(2);
		padding: 0;
		margin: auto;
	}
}
