#asc-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: spacing(2);
	background: $kelly-lightest;
	z-index: 1000000;
	overflow: hidden;
	border-radius: 24px 24px 0px 0px;

	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		height: 200px;
		width: 200px;
		border-radius: 200px;
		background: #99edbabf;
		transform: translateX(-75%) translateY(-50%);
	}

	@include if-size("desktop") {
		padding: spacing(4) spacing(2);
	}

	#animation {
		height: 64px;
		width: auto;
		z-index: 1000005;
		position: relative;
		margin-right: spacing(1);
	}

	#asc-banner-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: spacing(2);

		@include if-size("desktop") {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		p.sm {
			display: none;
			@include if-size("desktop") {
				display: block;
			}
		}
	}

	#asc-banner-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;

		@include if-size("desktop") {
			margin-left: spacing(4);
		}
	}

	#asc-banner-btn-group {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: spacing(2);

		.button-v2 {
			white-space: nowrap;
		}
	}
}

#asc-banner-commercial {
	@extend #asc-banner;

	background: linear-gradient(
			180deg,
			#bbccd4 -41.01%,
			rgba(247, 249, 252, 0) 100%
		),
		#fff;

	&::after {
		background: #bbccd4;
	}
}
