#heb-landing-banner {
	background-color: $sunflower-light;
}

#heb-landing-hero-section-container {
	flex: 1;
	display: flex;
	flex-direction: column;
	background-color: $sunflower;

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
		min-height: 540px;
	}

	#heb-landing-hero-hand {
		max-width: calc(100% - spacing(4));
		max-height: 300px;
		height: auto;
		width: fit-content;
		display: block;
		margin: spacing(4) 0 spacing(4) auto;

		@include if-size("desktop") {
			margin: 0;
			margin-left: auto;
			max-width: 40%;
			max-height: 75%;
			height: auto;
		}
	}

	#heb-landing-hero-title {
		text-align: left;
		margin: 0;
		font-family: "sofia-pro";
		font-size: 32px;
		font-style: normal;
		font-weight: 800;
		line-height: 40px; /* 125% */
		letter-spacing: -0.32px;

		@include if-size("desktop") {
			font-size: 56px;
			font-style: normal;
			font-weight: 800;
			line-height: 64px; /* 114.286% */
			letter-spacing: -1.12px;
		}
	}

	#landing-hero-address-input {
		@include if-size("desktop") {
			align-items: flex-start;
			margin: 0;
			margin-left: spacing(8);
		}
	}
}

#heb-terms {
	li,
	ol {
		li {
			margin: spacing(1) 0;
		}
	}
}
