.kebab-button {
	display: flex;
	flex-direction: column;
	gap: 3px;
	padding: spacing(1);

	&:hover {
		cursor: pointer;
	}

	.kebab-dot {
		background-color: $denim;
		width: 3px;
		height: 3px;
		border-radius: 50%;
	}
}

.kebab-menu {
	position: relative;
	.kebab-menu-popover {
		position: absolute;
		background-color: $denim-lightest;
		padding: 12px spacing(4);
		border-radius: 8px;
		box-shadow: 0px 4px 24px 0px #7f939d2e;

		top: 40px;
		left: calc(100% + 4px);
		@include if-size("desktop-xl") {
			left: unset;
			top: -8px;
			right: calc(100% + 4px);
		}
	}
}
