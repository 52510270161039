.landing-dark {
	p,
	h1,
	h2,
	h3 {
		color: white;

		&.denim-black {
			color: $denim-black;
		}
	}
	.content-wrapper {
		margin: 0 auto;
		max-width: 1136px;
		position: relative;
		padding: 0 16px;
		text-align: center;
		//overflow: hidden;
		@include if-size("desktop") {
			padding: 0;
			text-align: left;
		}
	}
	.white-outline {
		border-color: white;
		color: white;
	}
	#header-section {
		background-color: $denim-black;
		@include if-size("desktop") {
			padding: 16px 0 64px;
		}
		.heading-content {
			h1 {
				width: 775px;
				max-width: 100%;
			}
			p {
				margin-top: 20px;
				width: 775px;
				max-width: 100%;
			}
		}
		.header-bottom-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			position: relative;
			flex-direction: column;
			@include if-size("desktop") {
				flex-direction: row;
			}
			.left-container {
				display: flex;
				flex-direction: column;
				gap: 20px;
				justify-content: center;
				align-items: center;
				@include if-size("desktop") {
					align-items: start;
					justify-content: start;
				}
				.btns-container {
					display: flex;
					gap: 16px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include if-size("desktop") {
						flex-direction: row;
					}
				}
				.trusted-by-container {
					display: flex;
					gap: 5px;
					justify-content: start;
					align-items: center;
					.trusted-by-imgs {
					}

					.trusted-by-content {
						display: flex;
						flex-direction: column;
						justify-content: start;
						.star-icon {
							text-align: left;
						}
						.trusted-by-text {
							.trusted-by-title {
							}
						}
					}
				}
			}
			.right-container {
				position: relative;
				width: 100%;
				height: auto;
				margin-top: 20px;
				@include if-size("desktop") {
					width: 50%;
					margin-top: 0;
				}
				.product-page-screenshot-container {
					position: absolute;
					right: 0;
					top: 0;
					width: 100%;
					max-width: 100vw;
					overflow: hidden;
				}
			}
		}
	}
}

#tax-bill-management-landing.landing-dark {
	#header-section {
		padding-bottom: 0;
		padding-top: spacing(3);
		@include if-size("desktop") {
			padding-top: spacing(4);
		}

		.header-bottom-wrapper {
			display: flex;
			margin-top: 0;

			@include if-size("desktop") {
				padding: 0 spacing(2);
				align-items: center;
			}

			.left-container {
				flex: 1;
				gap: 12px;

				h1 {
					font-family: "sofia-pro";
					font-size: 32px;
					font-style: normal;
					font-weight: 727;
					line-height: 40px;
					letter-spacing: -0.32px;

					@include if-size("desktop") {
						font-family: "sofia-pro";
						font-size: 56px;
						font-style: normal;
						font-weight: 727;
						line-height: 64px;
						letter-spacing: -1.12px;
					}
				}

				.btns-container {
					margin-top: 12px;
					@include if-size("desktop") {
						margin-top: 20px;
					}
				}

				.trusted-by-container {
					margin-top: 12px;
					@include if-size("desktop") {
						margin-top: 4px;
					}
				}
			}

			.right-container {
				flex: 1;
				.product-page-screenshot-container {
					position: relative;

					@include if-size("desktop") {
						min-width: 600px;
					}
				}
			}
		}
	}

	#landing-marquee-section {
		margin-top: spacing(1);
		padding-top: spacing(2);
		background: linear-gradient(180deg, #000a0f 0%, #0d425d 100%);

		@include if-size("desktop") {
			margin-top: spacing(11);
			padding-top: spacing(2);
			padding-bottom: spacing(2);
		}

		.marquee-featured-in {
			@extend .label;
			color: $white;
		}

		.marquee-featured-card-white-transparent-background {
			width: 120px;
			height: 87px;
		}
	}

	#what-we-offer-section {
		background-color: $sky-light;
		padding: spacing(5) spacing(2);

		h3,
		h4,
		p {
			color: $denim;
		}

		@include if-size("desktop") {
			padding: spacing(8) spacing(2);
		}

		.title.content-wrapper {
			text-align: left;
			margin-bottom: spacing(2);
			@include if-size("desktop") {
				margin-bottom: spacing(4);
			}
		}

		.two-columns.content-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: 40px;
			@include if-size("desktop") {
				flex-direction: row;
				gap: 48px;
			}

			&:first-of-type {
				margin-bottom: spacing(5);
			}

			.left-column,
			.right-column {
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: spacing(2);
			}

			.faded-block {
				border-radius: 8px;

				background: linear-gradient(
					180deg,
					#bbccd4 -41.01%,
					rgba(247, 249, 252, 0) 100%
				);

				padding: spacing(3) spacing(2);

				@include if-size("desktop") {
					padding: spacing(4) spacing(2);
				}

				p {
					font-weight: 400;
				}
			}
		}

		.streamlined-tax-bill-payments-svg {
			height: 160px;
			width: auto;
		}
		.smart-forecasting-and-budgeting-svg {
			height: 160px;
			width: auto;
		}
		.early-payment-discounts-svg {
			height: 160px;
			width: auto;
		}
		.payment-and-delinquency-monitoring-svg {
			height: 160px;
			width: auto;
		}
	}

	#how-it-works-section {
		background: linear-gradient(180deg, #0d435f 0%, #000a0f 100%);

		padding: spacing(5) spacing(2);

		@include if-size("desktop") {
			padding: spacing(8) spacing(2);
		}

		.title.content-wrapper {
			text-align: center;
			margin-bottom: spacing(4);
		}

		.step-lines {
			margin: 0 auto;

			.step-spacer {
				height: 50px;
				width: 45px;
				border-right: solid 1px $white;
			}

			.step-line {
				display: flex;
				flex-direction: row;
				gap: spacing(2);
				align-items: center;
				justify-content: flex-start;

				.step-label {
					color: $denim-medium;
					background-color: $white;
					padding: 6px 14px;
					border-radius: 50px;
					white-space: nowrap;
				}

				.step-text {
					color: $white;
					font-weight: 400;
				}
			}
		}
	}

	#we-partner-with-section {
		background-color: $sky-light;

		padding: spacing(5) spacing(2);

		@include if-size("desktop") {
			padding: spacing(8) spacing(2);
		}

		h2,
		h3,
		h4,
		p {
			color: $denim;
		}

		.content-wrapper {
			display: flex;
			gap: spacing(2);

			flex-wrap: wrap;
			@include if-size("desktop") {
				flex-wrap: nowrap;
				align-items: center;
			}

			.left-container {
				max-width: 464px;
			}

			.right-container {
				flex: 1;
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: spacing(2);
				row-gap: spacing(2);

				@include if-size("desktop") {
					padding: 0 12px;
					column-gap: spacing(3);
					row-gap: spacing(4);
				}

				.comp-block-tile {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.gif-blob-composition {
					position: relative;

					img {
						position: relative;
						z-index: 5;
						width: 80px;
						height: 80px;

						@include if-size("desktop") {
							width: 130px;
							height: 130px;
						}
					}
					svg {
						width: 80px;
						height: 84px;
						z-index: 1;
						position: absolute;

						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						@include if-size("desktop") {
							width: 100px;
							height: 105px;
						}
					}
				}
			}
		}
	}

	#contact-section-wrapper {
		background: linear-gradient(0deg, #ffffff 0%, #edf6ff 97.63%);
		padding: 24px 0;

		.content-wrapper {
			h3 {
				color: $denim;
				text-align: center;
			}

			p {
				color: $denim;
				text-align: center;
			}

			#commercial-contact-form {
				@include if-size("desktop") {
					background-color: white;
				}
				box-shadow: none;

				&.contact-form-mobile-box {
					background-color: white;
					box-shadow: 0px 4px 24px 0px rgba(127, 147, 157, 0.18);
					display: flex;
					flex-direction: column;
					align-items: center;

					padding: spacing(4);
					@include if-size("desktop") {
						padding: spacing(4) spacing(2);
					}

					p:nth-child(2) {
						font-weight: 400;
					}

					.status-dot-svg {
						width: 88px;
						height: 88px;
						margin-bottom: spacing(2);
					}

					a {
						text-decoration: underline;
					}
				}
			}
		}
	}
}
