#onboarding-survey-builder {
	.onboarding-cumulative-savings {
		&:hover {
			cursor: pointer;
		}

		width: fit-content;
		margin-left: auto;
		display: flex;
		align-items: center;
		gap: 4px;

		// background-color: $sky;
		padding: 4px 8px;
		border-radius: 100px;
		@include if-size("desktop-xl") {
			padding: 6px 20px 6px 16px;
			border-radius: 56px;
		}

		animation: bg-color-pulse 0.4s ease-in-out forwards;
		@keyframes bg-color-pulse {
			from {
				background-color: rgb(203, 239, 223);
			}
			to {
				background-color: $sky;
			}
		}

		.little-coin {
			width: 20px;
			height: 20px;
			@include if-size("desktop-xl") {
				width: 24px;
				height: 24px;
			}

			animation: coin-rotate 0.4s ease-in-out forwards;
			@keyframes coin-rotate {
				from {
					transform: rotate3d(0, 1, 0, 0deg);
				}
				to {
					transform: rotate3d(0, 1, 0, 360deg);
				}
			}
		}

		@keyframes text-color-pulse {
			from {
				color: $kelly-dark;
			}
			to {
				color: $azure;
			}
		}

		.savings-text {
			display: flex;

			.est-savings-prefix,
			.dollar-symbol {
				animation-fill-mode: forwards;
				animation: text-color-pulse 0.4s ease-in-out forwards;
				white-space: nowrap;
			}

			.dollar-symbol {
				margin: auto;
				font-size: 16px;
				line-height: 16px;
			}

			.est-savings-prefix {
				margin-right: 4px;
				display: none;
				@include if-size("desktop") {
					display: inline-block;
				}
			}

			.ticker-view {
				.ticker-digit {
					display: flex;
					justify-content: center;

					span {
						font-size: 16px;
						line-height: 16px;
						width: min-content;
						animation-fill-mode: forwards;
						animation: text-color-pulse 0.4s ease-in-out forwards;
						white-space: nowrap;
					}
				}
			}
		}

		&-modal-content {
			padding: 0 spacing(2);

			max-width: 392px;
			margin: 0 auto;
			margin-top: 140px;
			@include if-size("desktop") {
				margin-top: 130px;
			}

			&-main {
				background-color: $white;
				border-radius: 16px;

				.property-address {
					padding: spacing(3);
					padding-bottom: 0;

					display: flex;
					align-items: center;
					gap: spacing(1);

					.denim-house-silhoutte {
						width: 20px;
						height: 20px;
					}

					.cross-no-circle {
						width: 14px;
						height: 14px;
					}
				}

				.saving-value-wrapper {
					position: relative;

					overflow: hidden;
					min-width: 100%;

					.green-graph {
						z-index: 0;
						position: absolute;
						mask-image: linear-gradient(to top, transparent 0%, black 10%);

						top: 12px;
						right: -24px;
					}

					.total-savings-amount {
						max-width: 100%;
						margin: 0 spacing(3);
						margin-bottom: spacing(1.5);
						font-weight: 727;
						font-size: 48px;
						line-height: 56px;
						letter-spacing: -0.01em;
						@include if-size("desktop") {
							font-size: 64px;
							line-height: 74px;
							letter-spacing: -0.03em;
						}
					}

					.total-savings-title {
						max-width: 100%;
						margin: 0 spacing(3);
						margin-bottom: spacing(2);

						font-weight: 622;
						font-size: 16px;
						line-height: 19px;
						color: $denim;

						@include if-size("desktop") {
							font-size: 20px;
							line-height: 28px;
						}
					}
				}

				.savings-categories {
					margin: 0 spacing(3);
					padding-bottom: spacing(2);

					> * + * {
						margin-top: 6px;
						@include if-size("desktop") {
							margin-top: spacing(1);
						}
					}
				}

				.savings-category {
					display: flex;
					justify-content: space-between;
					gap: spacing(12);

					.category {
						color: $denim-medium;
						font-weight: 622;
						font-size: 14px;
						line-height: 16px;
						letter-spacing: -0.015em;

						@include if-size("desktop") {
							font-size: 16px;
							line-height: 22px;
							letter-spacing: -0.01em;
						}
					}

					.savings-amount {
						color: $kelly-dark;
						background-color: $kelly-light;
						padding: 2px 12px;
						border-radius: 8px;

						font-weight: 622;
						font-size: 14px;
						line-height: 16px;
						letter-spacing: -0.015em;

						@include if-size("desktop") {
							font-size: 16px;
							line-height: 22px;
							letter-spacing: -0.01em;
						}
					}
				}
			}

			&-mini {
				padding: spacing(2);
				background-color: #0000003d;
				border-radius: 16px;
				backdrop-filter: blur(20px);

				display: flex;
				align-items: center;
				gap: spacing(2);

				.little-coin {
					min-width: 56px;
					max-width: 56px;
					min-height: 56px;
					max-height: 56px;
				}
			}
		}
	}

	.survey-builder-template-multiple-flows-fullscreen-nav {
		background-color: $denim-lightest;
		@include if-size("desktop") {
			background-color: white;
		}
	}

	#surveys-nav {
		width: 100%;
		overflow: auto;
		background-color: $denim-lightest;
		padding: spacing(2) 0;
		margin: 0 spacing(2);

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}

		&::-webkit-scrollbar-thumb {
			width: 0;
			height: 0;
		}

		@include if-size("desktop") {
			margin: unset;
			padding: unset;
			background-color: unset;
			@include styled-scrollbar();
		}

		&-address {
			display: none;

			@include if-size("desktop") {
				display: flex;
				align-items: center;
				gap: spacing(1);

				.denim-house-silhoutte {
					width: 20px;
					height: 20px;
				}
			}
		}

		.surveys-nav-groups {
			min-width: 100%;
			display: flex;
			flex-direction: row;
			gap: 2px;

			@include if-size("desktop") {
				flex-direction: column;
				gap: spacing(1);
			}
		}

		.surveys-nav-group {
			white-space: nowrap;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 0;
			gap: 2px;

			.surveys-nav-group-progress-dot {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: solid 1px $denim-extra-light;
				margin: 2px;
			}

			.get-started-user {
				width: 16px;
				height: 16px;
			}
			.ways-to-save-tag {
				width: 16px;
				height: 16px;
			}
			.evidence-package-folder {
				width: 16px;
				height: 16px;
			}
			.final-details-paper {
				width: 16px;
				height: 16px;
			}

			.check-in-azure-circle {
				display: none;
				width: 16px;
				height: 16px;
			}

			&.active,
			&.completed {
				p.sm {
					color: $azure;
				}

				.surveys-nav-group-progress-dot {
					border-color: $azure;
					background-color: $azure;
				}

				.get-started-user,
				.ways-to-save-tag,
				.evidence-package-folder,
				.final-details-paper {
					path {
						fill: $azure;
					}
				}
			}

			&.completed {
				.check-in-azure-circle {
					display: block;
					margin-left: auto;
				}
			}

			@include if-size("desktop") {
				background-color: $denim-lightest;
				padding: 6px spacing(1);
				gap: 4px;

				p.sm {
					font-weight: 700;
				}

				.surveys-nav-group-progress-dot {
					display: none;
				}

				.get-started-user {
					width: 21px;
					height: 21px;
				}
				.ways-to-save-tag {
					width: 24px;
					height: 24px;
				}
				.evidence-package-folder {
					width: 24px;
					height: 24px;
				}
				.final-details-paper {
					width: 18px;
					height: 18px;
				}

				.check-in-azure-circle {
					display: none;
					width: 20px;
					height: 20px;
				}
			}
		}

		.surveys-nav-group-spacer {
			min-width: 30px;
			border-top: 1px solid $denim-extra-light;
			margin: auto 0;

			@include if-size("desktop") {
				width: unset;
				border-top: unset;
				margin: unset;
				height: 24px;
				margin-left: 10px;
				border-left: 1px solid $denim-extra-light;
			}
		}
	}

	#property-equity-details-content {
		padding: 0;
	}

	#property-equity-details-footer {
		border-top: 0;
		padding: 0;

		margin-top: spacing(3);
	}

	.concierge-payment-wrapper {
		.payment-card-wrapper,
		.plan-details-card {
			width: 100%;

			@include if-size("desktop") {
				width: unset;
			}
		}
	}

	.concierge-confirmation-content {
		gap: 0;
	}

	.congrats-handshake {
		width: 128px;
		height: auto;
	}

	#referral-modal {
		border-radius: 16px;
		margin: 0 auto;
		margin-top: spacing(3);
		position: relative;
		transform: none;
		top: unset;
		left: unset;
		flex-direction: column;
		max-width: 100%;
		min-width: unset;

		@include if-size("desktop-xl") {
			flex-direction: row-reverse;
		}

		.referral-modal-image-container {
			&.give-20-get-20 {
				@include if-size("mobile-lg") {
					display: none;
				}

				@include if-size("desktop-xl") {
					display: block;
				}
			}

			#referral-modal-image-dogs {
				transform: unset;
				position: relative;
				top: unset;
				left: unset;
				height: auto;
			}
		}

		#referral-modal-body {
			h1.display1 {
				white-space: wrap;
			}
		}
	}
}
