$insurance_question_transition_time: 250ms;

.property-insurance-content {
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 100%;
	overflow: hidden;

	@include if-size("desktop-xl") {
		gap: 12px;
	}

	.property-insurance-card {
		display: flex;
		flex-direction: column;
		gap: 8px;

		border-radius: 8px;
		padding: 8px;
		background-color: $denim-lightest;
		@include if-size("desktop-xl") {
			border-radius: 8px;
			padding: 12px 16px;
			background-color: $white;
		}
	}

	.property-insurance-title-row {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&.before-survey {
		&-mobile {
			@include if-size("desktop-xl") {
				display: none;
			}

			.house-shield {
				flex: 0 0 65px;
				width: 65px;
				height: 65px;
			}

			.property-insurance-title-row {
				gap: 8px;
			}
		}

		&-desktop {
			display: none;
			@include if-size("desktop-xl") {
				display: flex;
			}

			.house-shield {
				flex: 0 0 112px;
				width: 112px;
				height: 112px;
			}

			.property-insurance-title-row {
				gap: 16px;
				padding: 16px 0;
			}
		}
	}

	&.after-survey {
		.house-shield {
			flex: 0 0 32px;
			width: 32px;
			height: 32px;

			@include if-size("desktop-xl") {
				flex: 0 0 40px;
				width: 40px;
				height: 40px;
			}
		}

		.property-insurance-title-row {
			place-self: flex-start;
			gap: 8px;
		}

		#insurance-survey-confirmation-card-container {
			margin-top: 0;
		}

		.insurance-survey-confirmation-card {
			border: 1px solid $slate;
			box-shadow: none;

			background-color: $white;
			@include if-size("desktop-xl") {
				background-color: $denim_lightest;
			}
		}
	}
}

#insurance-no-quotes-contact-card {
	@extend .insurance-survey-confirmation-card;

	p.sm.bold {
		margin-bottom: 4px;
	}
}

.provided-by-covered {
	width: fit-content;

	.label {
		font-size: 10px;
		margin-right: spacing(1);
	}
}

#insurance-survey-modal {
	@include if-size("desktop") {
		padding: spacing(4) 0 0;
		max-width: 1000px;
		width: 100%;
		margin: auto;
	}

	#insurance-survey-confirmation-card-container {
		padding: 0 spacing(1);
	}
}

#account-insurance-modal {
	overflow: hidden;

	#account-insurance-modal-text {
		padding: spacing(2);

		h3 {
			margin-bottom: 4px;
		}

		.button-v2 {
			margin: spacing(2) auto 0;
		}
	}
}

#account-insurance-modal-joined {
	padding: spacing(4);

	#account-insurance-waitlist-container {
		margin: spacing(4) auto;
		text-align: center;

		h2 {
			color: $azure;
		}
	}
}

#insurance-survey {
	position: relative;
	height: 100%;
	overflow: hidden;

	@include if-size("desktop") {
		margin: spacing(2) spacing(3) spacing(4);
	}
}

.insurance-survey-question {
	background-color: $denim-lightest;
	width: 100%;
	padding: spacing(4);
	border-radius: 16px;
	margin: spacing(1) 0;

	.insurance-survey-question-inner-footnote {
		opacity: 50%;
	}
}

#insurance-question-wrapper {
	position: relative;
}

#insurance-question-container {
	position: relative;
	height: 500px;
	overflow: hidden;
}

#insurance-survey-progress-bar-container {
	p {
		text-align: center;
	}

	.progress-bar-container {
		background-color: transparent;
	}
}

#insurance-survey-progress-bar {
	max-width: 400px;
	margin: 0 auto;

	.label {
		text-align: center;
		margin-top: 4px;
	}
}

.insurance-question {
	position: absolute;
	height: fit-content;
	width: 100%;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 10;
	transition:
		top $insurance_question_transition_time linear,
		transform $insurance_question_transition_time linear;
}

.insurance-question-up-arrow-container {
	cursor: pointer;
	height: 20px;
	width: 20px;
	z-index: 9;
	margin: 0 auto;

	&-hidden {
		@extend .insurance-question-up-arrow-container;
		opacity: 0;
		pointer-events: none;
	}
}

.next-btn {
	margin: spacing(2) auto;

	&-hidden {
		@extend .next-btn;
		opacity: 0;
	}
}

.insurance-question-behind {
	top: -200%;
}

.insurance-question-prev {
	top: -200%;

	@include if-size("desktop") {
		transform: translateY(-100%);
		top: 140px;
	}
}

.insurance-question-current {
	top: 0;

	@include if-size("desktop") {
		top: 50%;
		transform: translateY(-50%);
	}
}

.insurance-question-next {
	top: 200%;

	@include if-size("desktop") {
		top: calc(100% - 55px);
	}
}

.insurance-question-ahead {
	top: 200%;
}
.insurance-survey-question-overlay-container {
	width: 100%;
	height: 100%;
	position: relative;
}

#insurance-question-bottom-overlay,
#insurance-question-top-overlay {
	display: none;
	border-radius: 16px;
	backdrop-filter: blur(10px);
	z-index: 1000000;
	position: absolute;
	top: 0;
	transform: translateX(-8px) translateY(-8px);
	width: calc(100% + 16px);
	height: 70px;

	@include if-size("desktop") {
		display: block;
	}
}

#insurance-question-top-overlay {
	transform: translateX(-8px) translateY(-20px);
}

#insurance-question-bottom-overlay {
	top: calc(100% - 42px);
	transform: translateX(-8px) translateY(-8px);
}

#insurance-survey-title {
	margin: spacing(2) 0 spacing(4);
}

.up-arrow-disabled {
	cursor: not-allowed !important;
}

.insurance-marital-options-container {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: fit-content;
	margin-top: spacing(2);
	width: 100%;

	@include if-size("desktop") {
		flex-direction: row;
		width: fit-content;
	}
}

.insurance-marital-option {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $sand;
	border-radius: 16px;
	text-align: center;
	cursor: pointer;
	margin-bottom: spacing(1);
	padding: spacing(1) spacing(2);
	width: 100%;

	&:not(:last-of-type) {
		margin-right: spacing(4);
	}

	@include if-size("desktop") {
		flex-direction: column-reverse;
		justify-content: center;
		margin-bottom: 0px;
		padding: spacing(2) spacing(6);
	}

	input {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -10px;
		height: 1px;
		width: 1px;
		opacity: 0;
	}

	&-selected {
		@extend .insurance-marital-option;
		outline: 2px solid $azure;
	}
}

.insurance-question-title {
	font-weight: 600;
	margin-bottom: spacing(2);
}

.insurance-question-slider-label {
	padding-left: spacing(1);
}

.insurance-birthday-input input {
	width: 25px;
	height: 25px;
	margin-right: spacing(1);
	text-align: center;
	padding: 0;
	appearance: none;

	@include if-size("desktop") {
		padding: spacing(1);
		margin-right: spacing(2);
		width: 48px;
		height: 48px;
	}
}

.insurance-birthday-slash {
	border: 1px solid #000;
	transform: rotate(16deg);
	height: 32px;
	margin-right: spacing(1);

	@include if-size("desktop") {
		height: 64px;
		margin-right: spacing(2);
	}
}

.insurance-birthday-input-container {
	display: flex;
	align-items: center;
	margin: spacing(2) 0 spacing(1);
}

#insurance-discount-question {
	h4 {
		margin-top: spacing(2);
		margin-bottom: spacing(4);
	}

	.button-v2 {
		margin: 0 auto;
	}

	#insurance-discount-question-back {
		text-align: center;
		margin: spacing(1) 0 auto;
		cursor: pointer;
		color: $azure;
	}
}

.insurance-discount-card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: $cloud;
	border-radius: 16px;
	padding: spacing(2) spacing(4);
	margin-bottom: spacing(2);
	user-select: none;
	cursor: pointer;

	p.sm {
		margin-top: spacing(1);
		opacity: 0.5;
	}
}

.insurance-discount-card-icon-container {
	max-width: 52px;
	max-height: 52px;
	min-width: 42px;
	min-height: 42px;
	height: 100%;
	width: fit-content;
	display: grid;
	place-items: center;

	svg {
		height: 100%;
		width: 100%;

		> path {
			fill: $denim;
			transition: fill $insurance_question_transition_time ease-in-out;
		}
	}

	&-active {
		@extend .insurance-discount-card-icon-container;

		svg > path {
			fill: $azure;
		}
	}
}

#insurance-initial-questions {
	position: absolute;
	right: 10000px;
	transition:
		right $insurance_question_transition_time ease-in-out,
		left $insurance_question_transition_time ease-in-out;
	width: 100%;

	&-done {
		@extend #insurance-initial-questions;
		right: 10000px;
		visibility: hidden;
	}

	&-active {
		@extend #insurance-initial-questions;
		right: 0;
	}
}

#insurance-final-question {
	position: absolute;
	right: -10000px;
	transition: right $insurance_question_transition_time ease-in-out;
	width: 100%;
	height: 0px;
	overflow: hidden;

	&-done {
		@extend #insurance-final-question;
		right: 100000px;
	}

	&-active {
		@extend #insurance-final-question;
		overflow: unset;
		height: auto;
		right: 0;
	}
}

#insurance-survey-loading {
	position: absolute;
	right: -10000px;
	width: 100%;
	transition: right $insurance_question_transition_time ease-in-out;
	top: 0;
	overflow: hidden;

	&-active {
		@extend #insurance-survey-loading;
		padding: spacing(4) 0;
		overflow: visible;
		right: 0;
	}

	&-done {
		@extend #insurance-survey-loading;
		padding: spacing(4) 0;
		overflow: visible;
		right: 10000px;
	}
}

#insurance-survey-results {
	position: absolute;
	right: -10000px;
	width: 100%;
	transition: right $insurance_question_transition_time ease-in-out;
	top: 0;
	overflow: hidden;
	padding: 0 5px 0;

	@include if-size("desktop") {
		padding: spacing(0) spacing(2) 0;
	}

	&-active {
		@extend #insurance-survey-results;
		height: auto;
		right: 0;
	}

	&-done {
		@extend #insurance-survey-results;
		right: 10000px;
	}
}

#insurance-survey-results-subtitle {
	margin-top: spacing(1);
}

.insurance-survey-results-hr {
	width: 100%;
	height: 2px;
	border-radius: 2px;
	background-color: $sand;
	margin: spacing(4) 0;
}
.insurance-survey-results-section-title {
	margin-bottom: spacing(2);
}

#insurance-other-quotes {
	margin-top: spacing(2);
}

.insurance-quote-card {
	width: 156px;
	background-color: $white;
	border-radius: 16px;
	width: 100%;
	margin-top: spacing(2);
	box-shadow: $primary-box-shadow;
	margin-bottom: spacing(2);
	position: relative;

	@include if-size("desktop") {
		margin-top: 0;
	}
}

.insurance-quote-card-selected-pill {
	position: absolute;
	right: 16px;
	top: 0;
	transform: translateY(-75%);
	padding: spacing(1) spacing(2);
	border-radius: 500px;
	background-color: $azure;

	> .label {
		color: $white;
	}
}

.insurance-quote-card-top {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	align-items: center;
}

.insurance-quote-card-img {
	max-width: 50%;

	@include if-size("tablet") {
		width: fit-content;
		margin-right: auto;
	}
}

.insurance-quote-card-top-deductible {
	margin-right: spacing(2);
	display: none;

	@include if-size("desktop") {
		display: block;
	}
}

.insurance-quote-card-top-premium {
	display: flex;
	justify-content: flex-end;
	width: 50%;

	@include if-size("tablet") {
		width: auto;
	}
}
.insurance-quote-card-cta-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: spacing(2) auto 0;

	@include if-size("desktop") {
		margin: 0;
		margin-left: spacing(2);
	}

	.button-v2 {
		margin-bottom: spacing(2);
	}

	.button-v2 > span {
		white-space: nowrap;
	}
}

.insurance-quote-card-bottom {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: 2px solid $white;
	padding-top: spacing(2);
	margin-top: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}

	.button-v2 {
		margin-bottom: spacing(1);
	}

	// p.sm {
	// 	color: $azure;
	// 	cursor: pointer;
	// }
}

.insurance-quote-card-details {
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	margin-bottom: spacing(2);

	.label {
		white-space: nowrap;
	}

	> div {
		margin-top: spacing(2);
		margin-right: spacing(2);

		@include if-size("desktop") {
			margin-right: spacing(4);
		}
	}
}

#insurance-survey-results-benefits-container {
	background-color: $cloud;
	padding: spacing(4);
	border-radius: 8px;
}

.insurance-survey-results-benefits-title {
	display: flex;
	align-items: center;
	justify-content: center;

	.insurance-survey-results-benefits-title-image {
		height: 100%;
		padding: 0 0 spacing(1);
	}

	#insurance-survey-results-benefits-title-plus {
		margin: 0 spacing(2) 6px;
	}

	svg {
		margin-bottom: 2px;
		max-height: 40px;
		height: 100%;
		width: 100%;

		path {
			fill: $denim;
		}
	}
}

.insurance-survey-results-benefits {
	display: flex;
	align-items: center;
	margin-top: spacing(2);

	svg {
		margin-right: spacing(1);
		margin-top: 5px;
		min-width: 24px;
	}
}

#insurance-survey-results-faqs-container {
	margin: spacing(2) 0;

	#insurance-survey-results-faqs {
		display: flex;
		flex-wrap: wrap;

		.pricing-faq-card-col {
			width: 100%;
		}

		@include if-size("desktop") {
			.pricing-faq-card-col {
				width: calc(50% - spacing(2));

				&:nth-of-type(1) {
					margin-right: spacing(2);
				}
			}
		}
	}
}

#insurance-survey-results-recommended-quote-current-policy {
	display: flex;
	flex-direction: column;
	margin-top: spacing(4);

	h4 {
		margin-bottom: spacing(2);
	}

	@include if-size("desktop") {
		flex-direction: row;
		justify-content: space-between;
	}
}

#insurance-survey-results-recommended-quote {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 440px;
	margin: 0 auto;

	@include if-size("desktop") {
		margin: 0;
		margin-right: auto;
	}
}

#insurance-survey-results-recommended-quote-header {
	background-color: $denim;
	box-shadow: $primary-box-shadow;
	text-align: center;
	padding: spacing(2) 0;
	border-radius: 16px 16px 0px 0px;

	h3 {
		color: $white;
	}
}

#insurance-survey-results-recommended-quote-body {
	flex: 1;
	border-radius: 0px 0px 16px 16px;
	box-shadow: $primary-box-shadow;
	background-color: $cloud;
	padding: spacing(4);
}

#insurance-survey-results-recommended-quote-body-savings {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	padding-bottom: spacing(3);
	border-bottom: 2px solid $white;

	h2 {
		color: $azure;
	}
}

.insurance-survey-results-recommended-quote-body-savings-annual {
	display: flex;
	align-items: flex-end;

	p {
		color: $azure;
		position: relative;
		bottom: 3px;
	}
}

#insurance-survey-results-recommended-quote-body-details-container {
	padding-top: spacing(3);
}

.insurance-survey-results-recommended-quote-body-detail {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: spacing(3);

	div {
		display: flex;
		align-items: center;
	}

	.appeal-info-question-card-tooltip {
		margin-bottom: 2px;
		margin-left: 8px;
	}

	.insurance-survey-results-recommended-quote-body-detail-reduction {
		color: $azure;
		margin-left: spacing(1);
	}
}

#insurance-survey-results-current-policy {
	width: 100%;
	height: fit-content;
	box-shadow: $primary-box-shadow;
	padding: spacing(4);
	border-radius: 16px;
	margin-top: spacing(2);

	> .quick-link-small {
		margin: 0 auto;
	}

	@include if-size("desktop") {
		margin-top: 0;
		margin-left: spacing(2);
	}
}

.insurance-survey-results-current-policy-detail-section {
	flex: 1;
}

#insurance-survey-results-recommended-quote-body-cta {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.button-v2 {
		margin: spacing(1) 0 spacing(2);
	}
}

#insurance-survey-results-current-policy-details-container-horizontal,
#insurance-survey-results-current-policy-details-container-vertical {
	display: flex;
	flex-direction: column;

	@include if-size("desktop") {
		flex-direction: column;
	}

	.appeal-info-question-card-tooltip {
		width: 100%;
	}
}

#insurance-survey-results-current-policy-details-container-horizontal {
	flex-direction: column;

	@include if-size("desktop-lg") {
		flex-direction: row;
		gap: spacing(4);
	}
}

#property-save-more-insurance-policy-expand {
	display: flex;
	justify-content: center;
	margin: spacing(1);
}

.insurance-survey-results-current-policy-details-title {
	padding-bottom: 4px;
}

.insurance-survey-results-current-policy-detail {
	margin-bottom: spacing(2);
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include if-size("tablet") {
		margin-right: spacing(2);
	}

	p {
		text-align: right;
	}
}

#insurance-survey-savings-box {
	background-color: rgba(140, 179, 220, 0.25);
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	height: fit-content;
	display: none;

	@include if-size("desktop") {
		display: flex;
		margin: spacing(2) spacing(3) 0;
	}

	#insurance-survey-savings-box-image {
		height: 80px;
		width: min(144px, 40%);

		@include if-size("desktop") {
			width: 250px;
		}
	}

	#insurance-survey-savings-box-savings {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: spacing(2) 0;

		.label {
			text-align: right;
			margin-right: spacing(1);
		}

		h3 {
			color: $azure;
		}

		.insurance-survey-savings-box-savings-small-text {
			color: $azure;
			position: relative;
			top: 1px;
		}
	}
}

#insurance-survey-loading-image-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

#insurance-survey-loading-image {
	max-height: 200px;
	max-width: 200px;
	width: 100%;
	height: 100%;
}

#insurance-survey-initializing {
	position: absolute;
	display: flex;
	padding: spacing(8);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	&-done {
		@extend #insurance-survey-initializing;
		right: 10000000px;
	}
}

#insurance-survey-intro {
	h3 {
		margin-bottom: 8px;
	}

	.insurance-survey-intro-paragraph {
		padding-bottom: 8px;
	}

	.button-v2 {
		margin: spacing(4) auto;
	}
}

#insurance-survey-confirmation {
	position: absolute;
	top: 0;
	right: -10000px;
	width: 100%;
	overflow: hidden;
	transition: right $insurance_question_transition_time ease-in-out;
	padding: spacing(1) 0;

	&-active {
		@extend #insurance-survey-confirmation;
		right: 0;
		z-index: 1000000;
	}

	&-done {
		@extend #insurance-survey-confirmation;
		right: 10000px;
		height: 0;
	}
}

#insurance-survey-activated {
	@extend #insurance-survey-confirmation;
	&-active {
		@extend #insurance-survey-confirmation;
		right: 0;
		z-index: 1000000;
	}
}

#insurance-survey-confirmation-card-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: spacing(4);
	gap: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.insurance-survey-confirmation-card {
	flex: 1;
	box-shadow: $primary-box-shadow;
	padding: spacing(2);
	border-radius: 16px;
	background-color: $white;

	&.desktop-only {
		display: none;

		@include if-size("desktop-xl") {
			display: block;
		}
	}
}

.insurance-survey-confirmation-card-title {
	margin-bottom: spacing(1);
}

.insurance-survey-confiramtion-edit-details-input {
	width: 100%;

	> input {
		z-index: 10000000000;
	}
}

.insurance-survey-confirmation-title-body {
	margin-top: spacing(1);
}

.insurance-survey-confirmation-card-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: spacing(1);

	.label {
		margin-top: 4px;
	}

	> p {
		display: block;
		line-height: 1;
		text-align: right;
	}
}

#insurance-survey-confirmation-cta {
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: spacing(4) 0 0;

	@include if-size("desktop") {
		display: flex;
	}
}

#insurance-survey-confirmation-mobile-cta {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: spacing(4) 0 spacing(5);
	text-align: center;

	@include if-size("desktop") {
		display: none;
	}
}

.insurance-disclaimer-footer {
	padding: spacing(3) spacing(4) 0;

	@include if-size("desktop") {
		padding-bottom: spacing(3);
	}

	> p {
		color: rgba($denim, 0.5);
	}

	p {
		padding-bottom: 6px;
	}

	a {
		color: $denim;
		opacity: 0.5;
		text-decoration: underline;
	}
}

.error-text {
	margin: spacing(1) 0;
	color: $terracotta;
}

#insurance-survey-confirmation-save-details-btn {
	margin: spacing(2) auto 0;
}

#insurance-survey-confirmation-save-details-container {
	margin-top: spacing(3);
	display: grid;
	place-items: center;

	.button-v2 {
		margin-bottom: spacing(2);
	}
}

#insurance-survey-confirmation-edit-icon {
	width: 20px;
	height: 20px;
	fill: $azure;
	cursor: pointer;

	&-active {
		@extend #insurance-survey-confirmation-edit-icon;
		fill: $azure;
	}
}

#insurance-survey-confirmation-edit-details-title-row {
	display: flex;
	justify-content: space-between;
}

#insurance-talk-to-advisor {
	transition: right $insurance_question_transition_time ease-in-out;
	position: absolute;
	padding: 0 spacing(2) spacing(2);
	width: 100%;

	&-active {
		@extend #insurance-talk-to-advisor;

		@include if-size("desktop") {
			padding-top: spacing(4);
		}

		padding: spacing(2) 5px 0;
		right: 0;
	}

	&-inactive {
		@extend #insurance-talk-to-advisor;
		right: -10000px;
	}
}

#insurance-talk-to-advisor-active {
	// 	transition: right $insurance_question_transition_time ease-in-out;
	// 	position: absolute;
	// 	right: 0;

	.lg {
		padding-bottom: 6px;
	}
}

#insurance-calendly-embed-container {
	width: 100%;
	margin: spacing(4) 0;
	height: 1150px;

	@include if-size("desktop") {
		margin: spacing(4) auto;
		background-color: $cloud;
	}
}

#insurance-talk-to-advisor-cta-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: spacing(4) 0 spacing(6);

	@include if-size("desktop") {
		margin: spacing(4) 0;
	}
}

#insurance-talk-to-advisor-contact-card {
	width: 100%;
	box-shadow: $primary-box-shadow;
	border-radius: 8px;
	padding: spacing(2) spacing(4);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: spacing(1);

	> div:first-of-type {
		margin-bottom: spacing(2);
	}

	@include if-size("desktop") {
		flex-direction: row;

		> div:first-of-type {
			margin-bottom: 0;
			margin-right: spacing(4);
		}
	}

	a {
		color: $denim;
		&:hover {
			color: $azure;
		}
	}

	.insurance-talk-to-advisor-contact-card-contact {
		display: flex;
	}

	.insurance-talk-tp-advisor-contact-card-icon {
		width: 16px;
		height: 16px;
		margin-right: spacing(1);
		position: relative;
		top: 3px;
		@include if-size("desktop") {
			top: 5px;
		}
	}
}

#insurance-results-benefit-card-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: spacing(2) 0;

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.insurance-survey-results-benefit-card {
	flex: 1;
	background-color: $cloud;
	padding: spacing(2);
	border-radius: 8px;

	&:not(:last-of-type) {
		margin-bottom: spacing(2);

		@include if-size("desktop") {
			margin-bottom: 0;
			margin-right: spacing(2);
		}
	}

	p.lg {
		padding-bottom: spacing(1);
	}
}

.insurance-current-provider-textfield {
	margin-top: spacing(1);
}

.insurance-powered-by-matic {
	display: flex;
	align-items: center;
	justify-content: center;

	p {
		line-height: 1;
		margin-right: spacing(1);
		margin-bottom: spacing(1);
	}

	img {
		width: 160px;
		height: 35px;
	}
}

#insurance-survey-modal-back-arrow {
	position: absolute;
	display: flex;
	align-items: center;
	cursor: pointer;
	top: -32px;
	left: 16px;

	@include if-size("desktop") {
		top: spacing(2);
		left: spacing(2);
	}

	p {
		line-height: 1;
	}

	svg {
		margin-top: 2px;
		margin-right: spacing(1);
		fill: $denim;
	}
}

#insurance-survey-results-powered-by-matic {
	display: flex;
	justify-content: center;
	margin-top: spacing(3);
}

.insurance-survey-activated-container {
	background-color: $denim-lightest;
	padding: spacing(4) spacing(2);
	border-radius: 8px;
	display: flex;
	gap: 16px;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;

	@include if-size("tablet") {
		flex-direction: row;
		text-align: left;
	}
}

.insurance-survey-activated-icon-container {
	svg {
		width: 150px;
		height: 150px;
	}
}

.insurance-survey-activated-container-text-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 4px;
}

#insurance-survey-activated-btn {
	margin: 32px auto;
}

.property-insurance-content.quotes-ready {
	background: $white;
	padding: spacing(2);
	border-radius: 8px;

	#property-insurance-quotes-heading {
		background: $denim-lightest;
		padding: 12px;
		border-radius: 8px;

		@include if-size("desktop-xl") {
			padding: 0;
			background: $white;
		}

		svg {
			height: 40px;
			width: 40px;
		}
	}

	#insurance-new-deals-badge {
		padding: 5px 12px;
		display: flex;
		align-items: center;
		background: $kelly-lightest;
		border-radius: 8px;

		p.sm {
			color: $kelly-dark;
			white-space: nowrap;
		}

		svg {
			margin-left: 8px;
			width: 20px;
			height: 20px;
		}
	}

	#property-insurance-quote-container-outer {
		overflow: auto;
	}

	#property-insurance-quote-container {
		display: flex;
		gap: 16px;
		width: fit-content;
		flex-direction: row;
	}

	.insurance-quote-card {
		border: 1px solid $slate;
		border-radius: 8px;
		overflow: hidden;
		width: 220px;
		min-width: 220px;

		@include if-size("desktop") {
			width: 320px;
			min-width: 320px;
		}

		.insurance-quote-card-heading {
			width: 100%;
			padding: 12px;
			background-color: $sky-light;
			height: 70px;
			display: flex;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			justify-content: space-between;

			.insurance-quote-card-carrier-logo-container {
				flex: 1;
				height: 100%;
				margin-right: 14px;
				display: flex;
				align-items: center;

				@include if-size("desktop") {
					max-width: 35%;
				}
			}

			.insurance-quote-card-heading-carrier-name-container {
				max-width: 50%;
			}

			.insurance-quote-card-carrier-logo {
				width: 100%;
				height: auto;
			}

			.insurance-quote-card-savings,
			.insurance-quote-card-carrier-name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.insurance-quote-card-quote-details {
			padding: 12px;
			display: flex;
			flex-direction: column;
			gap: 6px;
		}

		.insurance-quote-card-cta-section {
			padding: 12px;
			padding-top: 0px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 12px;
			margin-top: spacing(1);

			@include if-size("desktop") {
				flex-direction: row;
				justify-content: space-between;
			}
		}

		.insurance-quote-card-cta-selected {
			display: flex;
			align-items: center;
			gap: 8px;

			svg > path {
				fill: $kelly-dark;
			}
		}
	}

	#selected-quote-modal {
		max-width: 760px;
	}

	#selected-quote-modal-heading {
		display: flex;
		align-items: center;
		position: relative;
		flex-direction: column;
		gap: 12px;

		@include if-size("desktop") {
			flex-direction: row;
			padding: spacing(3);
			background-color: $sky-light;
		}

		.selected-quote-modal-heading-carrier-logo {
			max-width: 120px;
			margin-right: 12px;
		}

		#selected-quote-modal-heading-save-value-prop {
			margin-bottom: 0;
		}

		#selected-quote-modal-heading-close-container {
			position: absolute;
			top: 24px;
			right: 24px;
			cursor: pointer;
			width: 24px;
			height: auto;
		}
	}

	#selected-quote-modal-body {
		padding: 24px 12px;

		@include if-size("desktop") {
			padding: spacing(3);
		}

		#selected-quote-modal-body-details-container {
			display: flex;
			flex-direction: column;
			gap: 16px;

			@include if-size("desktop") {
				padding: 0 spacing(7);
				flex-direction: row;
				justify-content: space-between;
			}

			.selected-quote-modal-body-details-column {
				display: flex;
				flex-direction: column;
				gap: spacing(2);

				.selected-quote-modal-body-details-row {
					display: flex;
					gap: 16px;
					justify-content: space-between;

					p.lg {
						margin-bottom: 0px;
					}
				}

				#selected-quote-modal-body-wind-hail-row {
					margin-top: -12px;
				}
			}
		}
	}
}

#interested-quote-modal {
	max-width: 920px;

	p.lg {
		margin-bottom: 0px;
	}

	@include if-size("desktop") {
		margin: 40px auto;
	}

	#interested-quote-modal-heading {
		position: relative;

		#interested-quote-modal-heading-close-container {
			position: absolute;
			top: 24px;
			right: 24px;
			cursor: pointer;
		}
	}
}

#interested-quote-card-container {
	width: fit-content;
	margin: spacing(3) auto 0;

	> .insurance-quote-card {
		max-width: unset;
	}
}

.covered-logo-small {
	height: auto;
	width: 80px;

	@include if-size("desktop") {
		width: 100px;
	}
}

.property-insurance-content-confirmation-card {
	@include if-size("desktop") {
		max-width: 50%;
	}
}

#current-quote-card {
	display: none;

	@include if-size("desktop") {
		display: block;
	}
}
