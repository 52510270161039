#commercial-landing-dark {
	p,
	h1,
	h2,
	h3 {
		color: white;

		&.denim-black {
			color: $denim-black;
		}
	}
	.content-wrapper {
		margin: 0 auto;
		max-width: 1136px;
		position: relative;
		padding: 0 16px;
		text-align: center;
		//overflow: hidden;
		@include if-size("desktop") {
			padding: 0 spacing(2);
			text-align: left;
		}
	}
	.white-outline {
		border-color: white;
		color: white;
	}
	#header-section {
		background-color: $denim-black;
		@include if-size("desktop") {
			padding: 16px 0 64px;
		}
		.heading-content {
			h1 {
				width: 775px;
				max-width: 100%;
			}
			p {
				margin-top: 20px;
				width: 775px;
				max-width: 100%;
			}
		}
		.header-bottom-wrapper {
			margin-top: 30px;
			display: flex;
			justify-content: space-between;
			position: relative;
			flex-direction: column;
			gap: spacing(3);
			@include if-size("desktop") {
				flex-direction: row;
			}
			.left-container {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 20px;
				justify-content: center;
				align-items: center;
				@include if-size("desktop") {
					align-items: start;
					justify-content: start;
				}
				.btns-container {
					display: flex;
					gap: 16px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include if-size("desktop") {
						flex-direction: row;
					}
				}
				.trusted-by-container {
					display: flex;
					gap: 5px;
					justify-content: start;
					align-items: center;
					.trusted-by-imgs {
					}

					.trusted-by-content {
						display: flex;
						flex-direction: column;
						justify-content: start;
						.star-icon {
							text-align: left;
						}
						.trusted-by-text {
							.trusted-by-title {
							}
						}
					}
				}
			}
			.right-container {
				position: relative;
				width: 100%;
				height: auto;
				margin-top: 20px;
				@include if-size("desktop") {
					width: 50%;
					margin-top: 0;
				}
				.product-page-screenshot-container {
					position: absolute;
					right: 0;
					top: 0;
					width: 100%;
					max-width: 100vw;
					overflow: hidden;
				}
			}
		}
	}
	#feature-rating-section {
		padding-top: 150px;
		margin-bottom: 64px;
		@include if-size("tablet") {
			padding-top: 350px;
		}
		@include if-size("desktop") {
			padding-top: 200px;
		}
		.featred-in-container {
			padding-top: 10px;
			background-color: white;
			overflow: hidden;
			h3 {
				color: $denim-medium;
				font-weight: 700;
				font-size: 18px;
				line-height: 20px;
				text-align: center;
				letter-spacing: 3px;
				text-transform: uppercase;
			}
			.featured-in-imgs {
				margin-top: 10px;
			}
		}

		.rating-container {
			margin-top: 48px;
			background-color: $denim-black;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-radius: 8px;
			flex-direction: column;
			gap: 20px;
			padding: 20px;
			margin: 10px;
			@include if-size("desktop") {
				flex-direction: row;
				padding: 40px;
				margin: 24px auto;
			}
			.btns-container {
				display: flex;
				gap: 16px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				@include if-size("desktop") {
					display: none;
				}
			}
			.title-container {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 20px;
				@include if-size("desktop") {
					justify-content: start;
					align-items: start;
					gap: 10px;
				}
				h3 {
					margin-bottom: 16px;
				}
			}

			.rating-boxes {
				display: flex;
				flex-direction: column;
				gap: 16px;
				@include if-size("desktop") {
					gap: 32px;
				}
				.ratings-row {
					display: flex;
					gap: 16px;
					@include if-size("desktop") {
						gap: 32px;
					}
					.rating-box {
						background-color: #00233580;
						height: 112px;
						border-radius: 4px;
						text-align: center;
						display: flex;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						width: 50%;
						padding: 10px;
						gap: 8px;
						@include if-size("desktop") {
							justify-content: start;
							width: 200px;
							padding: 0;
						}
						&.gap-0 {
							gap: 0;
						}
						.value {
							p {
								position: relative;
								.superscript {
									position: absolute;
									font-size: 24px;
									top: -10px;
									right: -15px;
									&.front {
										left: -15px;
										right: unset;
									}
								}
							}
						}

						.rating-text {
							p {
								color: $slate;
							}
						}
					}
				}
			}
		}
	}
	#slider-section {
		position: relative;
		overflow: hidden;
		background-color: $denim-black;
		display: flex;
		justify-content: center;
		align-items: center;
		.background-img-wrapper {
			position: absolute;
			overflow: hidden;
			height: fit-content;
			@include if-size("desktop") {
				height: 100%;
				width: 100%;
			}
		}
		.slider-container {
			display: flex;
			justify-content: space-between;
			justify-content: center;
			align-items: center;
			// padding: 10px 0;
			flex-wrap: wrap;
			position: relative;
			max-width: 1600px;
			width: 100%;
			@include if-size("desktop") {
				height: 640px;
				flex-wrap: unset;
				// padding: 96px 0;
			}
			.swiper-btn-container {
				position: absolute;
				display: flex;
				z-index: 9;
				width: 150px;
				justify-content: center;
				gap: 16px;
				bottom: 8px;
				@include if-size("desktop") {
					width: 100%;
					justify-content: space-between;
					bottom: unset;
				}
			}
			#swiper-button-prev {
				order: 2;
				@include if-size("desktop") {
					order: 1;
				}
				svg {
					cursor: pointer;
					@include if-size("desktop") {
						&:hover {
							rect {
								fill: $denim-medium;
							}
						}
					}
				}
			}
			.swiper-content-wrapper {
				// padding: 24px;
				// padding-top: 96px;
				order: 1;
				overflow: hidden;
				@include if-size("tablet") {
					order: 2;
					// padding-top: 200px;
				}
				@include if-size("desktop") {
					order: 2;
					height: 100%;
					// padding-top: 24px;
				}
				.mySwiper {
					overflow: hidden;
					position: relative;
					.swiper-slide {
						position: relative;
						overflow: hidden;
					}
					.swiper-card-wrapper {
						display: flex;
						justify-content: start;
						align-items: start;
						overflow: hidden;
						padding: 112px 8px 60px;
						@include if-size("desktop") {
							padding: 96px;
						}
						.swiper-card {
							display: flex;
							flex-direction: column;
							justify-content: start;
							padding: 24px;
							gap: 24px;
							background: #000a0f80;
							border-radius: 12px;
							max-width: 100%;
							border: 1px solid #566c77;
							backdrop-filter: blur(40px);
							overflow: hidden;
							@include if-size("desktop") {
								width: 464px;
							}
							.card-header {
								display: flex;
								flex-direction: column;
								gap: 4px;
								justify-content: start;
								align-items: start;
								.card-title {
									display: flex;
									gap: 10px;
									justify-content: start;
									align-items: center;
									padding: 8px 16px;
									background: #000a0f66;
									width: fit-content;
									border-radius: 8px;
									p {
										text-transform: uppercase;
										color: $slate;
										letter-spacing: 2px;
										font-size: 12px;
										font-weight: 600;
										@include if-size("desktop") {
											size: 14px;
										}
									}
								}
							}
							.card-body {
								display: flex;
								gap: 8px;
								.quote-left {
									display: flex;
									justify-content: start;
								}

								.body-text {
									width: 100%;
									text-align: left;
									p {
										color: $slate;
										.white {
											color: white;
										}
									}
								}

								.quote-right {
									display: flex;
									justify-content: end;
									align-items: flex-end;
								}
							}

							.card-signature {
								display: flex;
								flex-direction: column;
								text-transform: uppercase;
								margin-left: 35px;
								justify-content: start;
								align-items: start;
								p {
									color: $slate;
								}
							}

							.case-study-link {
								display: flex;
								justify-content: flex-end;
								p {
									color: $azure !important;
								}
							}
						}
					}
				}
			}

			#swiper-button-next {
				order: 3;
				svg {
					cursor: pointer;
					@include if-size("desktop") {
						&:hover {
							rect {
								fill: $denim-medium;
							}
						}
					}
				}
			}
		}

		#swiper-pagination {
			position: absolute;
			bottom: 0;
			z-index: 9;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 24px;
			.swiper-pagination-bullet {
				border: 1px solid $slate;
				background: none;
				width: 14px;
				height: 14px;
				border-radius: 50%;
			}
			.swiper-pagination-bullet-active {
				position: relative;
				width: 70px;
				height: 14px;
				background-color: $slate;
				border: 1px solid $slate;
				border-radius: 10px;
			}
		}
	}
	#assets-section {
		background-color: $denim-black;
		padding-top: 20px;
		@include if-size("desktop") {
			padding-top: 96px;
		}

		.content-wrapper {
			h3 {
				margin-bottom: 48px;
				text-align: center;
			}
			.assets-cards {
				display: flex;
				gap: 16px;
				flex-direction: column;
				@include if-size("desktop") {
					flex-direction: row;
				}
				.asset-card {
					flex: 1 1;
					border-radius: 8px;
					width: 100%;
					position: relative;
					overflow: hidden;
					.asset-img {
						width: 100%;
						height: 150px;
						position: relative;
						@include if-size("desktop") {
							height: 200px;
						}
					}
					.asset-text {
						padding: 24px 16px;
						background-color: #7f939d1a;
						p {
							text-align: center;
						}
					}
				}
			}
		}
	}

	#advantages-section {
		background-color: $denim-black;
		padding: 96px 0;
		.advantage-header {
			&.content-wrapper {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				text-align: center;
				color: white;
				padding-bottom: 32px;
				@include if-size("desktop") {
					padding-bottom: 96px;
				}
				h3 {
					text-align: center;
					margin-bottom: 16px;
				}
				.subtitle {
					text-align: center;
				}
				.btns-container {
					margin-top: 20px;
					display: flex;
					gap: 16px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include if-size("desktop") {
						flex-direction: row;
					}
				}
			}
		}

		.advantage-content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			row-gap: 32px;
			align-items: center;
			@include if-size("desktop") {
				row-gap: 96px;
				justify-content: space-between;
			}
			.advantage-card {
				width: 464px;
				max-width: 100%;
				flex-shrink: 0;
				.advantage-img {
					border-radius: 16px;
					overflow: hidden;
					//text-align: right;
					position: relative;
					.overlay-container {
						position: absolute;
						top: 50%;
						left: 0;
						transform: translate(0, -50%);
					}
				}
				.advantage-text {
					margin-top: 10px;
					h4 {
						color: white;
						text-align: center;
					}
					p {
						color: $slate;
						text-align: center;
					}
				}
			}
		}
	}

	#appeal-process {
		padding: 48px 0;
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: center;
		align-items: center;
		text-align: center;
		h3 {
			color: $denim-black;
		}
		.appeal-steps {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			@include if-size("desktop") {
				flex-direction: row;
			}
			.appeal-step {
				width: 368px;
				padding: 24px;
				.step-img {
					text-align: center;
					margin: 0 auto;
					margin-bottom: 10px;
				}
				.step-title {
					font-weight: 800;
					color: $denim_dark;
					text-align: center;
					@include if-size("desktop") {
						text-align: left;
					}
				}

				.step-text {
					color: $denim-medium;
					text-align: center;
					@include if-size("desktop") {
						text-align: left;
					}
				}
			}
		}

		.noi-box {
			background-color: $denim-black;
			padding: 32px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			color: white;
			border-radius: 16px;
			flex-direction: column-reverse;
			margin: 10px;
			gap: 24px;
			@include if-size("desktop") {
				flex-direction: row;
				margin: 0 auto;
				width: 100%;
			}
			.box-content {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 20px;
				.box-text {
					width: 100%;
					@include if-size("desktop") {
						width: 426px;
					}
					h4 {
						color: white;
					}
					p {
						margin-top: 8px;
						color: white;
					}
				}
				.box-btns {
					display: flex;
					gap: 16px;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					@include if-size("desktop") {
						flex-direction: row;
					}
				}
			}

			.box-img {
				position: relative;
				.overlay-container {
					position: absolute;
				}
			}
		}
	}

	#tax-consultants {
		padding: 24px 0;
		margin: 0 auto;
		max-width: 1600px;
		width: 100%;
		@include if-size("desktop") {
			padding-bottom: 64px;
		}
		h3 {
			color: $denim_dark;
			text-align: center;
		}
		.tax-consultants-slider {
			overflow: hidden;
			gap: 24px;
			display: flex;
			padding: 24px 0;
			@include if-size("desktop") {
				padding: 64px 0;
			}
			justify-content: center;
			.consultant-swiper {
				display: flex;
				gap: 24px;
				justify-content: center;
				.swiper-slide-consultant {
					width: fit-content;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.tax-consultant-card {
					width: 300px;
					overflow: hidden;
					border-radius: 8px;
					box-shadow: 0px 4px 24px 0px #7f939d2e;
					position: relative;
					flex: 0 0 300px;
					.experience {
						position: absolute;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						letter-spacing: -0.02em;
						text-align: left;
						top: 12px;
						left: 12px;
						background-color: $sky-light;
						padding: 2px 6px;
						color: $denim_dark;
						border-radius: 4px;
						overflow: hidden;
					}
					.consultant-info {
						display: flex;
						flex-direction: column;
						gap: 16px;
						padding: 16px;
						background-color: white;
						.consultant-name {
							color: $denim_dark;
						}
						.consultant-details {
							display: flex;
							flex-direction: column;
							gap: 8px;
							justify-content: flex-start;
							align-items: flex-start;
							.consultant-details-row {
								display: flex;
								justify-content: flex-start;
								align-items: flex-start;
								gap: 8px;
								svg {
									width: 16px;
									height: 16px;
									flex: 0 0 16px;
									margin-top: 4px;
								}
								.consultant-expertise {
									color: $denim_dark;
								}
								.consultant-aum {
									color: $denim_dark;
								}
								.consultant-appeal-won {
									color: $denim_dark;
								}
							}
						}
					}
				}
			}
		}
		.swiper-btn-container-consultant {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 24px;
			svg {
				cursor: pointer;
				@include if-size("desktop") {
					&:hover {
						rect {
							fill: $denim-medium;
						}
					}
				}
			}
		}
	}

	#case-study-section {
		background-color: $denim-black;
		.content-wrapper {
			.appeals-page-casestudies-header p {
				color: $slate !important;
			}
			.appeals-page-casestudies-btn-hide-on-mobile .button-v2 {
				border-color: white;
				color: white;
			}
			.resource-card {
				background-color: #7f939d1a;
				.resource-info-type {
					color: $slate;
				}
				.resource-card-title {
					color: white;
				}
				.resource-card-description {
					color: $slate;
				}
				.resource-info-date {
					color: $denim_lightest;
				}
			}
			.swiper-pagination-bullet-active {
				background: white !important;
			}
			.swiper-pagination-bullet {
				background: transparent;
				border: 1px solid white;
			}
		}
	}

	#contact-section {
		background-color: $denim_lightest;
		padding: 24px 0;
		.content-wrapper {
			h3 {
				color: $denim-black;
				text-align: center;
			}

			p {
				color: $denim-black;
				text-align: center;
			}

			#commercial-contact-form {
				@include if-size("desktop") {
					background-color: white;
				}
				box-shadow: none;
			}
		}
	}

	#landing-hero-address-input {
		padding: 0;
		width: 100%;

		#landing-hero-address-search-with-results {
			#landing-hero-address-search {
				.search-input-container {
					input.search {
						padding-right: spacing(2);
					}

					svg.search-icon-left path {
						fill: $denim;
					}
				}
			}
		}

		#landing-title-autocomplete-results-container {
			.landing-title-autocomplete-item {
				label,
				p {
					text-align: left;
					color: $denim;
				}
			}
		}
	}
}
