#property-layout {
	display: flex;
	flex-direction: column;
	min-height: calc(100svh - 50px);

	@include if-size("desktop") {
		flex-direction: row;
		height: calc(100vh - 44px);
		position: relative;
		top: unset;
	}

	&-content {
		flex: 1;
		background-color: $white;
		width: 100%;
		padding: spacing(3) spacing(2) spacing(2);
		height: 100%;
		position: relative;
		overflow: hidden;

		@include if-size("desktop") {
			overflow-y: auto;
			padding: spacing(2) spacing(2) spacing(32);
		}

		@include if-size("desktop-xl") {
			background-color: $white;
			padding: spacing(2) spacing(4);
		}

		@include styled-scrollbar();

		&.denim-light-background {
			background-color: $denim_lightest;
		}
	}

	//Needed to override Expenses tab grid for mobile only
	#property-layout-content > .grid {
		max-width: 100%;
		@include if-size("desktop") {
			max-width: inherit;
		}
	}
}
