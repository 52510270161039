.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}

.ml-auto {
	margin-left: auto;
}

.mr-auto {
	margin-right: auto;
}

.mb-auto {
	margin-bottom: auto;
}

@for $i from 1 through 8 {
	.mb-#{$i} {
		margin-bottom: spacing($i);
	}

	.mb-#{$i - 1}_5 {
		margin-bottom: spacing($i - 0.5);
	}

	@each $breakpoint, $query in $new-breakpoints {
		@include if-size($breakpoint) {
			.#{$breakpoint}-mb-#{$i} {
				margin-bottom: spacing($i);
			}

			.#{$breakpoint}-mb-#{$i - 1}_5 {
				margin-bottom: spacing($i - 0.5);
			}
		}
	}
}

@for $i from 1 through 8 {
	.mt-#{$i} {
		margin-top: spacing($i);
	}

	.mt-#{$i - 1}_5 {
		margin-top: spacing($i - 0.5);
	}

	@each $breakpoint, $query in $new-breakpoints {
		@include if-size($breakpoint) {
			.#{$breakpoint}-mt-#{$i} {
				margin-top: spacing($i);
			}

			.#{$breakpoint}-mt-#{$i - 1}_5 {
				margin-top: spacing($i - 0.5);
			}
		}
	}
}

@for $i from 1 through 8 {
	.mr-#{$i} {
		margin-right: spacing($i);
	}

	.mr-#{$i - 1}_5 {
		margin-right: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.ml-#{$i} {
		margin-left: spacing($i);
	}

	.ml-#{$i - 1}_5 {
		margin-left: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.mx-#{$i} {
		margin-left: spacing($i);
		margin-right: spacing($i);
	}

	.mx-#{$i - 1}_5 {
		margin-left: spacing($i - 0.5);
		margin-right: spacing($i - 0.5);
	}
}

@for $i from 1 through 8 {
	.my-#{$i} {
		margin-top: spacing($i);
		margin-bottom: spacing($i);
	}

	.my-#{$i - 1}_5 {
		margin-top: spacing($i - 0.5);
		margin-bottom: spacing($i - 0.5);
	}
}
