.scroll-locked {
	display: none;

	@include if-size("desktop") {
		display: grid;
		max-height: calc(100vh - spacing(16));
	}
}

#property-image-mobile-container {
	height: 216px;
	margin-top: calc(spacing(4) * -1);
	z-index: -20;
	position: relative;

	@include if-size("desktop") {
		display: none;
	}

	#property-image-mobile {
		z-index: -1;
		width: 100%;
		height: 100%;
		background-size: 100%;
		background-position: center;
	}

	#property-image-mobile-bar {
		position: absolute;
		bottom: -2px;
		left: 0;
		height: 18px;
		width: 100%;
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
		background-color: $white;
	}
}

#property-page {
	z-index: 100;

	display: grid;
	grid-template-columns: 1fr;
	grid-gap: spacing(2);

	@include if-size("desktop") {
		grid-template-columns: 1fr;
		grid-gap: spacing(3);
	}

	@include if-size("monitor") {
		grid-template-columns: 1fr 1fr 1fr;
	}

	max-width: 1400px;
	margin: 0 auto;

	#property-page-banner-section {
		grid-column: 1/-1;
		display: flex;
		width: 100%;
		gap: 16px;
		flex-direction: column;

		@include if-size("desktop") {
			flex-direction: row;
			gap: 32px;
		}
	}

	.property-card-maximize-tax-savings-card {
		grid-column: 1 / -1;
		order: 1;
		width: 100%;

		&.completed {
			order: 3;
			width: 100%;
		}

		&.multiple-banners-mobile {
			order: 3;
			width: 100%;
			grid-column: 1/-1;
			display: flex;
			@include if-size("desktop") {
				display: none;
			}
		}
		&.multiple-banners-desktop {
			flex-wrap: wrap;
			display: none;

			@include if-size("desktop") {
				display: flex;
				.desktop-hidden {
					display: flex;
				}

				.mobile-hidden {
					display: none;
				}
			}
			.property-card-maximize-tax-savings-title-and-progress {
				flex: 1 1;
			}
		}
	}

	.two-columns {
		@include if-size("desktop") {
			grid-column: span 2;
		}
	}

	#ways-to-save {
		order: 2;

		grid-column: 1 / -1;
		@include if-size("desktop") {
			grid-column: 1 / -1;
		}
	}

	#property-referral-card {
		height: 100%;
	}

	.property-referral-card-title-section {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
	}

	.chart-card.property-value-chart {
		@include if-size("desktop") {
			max-height: 390px;
		}
	}

	.mortgage-card {
		min-width: auto;
	}

	#mobile-property-details-title {
		grid-column: 1 / -1;
		margin-bottom: spacing(1);
		display: flex;
		align-items: center;

		svg {
			fill: $azure;
		}
		h3 {
			margin-left: 1ch;
		}

		@include if-size("desktop") {
			display: none;
		}
	}

	#desktop-property-card-column-1 {
		order: 2;
		@include if-size("desktop") {
			gap: spacing(2);
			display: flex;
			flex-direction: column;
			gap: spacing(2);
		}

		@include if-size("desktop-xl") {
			grid-column: 1 / 2;
		}
	}

	#desktop-property-card-column-2 {
		order: 1;
		@include if-size("desktop") {
			gap: spacing(2);
			display: flex;
			flex-direction: column;
			gap: spacing(2);
		}

		@include if-size("desktop-xl") {
			grid-column: 2 / 3;
		}
	}

	#property-card {
		grid-column: 1 / -1;
		display: flex;

		@include if-size("desktop") {
			border-radius: 16px;
			margin-bottom: spacing(2);
			background-color: $cloud;
		}

		#property-card-image {
			display: none;
			width: 33%;
			height: 100%;
			background-color: grey;
			background-size: cover;
			background-position: center;
			border-top-left-radius: 16px;
			border-bottom-left-radius: 16px;

			@include if-size("desktop") {
				display: block;
			}
		}

		#property-card-details {
			width: 100%;
			padding-bottom: spacing(4);
			position: relative;

			@include if-size("desktop") {
				padding: spacing(2);
			}

			#property-card-settings-three-dots-outer {
				position: relative;

				.property-settings-property-card-popover {
					top: -8px;
					right: -16px;

					@include if-size("desktop") {
						top: 6px;
						right: 6px;
					}
				}
			}

			#property-card-settings-three-dots-inner {
				position: absolute;
				z-index: 9;
				top: -8px;
				right: -16px;

				@include if-size("desktop") {
					top: 8px;
					right: 8px;
				}

				svg {
					cursor: pointer;
					padding: spacing(1);
					width: 22px;
					height: 40px;
				}
			}

			#property-card-details-title {
				display: flex;
				align-items: center;
				margin-bottom: 4px;

				svg {
					fill: $azure;
					min-width: 28px;
					min-height: 32px;
				}

				h3 {
					margin-left: 1ch;
				}
			}

			#property-card-details-parcel {
				margin-bottom: spacing(2);
			}

			#property-card-details-section {
				position: relative;
			}

			#property-card-processing-pill {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateX(-50%) translateY(-50%);
				z-index: 1000;
				background-color: $denim;
				padding: spacing(1) spacing(2);
				border-radius: 500px;
				display: flex;
				align-items: center;
				width: fit-content;

				p {
					color: $white;
					margin-bottom: 2px;
					margin-left: spacing(1);
					white-space: nowrap;
				}

				svg {
					height: 13px;
					width: 16px;
				}
			}
		}

		.property-details-data-row {
			display: flex;
			flex-wrap: wrap;

			#property-lot-sq-ft-data {
				@include if-size("tablet") {
					display: none;
				}
			}

			.property-details-data-point {
				margin-top: spacing(2);
				margin-right: spacing(4);
			}
		}

		.property-details-view-details-row {
			padding-top: spacing(1);
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	.mobile-card-color-underlay {
		position: absolute;
		top: 0;
		width: 200%;
		left: -50%;
		background-color: $cloud;
		height: 100%;
		z-index: -1;
	}

	#property-value-card {
		position: relative;
		grid-column: 1 / -1;
		padding: spacing(4) 0;
		background-color: $cloud;
		border-radius: 16px;
		padding: spacing(3) spacing(2);

		.label {
			margin-top: spacing(1);
			margin-bottom: 4px;
		}

		.current-assessed-value {
			display: flex;
			align-items: center;
			p,
			h3 {
				color: $azure;
			}
			p:not(.lg) {
				margin-left: 1ch;
			}
		}

		.property-value-description {
			margin-top: spacing(1);
		}
	}

	#property-appeal-status-card {
		background-color: $cloud;
		border-radius: 16px;
		margin-bottom: 0;

		@include if-size("desktop") {
			border-radius: 16px;
			height: min-content;
		}
	}

	.life-time-savings-wrapper {
		order: 8;
		grid-column: 1 / -1;
	}
}

.property-card-maximize-tax-savings-card {
	position: relative;
	background-color: $blonde-lightest;
	border: 1px solid $blonde;
	padding: 16px;
	border-radius: 16px;
	text-align: center;
	cursor: pointer;

	@include if-size("desktop-xl") {
		grid-column: span 2;
		padding: 16px 32px;
	}

	display: flex;
	flex-wrap: wrap;
	column-gap: spacing(2);
	row-gap: spacing(1);
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	@include if-size("desktop") {
		align-items: center;
		flex-wrap: nowrap;
	}

	.property-card-maximize-tax-savings-icon {
		width: 48px;
		height: 48px;
		@include if-size("desktop") {
			flex: 0 0 64px;
			width: 64px;
			height: 64px;
		}
	}
	.property-card-maximize-tax-savings-title-and-progress {
		flex: 1;

		display: flex;
		flex-direction: column;

		@include if-size("desktop") {
			flex: 1 1 250%;
			flex-direction: column-reverse;
		}

		.property-card-maximize-tax-savings-title {
			text-align: left;
		}

		.property-card-maximize-tax-savings-progress-bar-container {
			width: 100%;

			.progress-bar-container {
				margin: 4px auto;
				box-shadow: unset;
				border: solid 1px $sunflower;
				background-color: transparent;

				.progress-bar-bar {
					background-color: $sunflower;
				}
			}
		}
	}

	.property-card-maximize-tax-savings-title-and-badge {
		display: flex;
		align-items: center;
		gap: spacing(2);
	}

	.property-card-maximize-tax-savings-bottom-row {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: spacing(2);
	}

	.property-card-maximize-tax-savings-deadline-badge {
		background-color: $sunflower-lightest;
		color: $gold;
		font-weight: bold;
		letter-spacing: -0.02em;
		white-space: nowrap;
		height: min-content;

		display: flex;
		gap: 4px;

		font-size: 10px;
		line-height: 12px;
		border: 1px solid $sunflower;
		border-radius: 6px;
		padding: 4px;
		@include if-size("desktop") {
			font-size: 14px;
			line-height: 16px;
			border: 1.5px solid $sunflower;
			border-radius: 8px;
			padding: 4px 8px;
		}

		svg {
			margin-top: 1px;
			vertical-align: middle;
			width: 12px;
			height: 12px;
			@include if-size("desktop") {
				width: 16px;
				height: 16px;
			}
		}

		&-desktop {
			@extend .property-card-maximize-tax-savings-deadline-badge;
			display: none;
			@include if-size("desktop") {
				display: flex;
			}
		}

		&-mobile {
			@extend .property-card-maximize-tax-savings-deadline-badge;
			display: flex;
			@include if-size("desktop") {
				display: none;
			}
		}
	}

	.property-card-maximize-tax-savings-sub-title {
		flex: 1 1 100%;
		text-align: left;

		color: $denim-medium;
	}

	.property-card-maximize-tax-savings-cta-btn {
		justify-content: flex-end;
	}

	.property-card-maximize-tax-savings-cta-btn {
		&.desktop-only {
			display: none;
			@include if-size("desktop") {
				display: flex;
			}
		}

		&.mobile-only {
			display: flex;
			@include if-size("desktop") {
				display: none;
			}
		}
	}

	&.completed {
		border: solid 1px $slate;
		background-color: $white;

		.property-card-maximize-tax-savings-deadline-badge {
			&-desktop,
			&-mobile {
				background-color: $kelly;
				color: $white;
				border: 1.5px solid $kelly;

				svg path {
					fill: $white;
				}
			}
		}

		.property-card-maximize-tax-savings-title-and-progress {
			justify-content: center;
		}
	}
}

#property-save-more-card {
	padding: spacing(2);
	grid-column: 1 / -1;
	background-color: $cloud;
	border-radius: 16px;

	.property-save-more-title-row {
		display: flex;
		align-items: center;
		margin-bottom: spacing(1);

		p.lg,
		h4 {
			flex: 1;
			margin-left: 1ch;
		}
	}

	.property-save-more-savings-row {
		text-align: center;
		padding-top: spacing(2);

		.property-save-more-savings-box {
			display: inline-block;
			margin: auto;
			border: solid 2px $mint;
			border-radius: 8px;
			padding: spacing(1);
		}
	}

	.property-save-more-cta-row {
		// margin: spacing(2) 0;
		display: flex;
		justify-content: flex-start;
	}

	h5 {
		color: $azure;
		margin-bottom: spacing(2);
	}

	.button-v2,
	.quick-link {
		margin-top: spacing(2);
	}
}

#property-save-more-insurance-policy-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: spacing(2);
	margin-bottom: spacing(2);
	border-bottom: 2px solid $white;

	> div {
		display: flex;
		align-items: center;

		p {
			margin-right: 4px;
			margin-bottom: 2px;
		}
	}
}

.property-save-more-insurance-policy-detail {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: spacing(2);
}

.property-save-more-insurance-policy-detail-title {
	display: flex;
	align-items: center;

	> p {
		margin-right: spacing(1);
	}

	> .appeal-info-question-card-tooltip {
		margin-bottom: 3px;
	}
}

#property-referral-card {
	margin-bottom: 0;
	padding: spacing(2);
	border-radius: 16px;
	grid-column: span 1;
	border: solid 1px $slate;
	position: relative;
	background-color: $white;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: space-between;

	@include if-size("desktop") {
		padding: spacing(3);
	}

	.button-v2 {
		svg {
			height: 16px;
			width: 16px;
			position: relative;
			top: 3px;
		}
	}

	.property-referral-card-img-container {
		width: 100%;
		position: relative;

		svg {
			width: 100%;
		}

		.property-referral-card-img-text {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			text-align: center;
			vertical-align: middle;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.label,
			h1 {
				color: $royal;
				font-weight: 700;
			}

			h1 {
				font-weight: 800;
				font-family: $ff-secondary;
				font-size: 64px;
				font-style: normal;
				font-weight: 800;
				line-height: 74px; /* 115.625% */
				letter-spacing: -1.92px;
				margin-top: -12px;
				margin-bottom: 4px;

				@include if-size("monitor") {
					margin-bottom: -6px;
					font-size: 50px;
					margin-top: -14px;
				}
			}
		}
	}

	.property-referral-card-available-credit {
		color: $kelly-dark;
		padding-top: 4px;
		text-align: center;
		font-weight: 700;
	}

	.lg {
		margin-left: 1ch;
	}
}

#property-maximize-savings-card {
	position: relative;
	background-color: $cloud;
	padding: spacing(3) spacing(2);
	grid-column: 1 / -1;
	border-radius: 16px;

	.progress-bar {
		margin: spacing(2) 0;

		.progress-bar-container {
			background-color: transparent;
		}
	}

	.quick-link {
		margin-top: spacing(2);
	}
}

.property-card-maximize-tax-savings-cta-container {
	margin-top: spacing(2);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	row-gap: spacing(2);
}

.property-card-maximize-tax-savings-cta-dismiss {
	margin-left: spacing(2);
	margin-bottom: 4px;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.property-maximize-savings-dismissed-box {
	padding: spacing(1);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 4px;
	background-color: $white;
	margin-top: spacing(2);
}

.property-maximize-savings-card-title-container {
	display: flex;
	align-items: center;
}

.property-maximize-savings-card-dismissed-pill {
	background-color: rgba($denim, 0.5);
	border-radius: 1000px;
	padding: 5px 8px;
	margin-left: spacing(2);
	margin-top: spacing(1);

	.label {
		color: $white;
	}
}

#add-property-card {
	background-color: $azure;
	padding: spacing(2);
	margin-top: spacing(2);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 16px;
	cursor: pointer;
	display: none;

	* {
		color: $white;
	}

	.title-row {
		display: flex;
		align-items: center;

		svg {
			display: inline;
			width: 18px;
			height: 18px;
			fill: $white;
			margin-right: 1ch;
			margin-top: 4px;
		}
	}
}

#property-details-box-row {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	.property-details-box {
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: $sand;
		padding: spacing(1);
		border-radius: 4px;
		text-align: center;

		p {
			margin-bottom: 4px;
		}

		@include if-size("tablet") {
			margin-right: spacing(2);
		}
	}

	#property-lot-sq-ft {
		display: none;

		@include if-size("tablet") {
			display: flex;
		}
	}
}

#property-details-owner {
	display: flex;
	margin: spacing(3) 0;
	flex-wrap: wrap;
	row-gap: spacing(2);

	.property-detail {
		width: fit-content;
		margin-right: spacing(4);

		p {
			white-space: nowrap;
		}
	}
}

#property-expanded-details-title {
	display: flex;
	flex-direction: column;
	margin-top: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
		margin-top: none;
	}

	#property-expanded-details-title-image {
		border-radius: 8px;
		overflow: hidden;
		margin-right: spacing(2);
		background-size: cover;
		background-position: center;
		height: 150px;
		width: 100%;

		@include if-size("desktop") {
			display: inline-block;
			flex: 2;
			height: auto;
			width: auto;
		}
	}

	#property-expanded-details-details {
		flex: 5;
		padding: spacing(2) 0;

		#property-expanded-details-details-address {
			display: flex;

			svg {
				margin-top: 3px;
				fill: $azure;
			}

			h4 {
				margin-bottom: spacing(3);
				margin-left: spacing(1);
			}
		}
	}
}

#property-expanded-details-column-container {
	display: flex;
	flex-direction: column;
	margin-top: spacing(3);

	@include if-size("desktop") {
		flex-direction: row;
	}

	#property-expanded-details-column {
		flex: 1;

		@include if-size("desktop") {
			&:nth-of-type(even) {
				padding-left: spacing(2);
				border-left: 2px solid $sand;
			}
		}

		.interior-details-title {
			margin-bottom: spacing(2);
		}

		.property-detail-mobile {
			@include if-size("tablet") {
				display: none;
			}
		}

		div.divider {
			margin: spacing(2) auto;
			border-top: 2px solid $sand;
		}

		.ul-row {
			margin: spacing(2) 0;

			ul {
				list-style-position: inside;
				padding-left: spacing(2);
			}
		}

		> div {
			margin-top: spacing(2);
		}

		> .row {
			display: flex;
			flex-wrap: wrap;
			margin-top: 0;

			> div {
				margin-top: spacing(2);
			}

			> div:not(:last-of-type) {
				margin-right: spacing(4);
			}
		}
	}
}

#property-edit-details-title {
	display: flex;
	flex-direction: column;
	margin-bottom: 0;

	@include if-size("tablet") {
		flex-direction: row;
		margin-bottom: spacing(4);
	}

	#property-edit-details-title-image {
		flex: 1;
		border-radius: 8px;
		overflow: hidden;
		margin-right: spacing(2);
		background-size: cover;
		background-position: center;
		min-height: 130px;

		@include if-size("desktop") {
			display: inline-block;
		}
	}

	#property-edit-details-details {
		flex: 1;
		padding: spacing(2) 0;

		h4 {
			margin-bottom: spacing(3);
		}
	}
}

#property-unverified-title {
	display: flex;
	align-items: center;
	margin-bottom: spacing(4);
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	svg {
		margin-right: 2ch;
		max-height: 32px;
		max-width: 32px;
		height: 100%;
		width: 100%;

		path {
			fill: $azure;
		}
	}
}

#property-address-mobile {
	@include if-size("tablet") {
		display: none;
	}
}

#property-address-dt {
	display: none;

	@include if-size("tablet") {
		display: block;
	}
}

#property-unverified-card {
	background-color: $cloud;
	padding: spacing(4);
	border-radius: 16px;
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	#property-unverified-card-title {
		display: flex;
		align-items: center;
		margin-bottom: spacing(2);

		svg.mr {
			margin-right: 2ch;
		}

		svg.ml {
			margin-left: 2ch;
		}

		svg {
			height: 32px;
			width: 32px;
		}
	}

	#property-unverified-card-contact {
		margin-top: spacing(2);
	}
}

.property-survey-evidence-modal-container {
	position: fixed;
	top: calc($mobile-header-height + $mobile-properties-header-height);
	left: 0;
	width: 100%;
	height: calc(
		100% - calc($mobile-header-height + $mobile-properties-header-height)
	);
	background-color: rgba($denim, 0.3);
	z-index: 1000000;

	&-inner {
		position: relative;
		height: 100%;
		width: 100%;
		z-index: 1000000;
		padding: spacing(4) 0;
		overflow-y: auto;
	}

	@include if-size("desktop") {
		top: 0;
		height: 100%;

		&-inner {
			padding: spacing(4) 0;
		}
	}
}

.property-survey-evidence-modal {
	padding: spacing(2) spacing(2) spacing(3);
	background-color: $white;
	grid-column: 1 / -1;
	border-radius: 16px;

	@include if-size("desktop") {
		grid-column: 3 / -3;
		margin: 0 auto;
		width: 80%;
	}
}

.property-survey-evidence-modal-body-text-container {
	border: 3px solid $azure;
	border-radius: 4px;
	padding: spacing(2);
	margin: spacing(2) 0;

	ul {
		list-style: outside;
		padding-left: spacing(4);
		padding-top: spacing(1);
	}
}

.property-survey-evidence-modal-image-container {
	margin: spacing(2) 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: spacing(1);
}

.property-survey-evidence-modal-image-container-grid {
	margin: spacing(2) 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
	gap: spacing(1);

	@include if-size("tablet") {
		flex-direction: row;
	}

	> * {
		flex: 1;
		min-width: calc(50% - spacing(1));
	}
}

.property-survey-evidence-intro-card-header {
	margin-bottom: spacing(1);
}

.property-survey-evidence-intro-dos-donts-container {
	display: flex;
	flex-direction: column;
	gap: spacing(4);
	margin-top: spacing(2);

	@include if-size("desktop") {
		flex-direction: row;
	}
}

.property-survey-evidence-intro-dos,
.property-survey-evidence-intro-donts {
	border-width: 2px;
	border-style: solid;
	border-radius: 8px;
	padding: spacing(1);

	ul {
		list-style: outside;
		padding-left: spacing(4);
	}
}

.property-survey-evidence-intro-dos {
	border-color: $azure;
}

.property-survey-evidence-intro-donts {
	border-color: $terracotta;
}

#property-footnote {
	color: $denim;
	opacity: 0.5;
	grid-column: span 1;
	text-align: left;
	padding: spacing(1) 0 spacing(2);
	order: 5;

	@include if-size("desktop-xl") {
		padding-top: spacing(4);
		grid-column: span 2;
	}

	a {
		color: $denim;
		text-decoration: underline;
	}
}

.property-survey-question-section-seperator {
	width: 100%;
	background-color: #ecece7;
	height: 2px;
	margin: spacing(2) 0;
}

.property-survey-question-catchall-warning-box {
	background-color: $sunflower-lightest;

	border-radius: 16px;
	border: 2px solid $sunflower;
	padding: spacing(2);

	ul {
		li {
			margin-left: spacing(3);
		}
	}
}

.property-survey-one-column-checkbox-container {
	.checkbox:not(:last-of-type) {
		margin-bottom: spacing(1);
	}
}

#properties-page-property-card-container {
	grid-column: 1;
}

#property-page-widgets-container {
	grid-column: 1 / -1;
	display: flex;
	order: 5;
	gap: 24px;
	flex-direction: row;
	flex-wrap: wrap;

	> div {
		flex: 1 1 320px;
	}
}

#property-back-btn {
	display: none;
	gap: 8px;
	align-items: center;
	cursor: pointer;
	@include if-size("desktop") {
		display: flex;
		grid-column: 1/-1;
	}

	svg {
		position: relative;
		top: 2px;
	}
}
