#plaid-settings {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#plaid-item-manager {
	.plaid-item-manager-add-account-row,
	.plaid-item-manager-account-row {
		max-width: 650px;
		margin: 0 spacing(2);

		@include if-size("desktop") {
			margin: 0 spacing(9);
		}
	}
}
