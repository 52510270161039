.border-1 {
	border-width: 1px;
}

.border-dashed {
	border-style: dashed;
}

.border-denim-extra-light {
	border-color: $denim-extra-light;
}
