$new-breakpoints: (
	"mobile": (
		min-width: 336px,
	),
	"mobile-lg": (
		min-width: 415px,
	),
	"tablet": (
		min-width: 672px,
	),
	"desktop": (
		min-width: 960px,
	),
	"desktop-lg": (
		min-width: 1060px,
	),
	"desktop-nav-bar-signed-in": (
		min-width: 1113px,
	),
	"desktop-xl": (
		min-width: 1232px,
	),
	"monitor": (
		min-width: 1440px,
	),
	"monitor-lg": (
		min-width: 1728px,
	),
	"monitor-xl": (
		min-width: 1900px,
	),
);

@mixin if-size($breakpoint) {
	$raw-query: map-get($new-breakpoints, $breakpoint);

	@if $raw-query {
		$query: if(
			type-of($raw-query) == "string",
			unquote($raw-query),
			inspect($raw-query)
		);

		@media #{$query} {
			@content;
		}
	} @else {
		@error 'No value found for `#{$breakpoint}`. '
        + 'Make sure you have passed a valid value as defined in `$breakpoints` map in variables.scss.';
	}
}

.hidden {
	display: none;
}

.block {
	display: block;
}

// a css helper class for each breakpoint
// used to hide elements on specific breakpoints
// e.g. .mobile:hidden { display: none; }
// generate the code
@each $breakpoint, $query in $new-breakpoints {
	.#{$breakpoint}-hidden {
		@include if-size($breakpoint) {
			display: none;
		}
	}
}

@each $breakpoint, $query in $new-breakpoints {
	.#{$breakpoint}-block {
		@include if-size($breakpoint) {
			display: block;
		}
	}
}

@each $breakpoint, $query in $new-breakpoints {
	.#{$breakpoint}-inline-block {
		@include if-size($breakpoint) {
			display: inline-block;
		}
	}
}

@each $breakpoint, $query in $new-breakpoints {
	.#{$breakpoint}-flex {
		@include if-size($breakpoint) {
			display: flex;
		}
	}
}
