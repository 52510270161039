.survey-builder {
	&-template {
		&-single-flow {
			&-fullscreen {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999999;
				height: 100dvh;
				max-height: 100dvh;
				width: 100dvw;
				background-color: $white;

				overflow-x: hidden;
				overflow-y: auto;
				display: flex;
				flex-direction: column;

				&-header {
					z-index: 999999;
					position: sticky;
					top: 0px;
					background-color: $denim-lightest;
					border-bottom: 1px solid $slate;

					&-content {
						padding: spacing(1.5) spacing(3);
						@include if-size("desktop") {
							padding: spacing(2);
						}

						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}

				.survey-builder-navigation-row {
					display: flex;
					justify-content: space-between;
					padding: spacing(4) spacing(2) spacing(2) spacing(2);
					background: linear-gradient(to bottom, transparent, white 30%);
				}

				&-sticky-bottom {
					position: sticky;
					bottom: 0;
				}

				&-body {
					margin-bottom: auto;
					padding: spacing(2) spacing(2);
					@include if-size("desktop") {
						padding: spacing(5) spacing(18);
					}

					max-width: 1400px;
					margin-left: auto;
					margin-right: auto;
				}

				&-footer {
					background-color: $denim-lightest;

					padding: spacing(2);
					@include if-size("desktop") {
						padding: spacing(5) spacing(2);
					}
				}
			}

			&-embedded {
				.survey-builder-navigation-row {
					display: flex;
					justify-content: space-between;
					padding: spacing(4) spacing(2) spacing(2) spacing(2);
					background: linear-gradient(to bottom, transparent, white 30%);
				}

				&-body {
					margin-bottom: auto;
				}
			}
		}

		&-multiple-flows {
			&-fullscreen {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 999999;
				height: 100dvh;
				max-height: 100dvh;
				width: 100dvw;
				background-color: $white;

				overflow-x: hidden;
				overflow-y: auto;
				display: flex;
				flex-direction: column;

				&-header {
					z-index: 1000000;
					position: fixed;
					top: 0px;
					left: 0px;
					width: 100dvw;
					background-color: $denim-lightest;
					border-bottom: 1px solid $slate;

					&-content {
						padding: spacing(1.5) spacing(3);
						@include if-size("desktop") {
							padding: spacing(2);
						}

						display: flex;
						justify-content: space-between;
						align-items: center;
					}
				}

				&-body {
					display: flex;
					flex-direction: column;
					position: relative;
					top: 100px;
					max-height: calc(100dvh - 100px);
					width: 100%;

					@include if-size("desktop") {
						flex-direction: row;
						height: 100%;
						max-height: 100dvh;
						padding-top: 62px;
						height: 100dvh;
						top: unset;
						position: relative;

						overflow: hidden;
					}
				}

				&-nav {
					z-index: 999999;
					display: flex;
					position: fixed;
					top: 49px;
					width: 100dvw;

					@include if-size("desktop") {
						width: unset;
						flex-direction: column;
						border-right: 1px solid $slate;
						height: 100%;
						padding: spacing(3) spacing(4);

						position: relative;
						top: unset;
					}
				}

				&-flow-body {
					max-height: 100%;
					overflow: auto;
					overflow-x: hidden;
					width: 100%;
					margin-bottom: auto;
					padding: spacing(2) spacing(2);
					@include if-size("desktop") {
						padding: spacing(3) spacing(3);
					}

					&-step {
						margin: 0 auto;
						max-width: 100%;
						@include if-size("desktop") {
							max-width: 1000px;
						}
					}
				}
			}

			&-step-header {
				border-radius: 8px;
				background-color: $sky-light;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: spacing(2);
				padding: spacing(2) spacing(2);

				> * {
					flex: 1;
				}

				&-center-text {
					color: $azure;
					text-align: center;
					font-weight: 700;

					letter-spacing: -0.24px;
					@include _create_typography("12px", "18px");
					@include if-size("tablet") {
						letter-spacing: -0.21px;
						@include _create_typography("14px", "20px");
					}

					@include if-size("desktop") {
						letter-spacing: -0.36px;
						@include _create_typography("18px", "26px");
					}
				}
			}
		}
	}

	&-component {
		&-logo {
			height: 24px;
			@include if-size("desktop") {
				height: 30px;
			}
		}

		&-speedometer {
			height: 16px;
			@include if-size("desktop") {
				height: 20px;
			}
		}

		&-encryption {
			height: 16px;
			@include if-size("desktop") {
				height: 20px;
			}
		}

		&-light-bulb {
			height: 20px;
		}

		&-house-with-coins {
			height: 210px;
			@include if-size("desktop") {
				height: 190px;
			}
		}

		&-close {
			padding: 5px;
			background-color: transparent;
			border: none;
			height: 24px;
			width: 24px;
			svg {
				width: 14px;
				height: 14px;
			}

			&:hover {
				cursor: pointer;
			}
		}

		&-progress-bar {
			width: 100%;
			background-color: transparent;
			height: 4px;

			&-fill {
				height: 100%;
				background-color: $powder;
			}
		}

		&-title {
			@extend .extra-large;
			font-weight: 800;
			margin-bottom: 4px;
			@include if-size("desktop") {
				margin-bottom: spacing(1);
			}
		}

		&-subtitle {
			@extend .large;
			font-weight: 800;
			margin-bottom: 2px;
		}

		&-body {
			color: $denim-medium;
			margin-bottom: 4px;
			@include if-size("desktop") {
				margin-bottom: spacing(1);
			}
		}

		&-savings {
			display: flex;
			align-items: center;
			gap: spacing(1);

			.savings {
				font-size: 36px;
				line-height: 36px;
				font-family: "Moret";
				color: $royal;

				@include if-size("desktop-xl") {
					font-size: 50px;
					line-height: 50px;
				}
			}

			sup {
				font-size: 20px;
				line-height: 20px;
				font-family: "Moret";
				color: $royal;

				@include if-size("desktop-xl") {
					font-size: 32px;
					line-height: 32px;
				}
			}

			label {
				text-align: right;
				color: $royal;
				font-weight: bold;

				margin-top: 4px;
				@include if-size("desktop-xl") {
					margin-top: 12px;
				}
			}
		}

		&-message {
			padding: spacing(2);
			border-radius: 8px;
			margin-bottom: spacing(2);
			line-height: spacing(3);

			p {
				color: $white;
			}

			&.bg-azure {
				background-color: $azure;
			}

			&.bg-rust {
				background-color: $rust;
			}

			&.bg-sunflower-lightest {
				background-color: $sunflower-lightest;
			}
		}

		&-two-input-row {
			display: flex;
			flex-direction: column;

			@include if-size("desktop") {
				flex-direction: row;
				gap: spacing(3);
			}

			> * {
				flex: 1;
			}
		}

		&-tooltip {
			margin-bottom: spacing(3);
		}

		&-blocks {
			&-block {
				border-radius: 8px;
				padding: spacing(2) spacing(3);
				@include if-size("desktop") {
					padding: spacing(3);
				}
				background-color: $sky-light;
			}

			&-faded {
				border-radius: 8px;
				padding: spacing(3);
				background: linear-gradient(
					180deg,
					#bbccd4 -41.01%,
					rgba(247, 249, 252, 0) 100%
				);
			}

			&-outline {
				border-radius: 8px;
				background-color: $sky;
				padding: spacing(1);
				@include if-size("desktop") {
					padding: spacing(1) spacing(3);
				}

				border: solid 1px $azure;
			}

			&-template {
				&-info {
					margin-bottom: spacing(3);
					padding: spacing(2);
					@include if-size("desktop") {
						padding: spacing(3);
					}

					&-header {
						.hand-signing-document-graphic {
							width: 70px;
							height: auto;
						}

						.house-graphic {
							width: 64px;
							height: auto;
						}

						.house-with-shield-graphic {
							width: 64px;
							height: auto;
						}

						.house-with-percentage-graphic {
							width: 64px;
							height: auto;
						}

						.hand-signing-document-with-balance-graphic {
							width: 70px;
							height: auto;
						}

						.electric-bill-graphic {
							width: 55px;
							height: auto;
						}

						.laptop-wifi-graphic {
							width: 75px;
							height: auto;
						}
					}

					&-container {
						* {
							color: $royal;
						}
					}
				}
			}
		}

		&-banner {
			display: flex;
			align-items: center;
			width: fit-content;
			border-radius: 8px;
			padding: 14px spacing(3);
			@include if-size("desktop") {
				padding: spacing(3);
				max-width: 650px;

				&.max-w-full {
					max-width: 100%;
				}
			}

			margin-top: spacing(3);
			@include if-size("desktop") {
				margin-top: spacing(4);
			}

			&.bg-blonde-light {
				background-color: $blonde-light;
			}

			&.bg-sky {
				background-color: $sky;
			}

			&.bg-sky-light {
				background-color: $sky-light;
			}

			&.bg-kelly-light {
				background-color: $kelly-light;
			}

			&.bg-slate {
				background-color: $slate;
			}
		}

		&-badge {
			width: fit-content;
			white-space: nowrap;
			border-radius: 8px;
			padding: 6px 12px;

			&.bg-kelly-light {
				background-color: $kelly-light;
			}
		}

		&-fields {
			&-checkbox {
				gap: spacing(1);

				&.checkbox {
					.pretty {
						margin-top: 5px;
						height: 16px;
						width: 16px;
						margin-right: 0;

						input {
							min-width: unset;
							width: 16px;
							position: relative;
						}

						.checkbox {
							height: 16px;
							width: 16px;
						}

						.state {
							margin-top: unset;
							height: 16px;
							width: 16px;
							position: absolute;
							top: 0;
							left: 0;

							label:before,
							label:after {
								top: 0;
								height: 16px;
								width: 16px;
							}

							.icon {
								height: 16px;
								width: 16px;
								box-sizing: border-box;
								border: none;
								transform: none;
								top: 0;
								left: 0;
							}
						}
					}
				}

				label.label {
					letter-spacing: -0.36px;
					@include _create_typography("18px", "26px");
					@include if-size("tablet") {
						letter-spacing: -0.2px;
						@include _create_typography("20px", "28px");
					}
					text-transform: none;
					font-weight: normal;
				}
			}

			&-radio {
				&-group {
					margin-top: spacing(2);
					@include if-size("desktop") {
						margin-top: spacing(4);
					}
					&.layout {
						&-tiles {
							display: flex;
							flex-direction: column;
							gap: spacing(2);

							@include if-size("desktop") {
								display: grid;
								grid-template-columns: minmax(auto, 650px) minmax(auto, 650px);
								grid-gap: spacing(2);
							}
						}
						&-list {
							display: flex;
							flex-direction: column;
							gap: spacing(2);
						}
					}
				}

				&-option {
					max-width: 650px;
					border: 1px solid $denim-lightest;

					&.selected {
						border-color: $azure;
						background-color: $sky-light;
					}

					*:hover,
					&:hover {
						cursor: pointer;
					}

					&-icon {
						min-height: 40px;
						max-height: 40px;
						min-width: 40px;
						max-width: 40px;

						@include if-size("desktop") {
							min-height: 48px;
							max-height: 48px;
							min-width: 48px;
							max-width: 48px;
						}
					}
				}

				&-bullet {
					min-width: 18px;
					min-height: 18px;
					border-radius: 50%;
					border: 2px solid $denim;

					position: relative;

					.inner {
						display: none;
					}

					&.selected {
						border-color: $azure;
						.inner {
							display: block;
							position: absolute;
							top: 2px;
							left: 2px;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: $azure;
						}
					}
				}
			}

			&-jumbo {
				&-dollar {
					max-width: 650px;
					margin-top: spacing(3);
					@include if-size("desktop") {
						margin-top: spacing(4);
					}

					width: 100%;
					@include if-size("desktop") {
						max-width: 650px;
						width: fit-content;
					}

					label {
						margin-bottom: spacing(2);
					}

					&-input-section {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
						gap: spacing(1);
						@include if-size("desktop") {
							gap: 0;
						}
						padding-bottom: spacing(2);
						border-bottom: solid 1px $denim-extra-light;

						span {
							@extend .h3;
							color: $denim_5;
							margin-bottom: auto;
						}

						input {
							max-width: 350px;
							@extend .h1;
							color: $denim;
							background-color: transparent;
							border: none;
							padding: 0 spacing(1);

							caret-color: $denim-extra-light;
						}

						.survey-builder-component-badge {
							margin: auto 0;
							width: fit-content;
						}
					}
				}
			}

			&-upload-files {
				border-radius: 8px;
				padding: spacing(2) spacing(2);
				@include if-size("desktop") {
					padding: spacing(2);
				}
				background-color: $denim-lightest;

				margin-bottom: spacing(2);
			}

			&-textarea {
				width: 100%;
				margin-bottom: spacing(2);
			}

			&-dollar {
				width: 100%;
				margin-bottom: spacing(2);

				.label-container {
					label.label.input-label,
					label.label.required.input-label {
						color: $denim-medium;
					}
				}
			}

			&-date {
				width: 100%;
				margin-bottom: spacing(2);

				.label-container {
					label.label.input-label,
					label.label.required.input-label {
						color: $denim-medium;
					}
				}
			}

			&-text {
				margin-bottom: spacing(2);

				.label-container {
					label.label.input-label,
					label.label.required.input-label {
						color: $denim-medium;
					}
				}
			}

			&-select {
				&-container {
				}

				&-input {
					margin-bottom: spacing(2);
					.label-container {
						label.label.input-label,
						label.label.required.input-label {
							color: $denim-medium;
							margin-bottom: spacing(1);
						}
					}
				}
			}
		}

		&-intro-screen {
			z-index: 1000000;
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: white;

			&-header {
				position: relative;
				z-index: 1000000;
				height: 80px;
				background-color: $denim-lightest;
				display: flex;
				justify-content: center;
				align-items: center;

				.survey-builder-component-logo {
					height: 45px;
				}
			}
		}

		&-modal-backdrop {
			z-index: 1000000;
			position: fixed;
			top: 0;
			left: 0;
			width: 100dvw;
			height: 100dvh;
			background-color: #053249bf;
		}
	}
}
