#contactus-page {
	padding: spacing(8) 0 0;

	#faq {
		margin: spacing(8) 0 0;
		padding: spacing(8) 0;
		background-color: $cloud;

		.contactus-section-title {
			grid-column: 1 / -1;
			text-align: center;
			margin-top: 0;
			margin-bottom: spacing(4);

			@include if-size("desktop") {
				text-align: left;
				margin-bottom: spacing(6);
			}
		}
	}
}

#contactus-title-section {
	#contact-hero-icon {
		grid-column: 1 / -1;
		margin: 0 auto spacing(2);
		max-width: 50%;
		width: 250px;
		height: auto;

		@include if-size("desktop") {
			grid-column: 2 / 5;
			max-width: 209px;
			width: 100%;
		}
	}

	#help-center-graphic {
		grid-column: 1 / -1;
		margin: 0 auto spacing(2);
		max-width: 50%;
		width: 250px;
		height: auto;

		@include if-size("desktop") {
			grid-column: 2 / 5;
			max-width: 209px;
			width: 100%;
		}
	}

	#contactus-title-container {
		grid-column: 1 / -1;
		text-align: center;

		@include if-size("desktop") {
			grid-column: 6 / -1;
			text-align: left;
		}
	}

	#contactus-subtitle {
		margin-top: spacing(1);
		margin-bottom: spacing(3);
		text-align: center;
		@include if-size("desktop") {
			max-width: 508px;
			text-align: left;
		}
	}

	#contactus-view-cta-container {
		display: flex;
		align-items: center;

		@include if-size("desktop") {
			align-items: flex-start;
		}

		#contactus-view-cta-container-inner {
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: spacing(1);
			margin: 0 auto;

			@include if-size("desktop") {
				margin: unset;
			}

			@include if-size("desktop-xl") {
				flex-direction: row;
				gap: spacing(3);
			}
		}
	}

	#contactus-email {
		border-radius: 24px;
		background-color: $sky-light;
		padding: spacing(2) spacing(3);
	}

	#contactus-view-faq-btn,
	#contactus-view-phone-btn {
		// margin: spacing(3) auto 0;

		grid-column: 1 / -1;
		margin: 0 auto;
		width: 100%;

		@include if-size("desktop") {
			// margin: spacing(3) 0 0;
			margin: 0;
		}
	}
}

#contactus-method-container {
	width: 100%;
	position: relative;

	&-inner {
		grid-column: 1 / -1;
		z-index: 5;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 64px auto 0;
		border-radius: 16px;
		background-color: $royal;
		flex-direction: column;
		border-radius: 8px;
		padding: spacing(4);

		@include if-size("desktop") {
			padding-bottom: 0;
			background-color: transparent;
			width: 100%;
			flex-direction: row;
		}
	}

	&-overlay {
		z-index: -1;
		position: absolute;
		width: 100%;
		height: 50%;
		background-color: $cloud;
		bottom: 0;
	}

	.contactus-method {
		min-width: 0px;
		background-color: $royal;
		color: $cloud;
		display: flex;
		align-items: center;
		border-radius: 16px;
		cursor: pointer;
		flex-direction: row;
		justify-content: flex-start;

		&:not(:last-child) {
			width: 100%;
			border-radius: 0px;
			border-bottom: 1px solid #fff;
			padding-bottom: spacing(3);
			margin-bottom: spacing(3);
		}

		@include if-size("mobile") {
			min-width: 240px;
		}

		@include if-size("desktop") {
			width: 239px;
			height: 194px;
			margin: 0;
			flex-direction: column;
			justify-content: center;

			&:not(:last-child) {
				width: 239px;
				border-radius: 16px;
				border-bottom: none;
				padding-bottom: 0;
				margin-bottom: 0;
				margin-right: spacing(2);

				.contactus-method-col {
					margin-bottom: 0;
				}
			}
		}

		.contactus-method-col {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;

			@include if-size("desktop") {
				align-items: center;
				margin-bottom: 0;
			}
		}

		.contactus-method-icon {
			margin-right: spacing(2);
			height: 32px;
			width: 32px;

			@include if-size("desktop") {
				margin-right: 0;
			}
		}

		.contactus-method-title,
		.contactus-method-subtitle,
		.contactus-method-cta > p {
			color: $cloud;
		}

		svg {
			fill: $cloud;
		}

		.contactus-method-title {
			margin-top: 8px;
		}

		.contactus-method-cta {
			display: flex;
			align-items: center;
			margin-top: spacing(2);

			p {
				padding-bottom: 2px;
				margin-right: 8px;
			}
		}
	}
}

#contactus-lets-talk {
	width: 100%;
	background-color: $cloud;
	margin-top: spacing(8);

	@include if-size("desktop") {
		margin-top: spacing(12);
	}

	.contactus-lets-talk-title {
		grid-column: 1 / -1;
		margin-top: spacing(8);
	}

	#contactus-lets-talk-icon {
		height: 30px;
		width: 48px;
	}

	#contactus-email-row {
		grid-column: 1 / -1;
		display: flex;
		justify-content: center;
		padding-bottom: spacing(6);
		margin-bottom: spacing(4);
		border-bottom: 2px solid $white;

		#contactus-email {
			display: flex;
			align-items: center;

			svg {
				margin-right: spacing(2);
			}
		}
	}

	#contactus-lets-talk-subtitle-row {
		grid-column: 1 / -1;
		display: flex;
		align-items: center;
		margin-top: spacing(1);
		margin-bottom: spacing(8);

		#contactus-lets-talk-subtitle {
			margin-left: 18px;
			font-size: 18px;
			line-height: 22px;
		}
	}
}

.contactus-region {
	grid-column: 1 / -1;
	display: flex;
	margin-bottom: spacing(7);
	justify-content: center;

	@include if-size("desktop") {
		justify-content: flex-start;
		&:not(:nth-child(8)) {
			grid-column: span 6;
		}
	}

	.contactus-region-icon {
		width: 64px;
		height: 64px;
	}

	.contactus-region-info {
		margin-left: 16px;

		.contactus-region-subtitle {
			margin-left: 5px;
			margin-bottom: spacing(1);
		}
	}

	h5 {
		padding-bottom: 4px;
	}
}

#contactus-fax-container {
	grid-column: 1 / -1;
	width: 100%;
	display: flex;
	justify-content: center;
	margin: spacing(1) 0 spacing(6);

	@include if-size("desktop") {
		margin: spacing(4) 0 spacing(8);
	}

	#contactus-fax {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		#contactus-fax-icon {
			height: 45px;
			width: 45px;
		}

		#contactus-fax-title {
			margin-top: 9px;
		}

		#contactus-fax-number {
			margin-top: 2px;
		}
	}
}

.contact-suggested-articles {
	text-align: left;
	width: 100%;
	padding-bottom: spacing(1);
	transition: all 150ms ease-in-out;
	animation: fadeIn 0.8s ease-in-out;

	.label {
		padding-bottom: 4px;
		margin-bottom: 12px;
		border-bottom: 2px solid $sand;
	}

	.suggested-article {
		padding-bottom: spacing(2);
		&:hover {
			a {
				text-decoration: underline;
				color: $denim;
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#contact-us-phone-states {
	.help-center-search-container {
		margin-bottom: 35px;
	}
}

.contact-us-phone-body {
	display: flex;
	gap: 4px;
	padding-top: 4px;
	flex-direction: column;

	@include if-size("tablet") {
		flex-direction: row;
		align-items: center;
		gap: 16px;
	}

	.contact-us-phone-body-divs {
		display: flex;
		align-items: center;
		gap: 4px;
	}
}

.contact-us-phone-states-list {
	.contactus-region {
		grid-column: 1/-1;
		@include if-size("desktop") {
			&:nth-of-type(odd) {
				grid-column: 2/6;
			}
			&:nth-of-type(even) {
				grid-column: 8/12;
			}
		}
	}
}

.investor-contact-us {
	margin-bottom: spacing(4);
	grid-column: 1 / -1;
	padding: spacing(2);
	border-radius: 16px;
	background-color: $sky-light;
	display: flex;
	flex-direction: column;
	gap: spacing(1);

	@include if-size("desktop") {
		flex-direction: row;
		align-items: baseline;
		margin-bottom: spacing(8);
		grid-column: 2 / -2;
	}

	&:hover {
		cursor: pointer;
	}

	.quick-link-link {
		font-weight: 622;
		font-size: 20px;
		line-height: 24px;

		@include if-size("desktop") {
			font-size: 20px;
			line-height: 28px;
		}

		.quick-link-arrow {
			width: 12px;
			height: 12px;
			margin-left: 12px;
			margin-top: 4px;

			@include if-size("desktop") {
				width: 16px;
				height: 16px;
			}
		}
	}
}
