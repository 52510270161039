.self-start {
	align-self: flex-start;
}

.self-end {
	align-self: flex-end;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}

.margin-auto {
	margin: auto;
}

.position-relative {
	position: relative;
}

.nowrap {
	white-space: nowrap;
}

.ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.font-weight-400 {
	font-weight: 400 !important;
}

.extrabold {
	font-weight: 800 !important;
}

.bold {
	font-weight: 600 !important;
}

.superbold {
	font-weight: 1000 !important;
}

.italic {
	font-style: italic;
}

.uppercase {
	text-transform: uppercase;
}

.underline {
	text-decoration: underline;
}

.hover-underline:hover {
	text-decoration: underline;
}

.full-grid-column {
	grid-column: 1/-1;
}

.azure {
	color: $azure !important;
}

.slate {
	color: $slate !important;
}

.rust {
	color: $rust;
}

.rust-dark {
	color: $rust-dark;
}

.denim {
	color: $denim;
}

.denim_5 {
	color: $denim_5 !important;
}

.denim-medium {
	color: $denim-medium !important;
}

.label.denim-medium {
	color: $denim-medium;
}

.label.denim_5 {
	color: $denim_5;
}

.royal {
	color: $royal !important;
}

.royal_5 {
	color: rgba($royal, 0.5);
}

.powder {
	color: $powder;
}

.body-tiny.denim_5 {
	color: $denim_5;
}

.body-tiny.denim-medium {
	color: $denim-medium;
}

.body-tiny.azure {
	color: $azure;
}

.extra-small.azure {
	color: $azure;
}

.extra-small.white {
	color: $white;
}

.body-tiny.bold {
	font-weight: 700;
}

.extra-small {
	font-family: $ff-secondary;
	font-size: 14px;
	font-style: normal;
	line-height: 20px; /* 142.857% */
	letter-spacing: -0.21px;
}

.terracotta {
	color: $terracotta;
}

.kelly {
	color: $kelly;
}

.white {
	color: $white;
}

.cloud {
	color: $cloud;
}

.gold {
	color: $gold;
}

.kelly-dark {
	color: $kelly-dark !important;
}

.rust-dark {
	color: $rust-dark !important;
}

.blue-green-gradient {
	background: linear-gradient(270deg, #57c975 11.34%, #1c7ce5 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.kelly-gradient {
	background: -webkit-linear-gradient($kelly, $kelly-dark);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.azure-gradient {
	background: -webkit-linear-gradient($azure, $royal);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.rust-gradient {
	background: -webkit-linear-gradient(#de906f, #ce6549);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.rust-dark {
	color: $rust-dark;
}

.mint {
	color: $mint;
}

.tracking-minus-2 {
	letter-spacing: -0.02em;
}

$_default-text: $navy;

@mixin _create_typography($size, $height) {
	font-size: unquote($size);
	line-height: unquote($height);
}

::placeholder {
	color: $input-border-default;
	mix-blend-mode: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
td,
.body-tiny,
.extra-small {
	font-weight: normal;
	color: $denim;
}

h1,
.h1 {
	font-family: $ff-secondary;
	font-weight: 800;
	letter-spacing: -0.48px;
	@include _create_typography("48px", "56px");
	@include if-size("tablet") {
		@include _create_typography("64px", "74px");
		letter-spacing: -1.92px;
	}
}

h2,
.h2 {
	font-family: $ff-secondary;
	font-weight: 727;
	letter-spacing: -0.4px;
	@include _create_typography("40px", "48px");
	@include if-size("tablet") {
		@include _create_typography("56px", "64px");
		letter-spacing: -1.12px;
		font-weight: 800;
	}
}

h3,
.h3 {
	font-family: $ff-secondary;
	font-weight: 800;
	letter-spacing: -0.32px;
	@include _create_typography("32px", "40px");
	@include if-size("tablet") {
		letter-spacing: -1.44px;
		@include _create_typography("48px", "58px");
	}
}

h4,
.h4 {
	font-family: $ff-secondary;
	font-weight: 727;
	letter-spacing: -0.192px;
	@include _create_typography("24px", "30px");
	@include if-size("tablet") {
		letter-spacing: -0.96px;
		@include _create_typography("32px", "40px");
	}
}

h5,
.h5 {
	font-family: $ff-secondary;
	font-weight: 800;
	letter-spacing: -0.2px;
	@include _create_typography("20px", "24px");
	@include if-size("tablet") {
		letter-spacing: -0.48px;
		@include _create_typography("24px", "30px");
	}
}

p,
li,
td {
	font-weight: 433;
	font-family: $ff-secondary;
	letter-spacing: -0.16px;
	@include _create_typography("16px", "22px");
	@include if-size("tablet") {
		letter-spacing: -0.36px;
		@include _create_typography("18px", "26px");
	}
}

// --- Font Weight Classes ---
p.semibold,
.semibold,
td.semibold,
li.semibold,
span.semibold,
label.semibold,
.label.semibold {
	font-weight: 700;
}

p.bold,
.bold,
td.bold,
li.bold,
span.bold,
label.bold,
.label.bold {
	font-weight: 727;
}

// --- Font Size Classes ---
p.tiny,
td.tiny,
li.tiny,
span.tiny {
	letter-spacing: -0.24px;
	@include _create_typography("10px", "15px");
	@include if-size("tablet") {
		line-height: 18px;
		@include _create_typography("12px", "18px");
	}
}

p.xs,
p.extra-small,
td.xs,
li.xs,
a.xs,
span.xs {
	letter-spacing: -0.24px;
	@include _create_typography("12px", "18px");
	@include if-size("tablet") {
		letter-spacing: -0.21px;
		@include _create_typography("14px", "20px");
	}
}

.sm,
a.sm,
p.sm,
p.small,
td.sm,
li.sm,
span.sm,
textarea.sm {
	letter-spacing: -0.21px;
	@include _create_typography("14px", "18px");
	@include if-size("tablet") {
		letter-spacing: -0.16px;
		@include _create_typography("16px", "22px");
	}
}

p.lg,
p.large,
td.lg,
li.lg,
span.lg {
	letter-spacing: -0.36px;
	@include _create_typography("18px", "26px");
	@include if-size("tablet") {
		letter-spacing: -0.2px;
		@include _create_typography("20px", "28px");
	}
}

p.xl,
p.extra-large,
td.xl,
li.xl,
span.xl {
	letter-spacing: -0.2px;
	@include _create_typography("20px", "28px");
	@include if-size("tablet") {
		letter-spacing: -0.24px;
		@include _create_typography("24px", "34px");
	}
}

.h3-update {
	line-height: 30px;
	letter-spacing: -0.192px;
	font-family: $ff-secondary;
	font-weight: 727;
	@include if-size("tablet") {
		font-size: 48px;
		line-height: 78px;
		line-height: 58px;
		letter-spacing: -1.44px;
	}
}

.body-tiny {
	@include _create_typography("10px", "14px");
	@include if-size("tablet") {
		@include _create_typography("12px", "16px");
	}
}

sup {
	font-size: xx-small;
	vertical-align: super;
}

// --- Custom Typography ---

.display1 {
	font-family: $ff-primary;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.48px;
	font-size: 48px;
	@include if-size("tablet") {
		letter-spacing: 0.64px;
		font-size: 64px;
	}
}

.display2 {
	font-family: $ff-primary;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.4px;
	font-size: 40px;
	@include if-size("tablet") {
		letter-spacing: 0.56px;
		font-size: 56px;
	}
}

.display3 {
	font-family: $ff-primary;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.32px;
	font-size: 32px;
	@include if-size("tablet") {
		letter-spacing: 0.48px;
		font-size: 48px;
	}
}

p.p-link-blue {
	color: $azure;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

::selection {
	background-color: $azure;
	color: $white;
}
