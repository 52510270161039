.stepper {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: spacing(1);

	@include if-size("desktop") {
		gap: spacing(3);
	}

	&-step {
		$component: &;

		flex: 1;

		&-name {
			@extend .sm;
			margin-bottom: 4px;
			color: $azure;
		}

		&-bar {
			background-color: $sky;
			border-radius: 40px;
			height: 6px;
			width: 100%;
		}

		&.fill-bar {
			#{$component}-bar {
				background-color: $azure;
			}
		}

		&.bold-name {
			#{$component}-name {
				@extend .semibold;
			}
		}
	}
}
