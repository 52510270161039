#account-add-another-property-card {
	grid-column: 1 / -1;

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	#appeal-address-input-container {
		padding: spacing(2);
		margin-top: spacing(4);
		margin-bottom: spacing(4);

		@include if-size("desktop") {
			margin-bottom: spacing(2);
		}
	}

	.parcel-number-container-address-page {
		margin-top: spacing(1);
	}

	#appeal-address-other-signup-methods {
		@include if-size("desktop") {
			margin-bottom: spacing(8);
		}
	}
}

#account-leftovers-container {
	grid-column: 1 / -1;
	margin-bottom: spacing(4);

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#account-leftovers-animation-container {
	max-width: 300px;
	margin: 0 auto;
}

.account-leftovers-property-card {
	grid-column: 1 / -1;
	// box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	padding: spacing(2);
	margin-top: spacing(2);
	border: 1px solid $denim-extra-light;

	&-selected {
		@extend .account-leftovers-property-card;
		border: 1px solid $azure;
		background: rgba(213, 234, 255, 0.25);
	}

	&:not(.no-hover) {
		&:hover {
			cursor: pointer;
			border: 1px solid $azure;
		}
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}

	.account-leftovers-property-card-main-content {
		display: flex;
		align-items: center;
	}
}

.account-leftovers-property-card-image {
	align-self: stretch;
	border-radius: 8px;
	overflow: hidden;
	min-width: 64px;
	width: 64px;

	@include if-size("desktop") {
		width: 120px;
	}
}

.account-leftovers-property-card-checkmark {
	padding: 6px 8px;
	background-color: $kelly-light;
	border: solid 1.5px $kelly;
	border-radius: 8px;
}

.account-leftovers-property-card-address {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	// Hack to get ellipsis to work with dynamic width
	.display-table {
		display: table;
		table-layout: fixed;
		width: 100%;
	}

	.ellipsis {
		display: table-cell;
	}

	@include if-size("desktop") {
		flex-direction: row;
		align-items: center;
		gap: spacing(2);
	}
}

.account-leftovers-property-card-savings,
.account-leftovers-property-card-auth-preview {
	flex: 1;
	margin-top: spacing(1);

	.label {
		color: $denim;
		white-space: nowrap;
		margin-bottom: -2px;
	}

	@include if-size("desktop") {
		margin-left: auto;
		margin-top: 0;
		text-align: right;

		.label {
			margin-bottom: 0;
		}
	}

	.quick-link-small {
		font-size: 14px;
		line-height: 16px;
	}
}

.account-leftovers-property-card-savings {
	&.address-row {
		display: none;

		@include if-size("desktop") {
			display: block;
		}
	}

	&.bottom-row {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: 0;
		background-color: $denim-lightest;
		border-radius: 8px;
		padding: spacing(1);

		@include if-size("desktop") {
			display: none;
		}
	}
}

#account-leftovers-select-all {
	margin-left: auto;
	text-align: right;
	color: $azure;
	cursor: pointer;
	width: fit-content;
	user-select: none;

	&:hover {
		text-decoration: underline;
	}
}

#account-leftovers-heading {
	display: flex;
	flex-direction: column;
	margin-bottom: spacing(3);

	@include if-size("desktop") {
		flex-direction: row;
	}
}

#account-leftovers-heading-cta {
	margin: spacing(3) auto 0;

	@include if-size("desktop") {
		margin: 0;
		margin-left: spacing(4);
	}
}

#account-leftover-empty-property-card {
	padding: spacing(2);
	grid-column: 1 / -1;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 16px;
	border: 1px solid $cloud;
	margin-top: spacing(1);
	text-align: center;

	p.sm {
		margin-bottom: spacing(0.5);
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
		margin-top: spacing(2);
	}
}

#account-leftovers-load-more-container {
	grid-column: 1/-1;
	margin-top: spacing(2);
	text-align: center;

	p.sm {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	@include if-size("desktop") {
		grid-column: 3 / -3;
	}
}

#leftovers-success-banner {
	border: 3px solid $kelly;
	background: $kelly-light;
	padding: spacing(4) spacing(2);
	border-radius: 16px;
	text-align: center;
}
