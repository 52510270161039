.property-grid.documents-page {
	gap: spacing(2);

	@include if-size("desktop") {
		gap: spacing(3);
	}

	@include if-size("monitor") {
		max-width: 1400px;
		grid-template-columns: 786px auto;
	}
}

#property-documents-card {
	position: relative;
	border-radius: 16px;
	border: solid 1px $slate;
	background: $white;
	box-shadow:
		-2px 13px 28px 0px #ababab1a,
		-7px 51px 51px 0px #ababab17,
		-16px 115px 70px 0px #ababab0d,
		-28px 204px 82px 0px #ababab03,
		-44px 319px 90px 0px #ababab00;

	padding: spacing(2);
	@include if-size("desktop") {
		padding: spacing(3);
	}

	height: min-content;

	.property-select-appeal-tax-year-dropdown-select > .input-lable {
		margin-left: 0 !important;
	}
}

#property-documents-title-row {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	margin-bottom: spacing(1);

	> div:first-child {
		margin-bottom: spacing(1);
	}
}

#property-documents-tax-year-dropdown-container {
	display: flex;
	align-items: center;
	gap: 8px;

	.property-documents-tax-year-dropdown {
		padding-right: spacing(3);
		background-position: calc(100% - 0.4rem) center;
		background-color: transparent;
		padding-top: 0;
		padding-bottom: 0;
	}

	.property-select-appeal-tax-year-dropdown {
		.input-label {
			margin-left: 0 !important;
		}
	}
}

#property-documents-card-underlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $cloud;
}

#property-documents-files-container {
	margin-top: spacing(2);
}

#property-documents-tab-container {
	display: flex;
	justify-content: center;
	gap: 16px;

	padding: 0 spacing(2);

	/* responsive edge case styles */
	@media screen and (min-width: 960px) and (max-width: 966px) {
		padding: 0 spacing(1);
		gap: 8px;
	}
}

.property-documents-tab {
	flex: 1;

	background-color: $white;
	border: solid 1px $slate;
	border-bottom: none;
	padding: 6px spacing(2);
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	cursor: pointer;

	// /* responsive edge case styles */
	// @media screen and (min-width: 960px) and (max-width: 966px) {
	// 	padding: spacing(1) spacing(0.5);
	// }

	p.sm {
		font-size: 14px;
		text-align: center;

		/* responsive edge case styles */
		@media screen and (min-width: 960px) and (max-width: 966px) {
			font-size: 12px;
		}
	}

	&-selected {
		@extend .property-documents-tab;
		background-color: $denim-lightest;
		border: solid 1px $denim-lightest;
		border-bottom: none;

		p {
			font-weight: bold;
		}
	}
}

#property-documents-files {
	width: 100%;
	padding: spacing(1) spacing(2);
	background-color: $denim-lightest;
	border-radius: 8px;
	max-height: 298px;
	min-height: 215px;
	position: relative;

	box-shadow: none;
	overflow-y: auto;
	@include styled-scrollbar;

	&-expanded {
		@extend #property-documents-files;
		padding-bottom: spacing(7);
		max-height: unset;
	}
}

#property-documents-show-all-pill {
	position: absolute;
	bottom: 8px;
	left: 50%;
	transform: translateX(-50%);
	background-color: $white;
	padding: spacing(0.5) spacing(2.5);
	border-radius: 9999px;
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);

	&-expanded {
		@extend #property-documents-show-all-pill;
	}
}

.property-documents-file {
	background-color: $white;
	max-width: 100%;
	padding: spacing(1);
	box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
	border-radius: 8px;
	margin-top: spacing(1.5);
	display: flex;
	align-items: center;
	justify-content: space-between;

	p.sm {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 14px;
		max-width: 218px;

		/* responsive edge case styles */
		@include if-size("desktop") {
			max-width: 90px;
		}

		@media screen and (min-width: 1000px) {
			max-width: 120px;
		}

		@media screen and (min-width: 1030px) {
			max-width: 150px;
		}

		@media screen and (min-width: 1060px) {
			max-width: 180px;
		}

		@media screen and (min-width: 1100px) {
			max-width: 218px;
		}
	}
}

.property-documents-download-icon {
	height: 16px;
	width: 16px;
	cursor: pointer;

	path {
		fill: $denim;
	}
}

.property-documents-file-skeleton {
	height: 16px;
	width: 80%;
	background-color: $sand;
	border-radius: 4px;
}

.property-documents-download-icon-skeleton {
	height: 16px;
	width: 16px;
	cursor: not-allowed;

	path {
		fill: $sand;
	}
}

#property-license-upload-modal-file-upload-container {
	@extend #property-documents-upload-modal-file-upload-container;
	max-width: 752px;
	margin: spacing(4) auto 0;

	.exemption-question-card-subtitle {
		opacity: 0.5;
	}

	.checkbox {
		p {
			font-size: 16px;
		}
	}
}

#property-documents-upload {
	&,
	&-modal {
		&-file-upload-container {
			position: relative;
		}

		&-file-upload-tax-year-select-container {
			display: flex;
			align-items: center;
			gap: 12px;
			margin-bottom: spacing(2);
		}

		&-file-upload-seperator {
			height: 2px;
			width: 100%;
			background-color: $white;
			margin: spacing(4) 0;
		}

		&-failure-box {
			margin-bottom: spacing(2);
			background-color: $rust;
			padding: spacing(2);
			border-radius: 16px;
			display: flex;
			align-items: center;
			flex-direction: column;

			p.sm {
				color: $white;
			}

			svg {
				height: 32px;
				width: 32px;
			}

			@include if-size("desktop") {
				flex-direction: row;

				svg {
					margin-right: spacing(1);
				}
			}
		}

		&-submitting-overlay {
			position: absolute;
			width: 100%;
			height: calc(100% - 80px);
			top: 0;
			left: 0;
			background-color: rgba($white, 0.8);
			z-index: 10;
		}

		&-submitting-button {
			background-color: $denim;
			z-index: 1001;
		}

		&-success-animation-container {
			display: flex;
			justify-content: center;
		}

		&-success-cta-container {
			margin: spacing(4) 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
		}
	}

	&-card {
		height: min-content;
		background-color: $white;
		position: relative;
		padding: spacing(2);
		border: solid 1px $slate;
		border-radius: 16px;
		box-shadow:
			-2px 13px 28px 0px #ababab1a,
			-7px 51px 51px 0px #ababab17,
			-16px 115px 70px 0px #ababab0d,
			-28px 204px 82px 0px #ababab03,
			-44px 319px 90px 0px #ababab00;

		@include if-size("desktop-xl") {
			padding: spacing(3);
			border-radius: 16px;
		}

		#property-documents-upload-file-upload-container {
			margin: 0;
		}

		#property-documents-upload-file-upload-tax-year-select-container {
			justify-content: flex-end;
		}

		#property-documents-upload-submitting-overlay {
			background-color: rgba($denim_lightest, 0.8);
			z-index: 10;
		}

		.file-upload-active {
			background-color: $sky-light;
		}
	}
}

.property-documents-upload {
	&,
	&-modal {
		&-submitting-animation {
			height: 200px;
			width: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&-success-animation {
			height: 144px;
			width: auto;
			margin: 0 auto;
		}

		&-success-file {
			padding: spacing(1);
			background-color: $white;
			border-radius: 8px;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
			margin-top: spacing(1);
		}
	}
}

#documents-page-left-container {
	display: flex;
	flex-direction: column;
	gap: spacing(2);
	.bordered-content {
		border-width: 2px;
		border-style: solid;
		padding: spacing(2);
		border-radius: 8px;
		background-color: $white;

		@include if-size("desktop-xl") {
		}

		&.rust {
			border-color: $rust;
			background-color: $rust-lightest-64;
		}

		&.kelly {
			border-color: $kelly;
		}

		.exemptions-final-tax-refund-and-invoice-container {
			display: flex;
			justify-content: space-between;
			padding-top: spacing(2);
		}

		.exemptions-final-tax-refund-box {
			background-color: $kelly-light;
			border-radius: 8px;
			color: $denim;
			display: inline-block;
			padding: spacing(1) spacing(2);

			border: 1px solid $kelly;

			.label,
			p {
				color: $denim;
			}
		}
	}
}
