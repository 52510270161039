#property-equity {
	&-details-modal {
		max-width: 944px;
		padding: 0;

		@include if-size("desktop") {
			margin: 0 auto;
		}

		sup {
			margin-right: 4px;
			margin-left: 2px;
		}

		&-confirmation-content {
			display: flex;
			flex-direction: column;
			gap: spacing(2);

			@include if-size("desktop") {
				gap: spacing(4);
			}

			padding: spacing(2);

			@include if-size("desktop") {
				padding: spacing(2) spacing(4);
			}
		}

		&-turn-on-refinance-monitoring-content {
			display: flex;
			flex-direction: column;
			padding: spacing(2);

			@include if-size("desktop") {
				padding: spacing(2) spacing(4);
			}
		}

		&-footer {
			padding: spacing(3);
			border-top: solid 1px $slate;
		}
	}

	&-details-content {
		display: flex;

		gap: spacing(2);
		flex-direction: column-reverse;
		@include if-size("desktop") {
			gap: spacing(3);
			flex-direction: row;
			align-items: center;
		}

		padding: spacing(2);

		@include if-size("desktop") {
			padding: spacing(2) spacing(4);
		}

		#property-equity-details-form {
			flex: 1;

			@include if-size("desktop") {
				border-radius: 16px;
				padding: spacing(3);
				background-color: $denim-lightest;
			}

			.form-title {
				margin-bottom: 2px;
				@include if-size("desktop") {
					margin-bottom: 4px;
				}
			}

			fieldset {
				margin-top: spacing(2);
				position: relative;
				display: flex;
				flex-direction: column;
				gap: spacing(2);
			}

			.property-equity-details-form-fields {
				display: flex;
				flex-direction: column;
				gap: spacing(2);
				position: relative;
			}

			button {
				margin: spacing(2) auto;
			}

			.text-field-input-wrapper {
				margin-top: 2px;
			}

			.select-field-input-wrapper,
			select.with-label {
				margin-top: 2px;
			}

			.input-label {
				color: $denim-medium;
			}
		}

		#property-equity-details-breakdown {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 12px;

			@include if-size("desktop") {
				gap: 20px;
				padding: 0 26px;
				flex-direction: column;
			}

			.equity-house {
				flex: 1;
				min-width: 80px;
				max-width: 300px;
				@include if-size("desktop") {
					margin: 0 auto;
					flex: 1;
					width: 260px;
				}
			}

			.mortgage-card-equity-debt-details {
				flex: 0;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;

				@include if-size("desktop") {
					gap: 20px;
					flex: 1;
					justify-content: center;
					flex-direction: row;
				}

				.mortgage-card-equity-debt-detail-box {
					min-width: 140px;
					max-width: 260px;
					height: 90px;

					background-color: $denim-lightest;
					border-radius: 8px;
					padding: spacing(2);

					width: 100%;

					flex: 0;
					@include if-size("desktop") {
						flex: 1;
					}
				}
			}
		}
	}

	&-details-footer {
		padding: spacing(3);
		border-top: solid 1px $slate;
	}

	&-card {
		position: relative;
		padding: spacing(4) 0;
		background-color: $cloud;
		border-radius: 16px;
		padding: spacing(2) spacing(2);

		display: flex;
		flex-wrap: wrap;
		row-gap: spacing(2);

		.beta-tag {
			text-transform: uppercase;
			background-color: $mint;
			color: $denim;
			border-radius: 2px;

			margin-top: 6px;
			padding: 2px 8px;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: 0.08em;
		}

		.beta-tooltip {
			.tooltip2-body,
			.triangle-top {
				background-color: $mint;
			}

			.tooltip2-body {
				label,
				p {
					color: $black;
				}

				.quick-link-small {
					margin-top: spacing(3);
					white-space: nowrap;
				}

				.quick-link-arrow {
					fill: $black;
				}
			}
		}

		&-title-row {
			flex: 1;
			display: flex;
			align-items: baseline;
			justify-content: space-between;

			& > p {
				display: flex;
				align-items: center;
				gap: spacing(1);
			}
		}

		label {
			margin-bottom: 3px;
			color: $denim_5;
			white-space: nowrap;

			display: flex;
			gap: 4px;
			align-items: center;
		}

		p {
			border-radius: 4px;
		}

		svg.info-icon {
			height: 12px;
			width: 12px;

			path {
				fill: $denim_5;
			}
		}

		.equity-card-box {
			flex: 1 0 100%;
			padding: spacing(2);
			gap: 8px;
			background: $white;
			box-shadow: 1px 3px 7px 2px rgba(5, 50, 73, 0.05);
			border-radius: spacing(1);

			display: flex;
			align-items: center;
			justify-content: center;

			.line {
				width: 100%;
				background-color: $cloud;
				height: 1px;
				margin: spacing(1) 0;
			}

			.dollar-and-percentage {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;
			}

			.equity-summary {
				display: flex;
				flex-direction: column;
				flex: 1;
			}

			.equity-house {
				max-height: 120px;
				max-width: 120px;

				svg {
					width: 100%;
					height: 100%;
				}
			}

			.start-tracking {
				margin-left: spacing(1);
				display: flex;
				flex-direction: column;
				gap: spacing(1);
			}
		}

		.equity-values {
			display: flex;
			flex-wrap: wrap;
			row-gap: spacing(2);

			.tooltip2 {
				width: fit-content;
			}
		}

		.equity-value {
			flex: 1 0 50%;

			&-full-line {
				flex: 1 0 100%;
			}

			&-line-break {
				flex: 1 0 100%;
				height: 2px;
				background-color: $white;
			}
		}

		.details-button {
			justify-content: flex-end;
		}

		&.collapsed {
			.details-button {
				position: absolute;
				bottom: 16px;
				right: 16px;
			}

			.equity-value-full-line {
				& > label {
					overflow: clip;
					mask-image: linear-gradient(to top, transparent, black 100%, black);
				}

				& > p {
					display: none;
				}
			}

			.hide-on-collapse {
				display: none;
			}
		}

		.equity-card-feedback {
			animation: fade-in 0.3s ease-in 1 normal both;
			-webkit-animation: fade-in 0.3s ease-in 1 normal both;
		}
	}
}

.property-equity-details-modal-mortgage-paid-off-box {
	width: fit-content;
	background-color: $kelly-light;
	padding: spacing(2);
	border-radius: 8px;
	display: inline-block;

	@include if-size("desktop") {
		padding: spacing(1) spacing(2);
		left: 30px;
	}

	p {
		color: $kelly-dark;
	}
}

#mortgage-equity-service-content {
	background: $white;
	padding: spacing(2) 0;
	border-radius: 8px;

	@include if-size("desktop") {
		padding: spacing(2);
	}

	.mortgage-paid-off-badge {
		background-color: $sky-light;
		border-radius: 8px;
		width: fit-content;
		padding: 6px 12px;
	}

	#mortgage-equity-service-value-prop-card-container {
		display: flex;
		gap: spacing(1.5);
		margin-top: spacing(2);
		flex-direction: column;

		@include if-size("desktop") {
			flex-direction: row;
		}

		.mortgage-equity-service-value-prop-card {
			flex: 1;
			background: $denim_lightest;
			padding: spacing(1.5);
			border-radius: 8px;

			.mortgage-equity-service-value-prop-card-title {
				line-height: normal;
				font-size: 16px;
				font-weight: bold;

				@include if-size("desktop") {
					font-size: 18px;
					font-weight: bold;
				}
			}

			.body-tiny {
				margin-top: spacing(0.5);
			}
		}
	}
}

#refinance-monitoring-service-content {
	background: $white;
	padding: spacing(2) 0;
	border-radius: 8px;
	@include if-size("desktop") {
		padding: spacing(2);
	}

	.active-service-badge {
		margin-bottom: 6px;
	}

	.kelly-light-badge {
		padding: 6px 12px;
		border-radius: 8px;
		background-color: $kelly-light;
		color: $kelly-dark;
	}

	.kelly-badge {
		padding: 6px 12px;
		border-radius: 8px;
		background-color: $kelly;
		color: $white;
	}

	.value-prop-card {
		background: $denim_lightest;
		padding: spacing(1.5);
		border-radius: 8px;

		.sky-banner {
			padding: spacing(2);
			border-radius: 8px;
			background-color: $sky-light;
			color: $royal;
		}
	}
}

.lock-icon {
	height: 20px;
	width: 20px;

	path {
		fill: $denim_5;
	}
}

.house-illustration-mortgage-paid-off-graphic {
	width: 250px;
}
