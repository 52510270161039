#modal-portal {
	width: 100%;
	height: 100%;
	display: none;
}

#offer-modal-container {
	z-index: 9999999;
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba($denim, 0.5);
}

#offer-modal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	height: auto;
	width: 80%;
	max-width: 560px;
	background-color: #fff;
	display: grid;
	justify-content: center;
	border-radius: spacing(1);
	box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);

	#offer-modal-body {
		padding: spacing(2);

		@include if-size("tablet") {
			padding: spacing(4);
		}
	}

	.offer-modal-title {
		margin-bottom: 2px;

		@include if-size("tablet") {
			margin-bottom: spacing(1);
		}
	}

	.offer-modal-subtitle {
		margin-bottom: spacing(2);
	}

	.offer-modal-terms {
		color: $denim;
		opacity: 0.5;
		margin-bottom: spacing(2);
	}

	#offer-modal-close {
		height: 18px;
		width: 18px;
		z-index: 1000;
		background-color: #fff;
		border-radius: 50%;
		padding: 4px;
		cursor: pointer;
		position: absolute;
		right: spacing(1);
		top: spacing(1);
		box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.1);

		@include if-size("tablet") {
			height: 24px;
			width: 24px;
		}

		&.deadline-modal-close {
			border: 2px solid $denim;
			box-shadow: none;
		}
	}
}

.deadline-modal-cta {
	margin: spacing(2) auto 0;
}

#offer-modal-container {
	&.trends-signup-modal-container {
		opacity: 0;
		visibility: hidden;
		transition:
			opacity 1s ease-in-out,
			visibility 1s;
		#offer-modal {
			max-width: unset;
			border-radius: 16px;
			@include if-size("desktop") {
				max-width: 900px;
			}
			&.trends-signup-modal {
				#offer-modal-close {
					background-color: unset;
					box-shadow: unset;
				}
				#offer-modal-body {
					padding: 0 !important;
					border-radius: 16px;
					&.trends-signup-modal-body {
						position: relative;
						background-color: $denim-lightest;
						.modal-content-wrapper {
							position: relative;
							z-index: 10;
							display: flex;
							flex-direction: column;
							gap: 8px;
							justify-content: center;
							align-items: flex-start;
							padding: 8px;
							padding-top: 225px;
							@include if-size("tablet") {
								padding-top: 400px;
							}
							@include if-size("desktop") {
								max-width: 960px;
								padding-right: 400px;
								height: 450px;
								padding-top: unset;
								padding-left: 32px;
								min-width: 450px;
								flex-shrink: 0;
							}
							.saving-opp-box {
								background-color: #ffe6a1;
								color: $gold;
								border-radius: 8px;
								display: flex;
								justify-content: center;
								align-items: center;
								gap: 8px;
								padding: 6px 8px;
								width: fit-content;
								.saving-opp-text {
									color: $gold;
								}
							}
							#landing-hero-address-input {
								margin: 8px 0;
								padding: 0;
								width: 100%;
								justify-content: flex-start;
								align-items: start;
								#landing-hero-address-search {
									background-color: $sky-light;
								}
								.search-input-container input.search {
									padding: 4px 32px !important;
									background-color: $sky-light;
									color: $royal;
									&::placeholder {
										color: $royal;
									}
								}
								.search-input-container svg.search-icon-left {
									height: 20px;
									path {
										fill: $royal;
									}
								}
							}
							.features-box {
								display: flex;
								flex-direction: column;
								gap: 6px;
								background-color: white;
								border-radius: 16px;
								padding: 12px;
								width: 100%;
							}
						}
						.modal-bg {
							position: absolute;
							width: 100%;
							top: 0;
							@include if-size("desktop") {
								width: unset;
								height: 100%;
								right: 0;
								z-index: 0;
								overflow: hidden;
								border-radius: 8px;
								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}
					}
				}
			}
		}
	}
	&.trends-signup-modal-container.fade-in {
		opacity: 1;
		visibility: visible;
	}

	&.trends-signup-modal-container.fade-out {
		opacity: 0;
		visibility: hidden;
	}
}
